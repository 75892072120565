import React, { useEffect, useState } from "react";
import "./Search.css";
import CourseCard from "./components/CourseCard";
import Pagination from "./components/Pagination";
import { fetchC } from "./utils/customFetch";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  SimpleGrid,
  Spinner,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";
import { IoMdSearch } from "react-icons/io";

function Search() {
  // Get query string from URL
  const queryString = window.location.search;

  const [search, setSearch] = useState(
    decodeURIComponent(queryString.split("q=")[1]) == "undefined"
      ? ""
      : decodeURIComponent(queryString.split("q=")[1])
  );

  const [page, setPage] = useState(1);

  const [courses, setCourses] = useState(null);

  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [minPriceL, setMinPriceL] = useState(null);
  const [maxPriceL, setMaxPriceL] = useState(null);
  const [minRating, setMinRating] = useState(null);
  const [ai, setAi] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  // Update search query string
  const searchF = () => {
    window.location.search = `?q=${search}`;
  };

  useEffect(() => {
    setIsLoading(true);
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/courses/search?keywords=${decodeURIComponent(
        queryString.split("q=")[1]
      ).split(" ")}`,
      {
        method: "GET",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setCourses(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/courses/search?keywords=${decodeURIComponent(
        queryString.split("q=")[1]
      ).split(" ")}` +
        (page ? `&page=${page}` : "") +
        (minPrice ? `&minPrice=${minPrice}` : "") +
        (maxPrice ? `&maxPrice=${maxPrice}` : "") +
        (minRating ? `&minRating=${minRating}` : "") +
        (ai != null ? `&ai=${ai}` : "")
    )
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        setCourses(data);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, [page, minPrice, maxPrice, minRating, ai]);

  return (
    <Stack>
      <Text fontSize="4xl" fontWeight="bold" textAlign="center" my="5vh">
        Search
      </Text>
      <Flex align="center" justify="center">
        <InputGroup borderRadius={5} size="lg" width="40vw" marginBottom="5vh">
          <Input
            border="1px solid #949494"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            size="lg"
            placeholder="Find the perfect course for you"
            bg="white"
            type="search"
          />
          <InputRightAddon p={0} border="none">
            <Button
              isLoading={isLoading}
              onClick={() => searchF()}
              bg="none"
              _hover="none"
            >
              <IoMdSearch size={20} />
            </Button>
          </InputRightAddon>
        </InputGroup>
      </Flex>
      <Flex
        flexDir={["column", "column", "row"]}
        align="center"
        justify="space-evenly"
        mb="10vh"
      >
        <Flex>
          <span>Price: </span>
          <Flex w={["70vw", "60vw", "40vw", "20vw"]} ml="1em">
            <span>₹0:{minPriceL}</span>
            <RangeSlider
              mx="1em"
              aria-label={["min price", "max price"]}
              defaultValue={[0, 10000]}
              min={0}
              max={10000}
              onChange={(val) => {
                setMinPriceL(val[0]);
                setMaxPriceL(val[1]);
              }}
              onChangeEnd={(val) => {
                setMinPrice(val[0]);
                setMaxPrice(val[1]);
              }}
            >
              <RangeSliderTrack>
                <RangeSliderFilledTrack />
              </RangeSliderTrack>
              <RangeSliderThumb index={0} />
              <RangeSliderThumb index={1} />
            </RangeSlider>
            <span>₹{maxPriceL}:10,000</span>
          </Flex>
        </Flex>
        <Flex align="center">
          <span style={{ marginRight: "1em" }}>Min rating</span>
          {[1, 2, 3, 4, 5].map((num) => {
            return (
              <div>
                <span
                  onClick={(e) => setMinRating(num)}
                  className={
                    minRating < num ? "Search__star" : "Search__starSelected"
                  }
                >
                  &#9733;
                </span>
              </div>
            );
          })}
        </Flex>
        <Box>
          <span>AI</span>
          <Switch
            ml="1em"
            isChecked={ai ? ai : false}
            onChange={(e) => setAi(!ai)}
          />
        </Box>
      </Flex>
      <div className="Search__superbox">
        {!isLoading ? (
          <SimpleGrid mx="2em" columns={[1, 1, 2, 2, 3]} spacing={10}>
            {courses ? (
              <>
                {courses?.msg.map((course) => (
                  <CourseCard
                    thumbnail={course.thumbnail}
                    price={course.price}
                    title={course.title}
                    id={course.id}
                    bestseller={course.bestseller}
                    rating={course.rating}
                    ratingNumber={course.ratingNum}
                    instructor={course.instructorObj}
                    edit={false}
                    status={course.status}
                  />
                ))}
              </>
            ) : null}
          </SimpleGrid>
        ) : (
          <Spinner size="xl" />
        )}
      </div>
      <Flex mb="2em" w="100%" align="center" justify="center">
        <Box w="30%">
          <Pagination
            page={page}
            setPage={setPage}
            pages={courses?.total_pages}
          />
        </Box>
      </Flex>
    </Stack>
  );
}

export default Search;
