import React, { useState } from "react";
import "../Forms.css";
import { fetchC } from "../utils/customFetch";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";

function CreateLesson(props) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [locked, setLocked] = useState(false);
  const [free, setFree] = useState(false);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const createLesson = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    fetchC("https://coursehub-server-de93512846f7.herokuapp.com/api/lessons/", {
      method: "POST",
      body: JSON.stringify({
        title: title,
        description: description,
        locked: locked,
        free: free,
        section: props.id,
      }),
      "Content-Type": "application/json",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            props.close();
            window.location.reload();
          },
        });
        setIsLoading(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      {props.id ? (
        <Flex flexDir="column" align="center" padding="2em">
          <Text fontSize="3xl" fontWeight="bold">
            Create Lesson
          </Text>
          <form onSubmit={createLesson}>
            <FormControl>
              <FormLabel>Title</FormLabel>
              <Input
                isRequired
                maxLength={100}
                type="text"
                placeholder="Title"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormControl>
            <FormControl mt="2em">
              <FormLabel>Description</FormLabel>
              <Textarea
                isRequired
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Description"
                maxLength={500}
              ></Textarea>
            </FormControl>
            <FormControl mt="2em">
              <FormLabel>Locked</FormLabel>
              <Switch
                isChecked={locked}
                id="locked"
                onChange={(e) => setLocked(!locked)}
              />
            </FormControl>
            <FormControl mt="2em">
              <FormLabel>Free</FormLabel>
              <Switch
                isChecked={free}
                id="free"
                onChange={(e) => setFree(!free)}
              />
            </FormControl>
            <Button
              isLoading={isLoading}
              mt="2em"
              colorScheme="green"
              type="submit"
            >
              Submit
            </Button>
          </form>
        </Flex>
      ) : (
        <h1>Section not found</h1>
      )}
    </>
  );
}

export default CreateLesson;
