import React, { useEffect, useState } from "react";
import CourseContent from "./components/CourseContent";
import "./CourseDash.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import thumbnail from "./thumbnail.png";
import { MdEdit } from "react-icons/md";
import Popup from "reactjs-popup";
import UploadThumbnail from "./components/UploadThumbnail";
import { FaStar } from "react-icons/fa";
import UploadCert from "./components/UploadCert";
import { fetchC } from "./utils/customFetch";
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  ListItem,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  UnorderedList,
  useToast,
} from "@chakra-ui/react";
import CreateFAQ from "./components/CreateFAQ";
import Discussions from "./components/Discussions";
import CourseStudents from "./components/CourseStudents";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LineController,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from "chart.js";
import { Pie, Line } from "react-chartjs-2";
import { DateTime } from "luxon";
import "chartjs-adapter-luxon";

ChartJS.register(
  LineController,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

function CourseDash() {
  const { id } = useParams();
  const [course, setCourse] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();
  const [isDeleting, setIsDeleting] = useState(false);

  const { navigate } = useNavigate();

  useEffect(() => {
    const fun = async () => {
      fetchC(
        `https://coursehub-server-de93512846f7.herokuapp.com/api/courses/${id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setCourse(data.msg);
          setIsLoading(false);
          console.log(data.msg);
        })
        .catch((err) => {
          console.error(err);
          setIsLoading(false);
        });
    };
    fun();
  }, []);

  const deleteFaq = (id) => {
    const confirm = window.confirm("Are you sure you want to delete this FAQ?");
    if (!confirm) return;
    setIsDeleting(true);
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/faqs/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            window.location.reload();
          },
        });
        setIsDeleting(false);
      })
      .catch((err) => {
        setIsDeleting(false);
        console.error(err);
        toast({
          title: "Error",
          description: "Failed to delete FAQ",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <Flex
      px="5vw"
      py="5vh"
      justify="space-between"
      align={["center", "center", "center", "center", "flex-start"]}
      flexDir={["column", "column", "column", "column", "row"]}
    >
      {isLoading ? (
        <Flex align="center" justify="center" width="100vw" h="78vh">
          <Spinner size="xl" />
        </Flex>
      ) : id && course ? (
        <>
          <Flex
            flexDir="column"
            align="center"
            maxW={["80vw", "80vw", "80vw", "80vw", "25vw"]}
            p="2em"
            border="1px"
            borderRadius="xl"
          >
            <Image
              borderRadius="xl"
              src={course.thumbnail != "default" ? course.thumbnail : thumbnail}
            />
            <Popup
              trigger={
                <MdEdit
                  style={{
                    position: "relative",
                    top: "-3em",
                    left: "35%",
                    cursor: "pointer",
                  }}
                  size={24}
                  color="white"
                />
              }
              modal
              nested
            >
              {(close) => (
                <div className="modal">
                  <button className="close" onClick={close}>
                    &times;
                  </button>
                  <UploadThumbnail id={id} thumbnail={course.thumbnail} />
                </div>
              )}
            </Popup>
            <Text fontSize="2xl" fontWeight="bold">
              {course.title}
            </Text>
            <Text textAlign="center" fontSize="sm" mb="1em">
              {course.description}
            </Text>
            <Text>
              <b>Price: ₹ </b>
              {course.price}
            </Text>
            <Link to={`/edit/${id}`} className="CourseCard__edit">
              <Flex align="center" w="3vw" justify="space-between">
                <p>Edit</p> <MdEdit color="black" size={18} />
              </Flex>
            </Link>
            <Popup
              trigger={
                <Button colorScheme="green" my="2em">
                  Upload cert
                </Button>
              }
              modal
              nested
            >
              {(close) => (
                <div className="modal">
                  <button className="close" onClick={close}>
                    &times;
                  </button>
                  <UploadCert id={id} />
                </div>
              )}
            </Popup>
            <Flex align="center" mb="0.5em">
              Rating: {course.rating} <FaStar color="green" /> (
              {course.ratingNum})
            </Flex>
            <Text mb="0.5em">Promoted: {course.promoted ? "Yes" : "No"}</Text>
            <Text>
              Status:{" "}
              <b style={{ color: course.status == "active" ? "green" : "red" }}>
                {course.status}
              </b>
            </Text>
            {course.bestseller ? (
              <p style={{ color: "gold", fontWeight: 1000 }}>
                Bestseller <FaStar color="gold" />
              </p>
            ) : null}
            <br />
          </Flex>
          <Flex
            flexDir="column"
            padding="2em"
            border="1px"
            borderRadius="xl"
            w={["80vw", "80vw", "80vw", "80vw", "60vw"]}
            mt={["2em", "2em", "2em", "2em", "0"]}
          >
            <Text fontSize="2xl" fontWeight="bold">
              Overview
            </Text>
            <Flex
              align="center"
              justify="space-between"
              my="1em"
              flexDir={["column", "column", "row"]}
            >
              <Box
                flex="2"
                borderRadius="lg"
                border="1px"
                p="1em"
                minH="40vh"
                width={["100%", "100%", "inherit"]}
                h="100%"
              >
                <Text fontSize="lg">Purchase graph: (Wireframe)</Text>
                <Line
                  data={{
                    labels: ["Q1", "Q2", "Q3", "Q4"],
                    datasets: [
                      {
                        label: "Sales",
                        data: [10, 20, 30, 50],
                        fill: false,
                        borderColor: "rgb(75, 192, 192)",
                        tension: 0.1,
                      },
                    ],
                  }}
                  options={{
                    scales: {
                      x: {
                        type: "linear",
                        display: true,
                        title: {
                          display: true,
                          text: "Quarters",
                        },
                        stepSize: 1,
                      },
                      y: {
                        type: "linear",
                        display: true,
                        title: {
                          display: true,
                          text: "Sales",
                        },
                      },
                    },
                  }}
                />
              </Box>
              <Box
                flex="1"
                borderRadius="lg"
                border="1px"
                p="1em"
                minH="40vh"
                width={["100%", "100%", "inherit"]}
                mt={["1em", "1em", "0"]}
                ml={["0", "0", "2em"]}
              >
                <Text fontSize="xl" fontWeight="bold" mb="0.5em">
                  Net Revenue: XX,XXX Rs
                </Text>
                <Pie
                  data={{
                    labels: ["Fees", "Commission", "Profit"],
                    datasets: [
                      {
                        data: [10, 50, 100],
                        backgroundColor: ["#ED8936", "#38B2AC", "#48BB78"],
                        hoverBackgroundColor: ["#ED8936", "#38B2AC", "#48BB78"],
                      },
                    ],
                  }}
                />
              </Box>
            </Flex>
            <Text fontSize="2xl" fontWeight="bold">
              Options
            </Text>
            <Tabs colorScheme="green" w="100%" variant="soft-rounded">
              <TabList
                mb="2em"
                display="flex"
                alignItems="center"
                justifyContent="space-evenly"
              >
                <Tab>FAQs</Tab>
                <Tab>Discussions</Tab>
                <Tab>Assignment submissions</Tab>
                <Tab>Students</Tab>
              </TabList>
              <TabPanels>
                <TabPanel display="flex" flexDir="column" alignItems="center">
                  <Divider mb="1em" />
                  {course.faqs ? (
                    <UnorderedList
                      w="70%"
                      px="4em"
                      py="2em"
                      border="1px"
                      borderRadius="lg"
                    >
                      {course.faqs.map((faq) => (
                        <ListItem>
                          <Text fontSize="lg" fontWeight="bold">
                            Q: {faq.question}
                          </Text>
                          <Text fontSize="lg">A: {faq.answer}</Text>
                          <Button
                            colorScheme="red"
                            onClick={() => deleteFaq(faq.id)}
                            isLoading={isDeleting}
                            mb="1em"
                          >
                            Delete
                          </Button>
                          <hr />
                        </ListItem>
                      ))}
                    </UnorderedList>
                  ) : null}
                  <Popup
                    trigger={
                      <Button colorScheme="green" my="2em" w="fit-content">
                        Add
                      </Button>
                    }
                    modal
                    nested
                  >
                    {(close) => (
                      <div className="modal">
                        <button className="close" onClick={close}>
                          &times;
                        </button>
                        <CreateFAQ id={id} />
                      </div>
                    )}
                  </Popup>
                  <Divider mt="1em" />
                </TabPanel>
                <TabPanel display="flex" flexDir="column" alignItems="center">
                  <Divider mb="1em" />
                  <Discussions num={3} id={id} />
                  <Link to={`/discussions/${id}`}>
                    <Button>View all</Button>
                  </Link>
                  <Divider mt="1em" />
                </TabPanel>
                <TabPanel>
                  <Heading>Coming soon...</Heading>
                </TabPanel>
                <TabPanel>
                  <CourseStudents id={id} />
                </TabPanel>
              </TabPanels>
            </Tabs>
            <Text fontSize="2xl" fontWeight="bold" mt="2em">
              Content
            </Text>
            <CourseContent id={id} />
          </Flex>
        </>
      ) : (
        <h1>No course found</h1>
      )}
    </Flex>
  );
}

export default CourseDash;
