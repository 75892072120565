import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { fetchC } from "../utils/customFetch";

function CreateQuestion(props) {
  const [question, setQuestion] = useState("");
  const [points, setPoints] = useState(0);
  const [answer, setAnswer] = useState("");
  const [hint, setHint] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [required, setRequired] = useState(false);
  const [negative, setNegative] = useState(false);

  const toast = useToast();

  const createQuestion = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    fetchC(
      "https://coursehub-server-de93512846f7.herokuapp.com/api/questions/",
      {
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          question,
          points,
          answer,
          hint,
          test: props.test,
          required,
          negative,
        }),
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            props.close();
            window.location.reload();
          },
        });
        setIsLoading(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      {props.test ? (
        <Flex flexDir="column" align="center" padding="2em">
          <Text fontSize="3xl" fontWeight="bold">
            Create Question
          </Text>
          <form onSubmit={createQuestion}>
            <FormControl>
              <FormLabel>Question</FormLabel>
              <Input
                isRequired
                maxLength={100}
                type="text"
                placeholder="Question"
                id="question"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
              />
            </FormControl>
            <FormControl mt="1em">
              <FormLabel>Points</FormLabel>
              <Input
                isRequired
                type="number"
                placeholder="Points"
                id="points"
                value={points}
                onChange={(e) => setPoints(e.target.value)}
              />
            </FormControl>
            <FormControl mt="1em">
              <FormLabel>Answer (Leave blank for MCQ)</FormLabel>
              <Input
                type="text"
                placeholder="Answer"
                id="answer"
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
              />
            </FormControl>
            <FormControl mt="1em">
              <FormLabel>Hint</FormLabel>
              <Input
                type="text"
                placeholder="Hint"
                id="hint"
                value={hint}
                onChange={(e) => setHint(e.target.value)}
              />
            </FormControl>
            <FormControl mt="1em">
              <FormLabel>Required</FormLabel>
              <Switch
                isRequired
                id="required"
                value={required}
                onChange={(e) => setRequired(!required)}
              />
            </FormControl>
            {props.negative ? (
              <FormControl mt="1em">
                <FormLabel>Negative</FormLabel>
                <Input
                  isRequired
                  type="number"
                  placeholder="Negative"
                  id="negative"
                  value={negative}
                  onChange={(e) => setNegative(e.target.value)}
                />
              </FormControl>
            ) : null}
            <Button
              mt="2em"
              colorScheme="green"
              type="submit"
              isLoading={isLoading}
            >
              Submit
            </Button>
          </form>
        </Flex>
      ) : (
        <h1>Course not found</h1>
      )}
    </>
  );
}

export default CreateQuestion;
