import { ListItem, Stack, Text, UnorderedList } from "@chakra-ui/react";
import React from "react";

function TandC() {
  return (
    <Stack padding="2em" lineHeight="2.5" minHeight="78vh">
      <Text fontSize="2xl" fontWeight="bold">
        Terms and Conditions
      </Text>
      <p>
        Welcome to our online course hosting platform. Please read these terms
        and conditions carefully before using our services.
      </p>

      <Text fontSize="xl" fontWeight="bold">
        1. Acceptance of Terms
      </Text>
      <p>
        By accessing or using our platform, you agree to be bound by these terms
        and conditions. If you do not agree with any part of these terms, you
        may not use our services.
      </p>

      <Text fontSize="xl" fontWeight="bold">
        2. User Responsibilities
      </Text>
      <p>As a user of our platform, you are responsible for:</p>
      <UnorderedList ml="2em">
        <ListItem>
          Providing accurate and up-to-date information during registration.
        </ListItem>
        <ListItem>
          Keeping your login credentials secure and confidential.
        </ListItem>
        <ListItem>Complying with all applicable laws and regulations.</ListItem>
        <ListItem>
          Respecting the intellectual property rights of others.
        </ListItem>
        <ListItem>Using our services for lawful purposes only.</ListItem>
      </UnorderedList>

      <Text fontSize="xl" fontWeight="bold">
        3. Course Content
      </Text>
      <p>
        The course content provided on our platform is for educational purposes
        only. We make no guarantees about the accuracy, completeness, or
        usefulness of the content. You are responsible for verifying the
        information provided and applying it appropriately.
      </p>

      <Text fontSize="xl" fontWeight="bold">
        4. Payments and Refunds
      </Text>
      <p>
        Payment for courses is required before gaining access to the content. We
        offer a refund policy within a specified timeframe, which you can find
        in our refund policy document.
      </p>

      <Text fontSize="xl" fontWeight="bold">
        5. Intellectual Property
      </Text>
      <p>
        All course materials, including but not limited to text, images, videos,
        and other media, are the intellectual property of their respective
        owners. You may not reproduce, distribute, or modify the content without
        obtaining permission from the owner.
      </p>

      <Text fontSize="xl" fontWeight="bold">
        6. Limitation of Liability
      </Text>
      <p>
        We are not liable for any direct, indirect, incidental, consequential,
        or special damages arising out of or in connection with the use of our
        platform or the course content.
      </p>

      <Text fontSize="xl" fontWeight="bold">
        7. Modifications to Terms
      </Text>
      <p>
        We reserve the right to modify these terms and conditions at any time.
        Any changes will be effective immediately upon posting on our platform.
        It is your responsibility to review the terms periodically for updates.
      </p>

      <Text fontSize="xl" fontWeight="bold">
        8. Governing Law
      </Text>
      <p>
        These terms and conditions are governed by and construed in accordance
        with the laws of Surat, Gujarat, India. Any disputes arising out of or
        in connection with these terms shall be subject to the exclusive
        jurisdiction of the courts in Surat, Gujarat, India.
      </p>

      <p>
        If you have any questions or concerns about these terms and conditions,
        please contact us at chevlimegh@gmail.com
      </p>
    </Stack>
  );
}

export default TandC;
