import React, { useEffect, useState } from "react";
import "./Pagination.css";
import { Button, Flex } from "@chakra-ui/react";

function Pagination(props) {
  const [page, setPage] = useState(props.page);
  useEffect(() => {
    props.setPage(page);
  }, [page]);
  return (
    <Flex align="center" justify="space-between" w="100%" mt="0.5em">
      <Button
        onClick={() => {
          if (page > 1) {
            setPage(page - 1);
          }
        }}
      >
        Prev
      </Button>
      <input
        min={1}
        max={props.pages}
        type="number"
        placeholder={`Pg: ${page}`}
        value={page}
        onChange={(e) => setPage(e.target.value)}
        style={{ width: "2em", textAlign: "center", padding: "0.5em" }}
      />
      <Button
        onClick={() => {
          if (page < props.pages) {
            setPage(page + 1);
          }
        }}
      >
        Next
      </Button>
    </Flex>
  );
}

export default Pagination;
