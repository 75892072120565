import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAxgUKVOlyhqlNQHKhSsV5eEgV2_txrZLM",
  authDomain: "coursehub-78cd9.firebaseapp.com",
  projectId: "coursehub-78cd9",
  storageBucket: "coursehub-78cd9.appspot.com",
  messagingSenderId: "882571176164",
  appId: "1:882571176164:web:4c56a4edc73bf26b3824f1",
  measurementId: "G-SZXSHVEPFX",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
