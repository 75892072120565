import {
  ListItem,
  OrderedList,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";

function Cancellation() {
  return (
    <Stack padding="2em" lineHeight="2.5" minHeight="78vh">
      <Text fontSize="2xl" fontWeight="bold">
        Cancellation and Refund Policy
      </Text>
      <p>
        Thank you for using Coursehub. This Cancellation and Refund Policy
        explains the terms and conditions for cancelling courses and requesting
        refunds on our platform.
      </p>
      <Text fontSize="xl" fontWeight="bold">
        1. Cancellation Policy
      </Text>
      <OrderedList ml="2em">
        <ListItem>
          Students can cancel a course enrollment at any time during 36 hours
          from the payment date.
        </ListItem>
        <ListItem>
          To cancel a course enrollment, students must contact our support team
          or use the cancellation feature available on the Coursehub platform.
        </ListItem>
        <ListItem>
          Cancellations made before the the 36 hours will be refunded in full to
          the student .
        </ListItem>
        <ListItem>
          Cancellations made after the 36 hours date will not be eligible for a
          refund except in special conditions.
        </ListItem>
      </OrderedList>
      <Text fontSize="xl" fontWeight="bold">
        2. Refund Policy
      </Text>
      <OrderedList ml="2em">
        <ListItem>
          Refunds will be initiated within 7 business days from the date of
          cancellation.
        </ListItem>
        <ListItem>
          Refunds will be issued to the original payment method used during the
          course enrollment.
        </ListItem>
        <ListItem>
          Refunds will be issued in the same currency as the original payment.
        </ListItem>
        <ListItem>
          Coursehub reserves the right to modify or update this Cancellation and
          Refund Policy at any time without prior notice.
        </ListItem>
      </OrderedList>
    </Stack>
  );
}

export default Cancellation;
