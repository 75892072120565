import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  Input,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Tag,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchC } from "./utils/customFetch";

function DoTest() {
  const { id } = useParams();

  const [test, setTest] = useState(null);
  const [loading, setLoading] = useState(true);

  const [loadingSave, setLoadingSave] = useState(false);

  const [answers, setAnswers] = useState([]);
  const [passed, setPassed] = useState(false);
  const [results, setResults] = useState([]);

  const toast = useToast();

  useEffect(() => {
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/tests/${id}`,
      {
        method: "GET",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        } else {
          return res.json();
        }
      })
      .then((data) => {
        setTest(data.msg);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  const completeTest = (e) => {
    e.preventDefault();
    setLoadingSave(true);
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/tests/complete`,
      {
        method: "POST",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: id }),
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        } else {
          return res.json();
        }
      })
      .then((data) => {
        toast({
          title: "Test completed",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setLoadingSave(false);
      })
      .catch((err) => {
        err.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setLoadingSave(false);
      });
  };

  const checkAnswers = (e) => {
    setLoadingSave(true);
    e.preventDefault();
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/questions/answer`,
      {
        method: "POST",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ test: id, answers: answers }),
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        } else {
          return res.json();
        }
      })
      .then((data) => {
        if (data.score >= test.passing) {
          setPassed(true);
        }
        toast({
          title: "Your score",
          description: `You scored ${data.score} points`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setResults(data.results);
        setLoadingSave(false);
      })
      .catch((err) => {
        err.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setLoadingSave(false);
      });
  };

  const optionModify = (id, e) => {
    var answersTmp = answers;
    const index = answersTmp.findIndex((a) => a.id === id);
    if (index === -1) {
      answersTmp.push({ id: id, answer: e });
    } else {
      answersTmp[index].answer = e;
    }
    setAnswers(answersTmp);
  };

  return (
    <Center minH="78vh">
      <Stack
        my="2em"
        minH="70vh"
        w="80vw"
        p="2em"
        border="1px"
        borderRadius="lg"
      >
        {loading ? (
          <Center h="78vh">
            <Spinner size="xl" />
          </Center>
        ) : test ? (
          <>
            <Heading>{test.title}</Heading>
            <Text>{test.description}</Text>
            <Text>Passing points: {test.passing}</Text>
            {test.graded ? (
              <Tag w="fit-content" colorScheme="blue">
                Graded
              </Tag>
            ) : null}
            <Text fontSize="lg" fontWeight="bold">
              Time to complete: {test.time}
            </Text>
            <Stack p="1em" borderRadius="xl" border="1px">
              {test?.questions
                .sort((a, b) => a.questionNo - b.questionNo)
                .map((q, i) => (
                  <Box key={i}>
                    <Text fontWeight="bold" fontSize="lg">
                      Question: {q.question}
                    </Text>
                    {results ? (
                      <Text fontSize="sm" w="fit-content">
                        Result: {results[q.id]?.msg}
                      </Text>
                    ) : null}
                    <Tag size="sm" w="fit-content" colorScheme="yellow">
                      {q.points} pt
                    </Tag>
                    {q.options.length > 0 ? (
                      <RadioGroup
                        my="1em"
                        onChange={(e) => optionModify(q.id, e)}
                      >
                        <Stack>
                          {q.options.map((o, j) => (
                            <Flex key={j}>
                              <Radio value={o.option} />
                              <Text ml="0.5em">{o.option}</Text>
                            </Flex>
                          ))}
                        </Stack>
                      </RadioGroup>
                    ) : (
                      <>
                        <Input
                          onChange={(e) => optionModify(q.id, e.target.value)}
                          type="text"
                          placeholder="Answer"
                          my="1em"
                        />
                      </>
                    )}
                    <Divider />
                  </Box>
                ))}
            </Stack>
            <Button
              w="fit-content"
              colorScheme="teal"
              onClick={checkAnswers}
              isLoading={loadingSave}
            >
              Check answers
            </Button>
            {passed ? (
              <Button
                w="fit-content"
                colorScheme="green"
                onClick={completeTest}
                isLoading={loadingSave}
              >
                Complete test
              </Button>
            ) : null}
          </>
        ) : (
          <Heading>Test data not found</Heading>
        )}
      </Stack>
    </Center>
  );
}

export default DoTest;
