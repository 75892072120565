import React, { useState, useRef, useContext } from "react";
import "./Forms.css";
import ReCAPTCHA from "react-google-recaptcha";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { UserContext } from "./index";
import { Link, useNavigate } from "react-router-dom";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import {
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { fetchC } from "./utils/customFetch";
import { FcGoogle } from "react-icons/fc";

// The Sign in page

function Signin() {
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const recaptcha = useRef();

  const navigate = useNavigate();

  const auth = getAuth();

  const { user, setUser, fetchUser } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);

  const provider = new GoogleAuthProvider();
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);

  const google = () => {
    setIsLoadingGoogle(true);

    signInWithPopup(auth, provider)
      .then((result) => {
        fetchC(
          "https://coursehub-server-de93512846f7.herokuapp.com/api/users/status",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            "Content-Type": "application/json",
          }
        )
          .then((res) => {
            if (!res.ok) {
              throw res;
            }
            toast({
              title: "Success",
              description: "Successfully signed in",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            setIsLoadingGoogle(false);
            navigate("/");
          })
          .catch((error) => {
            setIsLoadingGoogle(false);
            navigate("/additional-details");
            return;
          });
      })
      .catch((error) => {
        const errorMessage = error.message;
        toast({
          title: "Error",
          description: errorMessage,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setIsLoadingGoogle(false);
      });
  };

  // Sign in user
  const loginUser = (e) => {
    if (!email || !password) {
      toast({
        title: "Error",
        description: "Please enter both email and password",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!recaptcha.current.getValue()) {
      toast({
        title: "Warning",
        description: "Please complete the reCAPTCHA",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    e.preventDefault();
    setIsLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((user) => {
        toast({
          title: "Success",
          description: "Signed in successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            fetchUser();
            window.location.reload();
            navigate("/");
          },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        toast({
          title: "Error",
          description: err,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      {!user ? (
        <Center minH="78vh">
          <Flex
            my="2em"
            flexDir="column"
            paddingX="1em"
            paddingY="2em"
            borderRadius="1em"
            minW={"30vw"}
            border={["0px", "1px"]}
            boxShadow={["none", "lg"]}
          >
            <Text fontSize="4xl" textAlign="center" fontWeight="bold">
              CourseHub
            </Text>
            <Text textAlign="center">Welcome back to CourseHub</Text>
            <form onSubmit={loginUser}>
              <FormControl maxW="80%" mb="1em" mt="2em">
                <FormLabel>Email</FormLabel>
                <Input
                  isRequired
                  maxLength={150}
                  id="email"
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
              <FormControl maxW="80%" mb="2em">
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <Input
                    isRequired
                    minLength={6}
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputRightElement>
                    <Button onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <Link style={{ marginTop: "0.5em" }} to="/forgot-pass">
                  <u>Forgot password?</u>
                </Link>
              </FormControl>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY}
                ref={recaptcha}
                className="Form__recaptcha"
              />
              <Button isLoading={isLoading} colorScheme="green" type="submit">
                Submit
              </Button>
              or
              <Button
                display="flex"
                alignItems="center"
                isLoading={isLoadingGoogle}
                onClick={google}
              >
                Sign in with Google
                <FcGoogle size={24} style={{ marginLeft: "0.5em" }} />
              </Button>
              <br />
              <Link to="/signup">
                <u>Dont have an account?</u>
              </Link>
            </form>
          </Flex>
        </Center>
      ) : (
        <h1>Already signed in</h1>
      )}
    </>
  );
}

export default Signin;
