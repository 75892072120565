import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Flex,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Discussions from "./components/Discussions";
import { useParams } from "react-router-dom";
import Pagination from "./components/Pagination";
import { fetchC } from "./utils/customFetch";
import Popup from "reactjs-popup";
import CreateDiscussion from "./components/CreateDiscussion";

function DiscussionsPage() {
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [course, setCourse] = useState("");

  useEffect(() => {
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/courses/name/${id}`,
      {
        method: "GET",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        setCourse(data.msg);
      })
      .catch((res) => {
        res.json().then((data) => {
          console.log(data.msg);
        });
      });
  }, []);

  return (
    <Center minH="78vh" p="2em">
      <Stack
        display="flex"
        flexDir="column"
        justifyContent="space-between"
        w="80%"
        minH="70vh"
        p="2em"
        border="1px"
        borderRadius="xl"
      >
        <Stack>
          <Breadcrumb>
            <BreadcrumbItem>
              <BreadcrumbLink href={`/course/${id}`}>Course</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="#">Discussions</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Heading>Discussions for: {course}</Heading>
        </Stack>
        <Discussions id={id} num={10} page={page} setTotal={setTotalPages} />
        <Pagination setPage={setPage} page={page} pages={totalPages} />
        <Flex justifyContent="center">
          <Popup
            trigger={
              <Button w="fit-content" colorScheme="green">
                Create
              </Button>
            }
            modal
            nested
          >
            {(close) => (
              <div className="modal">
                <button className="close" onClick={close}>
                  &times;
                </button>
                <CreateDiscussion id={id} close={close} />
              </div>
            )}
          </Popup>
        </Flex>
      </Stack>
    </Center>
  );
}

export default DiscussionsPage;
