import React, { useState } from "react";
import "../Forms.css";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import { fetchC } from "../utils/customFetch";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";

function CreateMarkdown(props) {
  const [md, setMd] = useState("");
  const [time, setTime] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const submit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/markdown/`,
      {
        method: "POST",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          markdown: md,
          lesson: props.id,
          timeEstimate: time,
        }),
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            props.close();
            window.location.reload();
          },
        });
        setIsLoading(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      {props.id ? (
        <Flex flexDir="column" align="center">
          <Text fontSize="2xl" fontWeight="bold" my="1em">
            Create Markdown
          </Text>
          <form
            onSubmit={submit}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              className="Form__group"
              data-color-mode="light"
              style={{ width: "100%" }}
            >
              <MDEditor
                style={{ width: "100%" }}
                className="md"
                value={md}
                onChange={setMd}
                previewOptions={{
                  rehypePlugins: [[rehypeSanitize]],
                }}
              />
            </div>
            <FormControl mt="2em" maxW="50%">
              <FormLabel>Time Estimate</FormLabel>
              <InputGroup>
                <Input
                  isRequired
                  type="number"
                  id="time"
                  name="time"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                  min={0}
                />
                <InputRightAddon>min</InputRightAddon>
              </InputGroup>
            </FormControl>
            <Flex mt="2em" w="70%" justify="space-evenly">
              <Button colorScheme="green" type="submit" isLoading={isLoading}>
                Submit
              </Button>
              <Button colorScheme="red" onClick={() => props.close()}>
                Cancel
              </Button>
            </Flex>
          </form>
        </Flex>
      ) : null}
    </>
  );
}

export default CreateMarkdown;
