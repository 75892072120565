import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";

function Report(props) {
  const [msg, setMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const createReport = async (e) => {
    e.preventDefault();

    if (msg === "" || msg.length < 10 || msg.length > 500) {
      toast({
        title: "Warning",
        description: "Message must be between 10 and 500 characters",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (
      props.id === null ||
      props.id === undefined ||
      props.type === null ||
      props.type === undefined
    ) {
      toast({
        title: "Error",
        description: "Invalid course or user",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (props.type !== "course" && props.type !== "user") {
      toast({
        title: "Error",
        description: "Invalid report type",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    fetch("https://coursehub-server-de93512846f7.herokuapp.com/api/reports/", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        type: props.type,
        report: msg,
        course: props.type === "course" ? props.id : null,
        user: props.type === "user" ? props.id : null,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: "Report submitted successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          setIsLoading(false);
        });
      });
  };

  return (
    <>
      {props.id ? (
        <Flex flexDir="column" align="center" padding="2em">
          <Text fontSize="3xl" fontWeight="bold">
            Enter your objection
          </Text>
          <form onSubmit={createReport}>
            <FormControl>
              <FormLabel>Message for Admin</FormLabel>
              <Textarea
                isRequired
                maxLength={500}
                id="description"
                value={msg}
                onChange={(e) => setMsg(e.target.value)}
                placeholder="Message"
              ></Textarea>
            </FormControl>
            <Button
              mt="2em"
              colorScheme="green"
              type="submit"
              isLoading={isLoading}
            >
              Submit
            </Button>
          </form>
        </Flex>
      ) : (
        <h1>Course not found</h1>
      )}
    </>
  );
}

export default Report;
