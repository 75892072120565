import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchC } from "./utils/customFetch";
import { Box, Center, Heading, Spinner, Stack, Text } from "@chakra-ui/react";
import UserShort from "./components/UserShort";

function VerifyCertificate() {
  const { id } = useParams();

  const [certInfo, setCertInfo] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!id) return;
    const ids = id.split("_");
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/certificates/verify?course_id=${ids[0]}&user_id=${ids[1]}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        setCertInfo(data.msg);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setCertInfo("Error");
        setLoading(false);
      });
  }, [id]);

  function getRandomInt() {
    return Math.floor(Math.random() * 1000);
  }

  return (
    <Center minH="80vh">
      <Stack
        my="2em"
        p="1em"
        border="1px"
        borderRadius="2xl"
        minH="70vh"
        w="70%"
      >
        {loading ? (
          <Center w="100%" h="70vh">
            <Spinner size="xl" />
          </Center>
        ) : certInfo === "Error" ? (
          <Heading>Certificate not verified</Heading>
        ) : certInfo ? (
          <>
            <Heading>Verified Certificate</Heading>
            <Link to={`/course/${certInfo?.courseObj.id}`}>
              <Text fontSize="lg">Course: {certInfo?.courseObj.title}</Text>
            </Link>
            <Box ml="-0.5em">
              <UserShort
                id={certInfo?.userObj.id}
                username={certInfo?.userObj.username}
                img={certInfo?.userObj.avatar}
              />
            </Box>
            <Center w="100%">
              <img
                src={`${certInfo?.certificate}?ignoreCache=${getRandomInt()}`}
                alt="certificate"
                style={{
                  width: "80%",
                  height: "auto",
                  borderRadius: "1em",
                  border: "1px solid black",
                  marginTop: "1em",
                  marginBottom: "1em",
                }}
              />
            </Center>
          </>
        ) : (
          <Heading>Certificate not found</Heading>
        )}
      </Stack>
    </Center>
  );
}

export default VerifyCertificate;
