import React, { useState } from "react";
import { fetchC } from "../utils/customFetch";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Text,
  useToast,
} from "@chakra-ui/react";

function EditCriteria(props) {
  const toast = useToast();
  const [question, setQuestion] = useState(props.criteria.description);
  const [points, setPoints] = useState(props.criteria.points);

  const [isLoading, setIsLoading] = useState(false);

  const handleEdit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/assignments/criteria/${props.id}`,
      {
        method: "PATCH",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          criteria: question,
          points: points,
        }),
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            props.close();
            window.location.reload();
          },
        });
        setIsLoading(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsLoading(false);
      });
  };

  return (
    <Flex flexDir="column" align="center" padding="2em">
      <Text fontSize="3xl" fontWeight="bold" mb="1em">
        Edit criteria
      </Text>
      <form onSubmit={handleEdit}>
        <FormControl>
          <FormLabel>Question</FormLabel>
          <Input
            isRequired
            type="text"
            placeholder="Question"
            value={question}
            id="q"
            onChange={(e) => setQuestion(e.target.value)}
          />
        </FormControl>
        <FormControl mt="2em">
          <FormLabel>Points</FormLabel>
          <InputGroup>
            <Input
              isRequired
              min={1}
              id="p"
              type="number"
              value={points}
              onChange={(e) => setPoints(e.target.value)}
            />
            <InputRightAddon>pt</InputRightAddon>
          </InputGroup>
        </FormControl>
        <Button
          isLoading={isLoading}
          type="submit"
          mt="2em"
          colorScheme="green"
        >
          Submit
        </Button>
      </form>
    </Flex>
  );
}

export default EditCriteria;
