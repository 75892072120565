import { Stack, Text } from "@chakra-ui/react";
import React from "react";

function ContactUs() {
  return (
    <Stack padding="2em" lineHeight="2.5" minHeight="78vh">
      <Text fontSize="2xl" fontWeight="bold">
        Contact us
      </Text>
      <p>
        You can contact us in various ways such as: <br />
        <b>Email:</b> chevlimegh@gmail.com
        <br />
        <b>Email:</b> premjgandhi@gmail.com
        <br />
        <b>Email:</b> mycutemammoth@gmail.com
        <br />
        Or you could DM us at our Instagram account:
        <a href="https://www.instagram.com/coursehub.in?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
          <u>Instagram</u>
        </a>
        <br />
        We are comitted to provide you with the best service possible. Please
        feel free to reach out to us with any questions or concerns.
      </p>
    </Stack>
  );
}

export default ContactUs;
