import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { fetchC } from "./utils/customFetch";
import "./utils/firebase";
import { ChakraProvider } from "@chakra-ui/react";

export const UserContext = React.createContext(null);

function UserProvider({ children }) {
  const [user, setUser] = useState(null);

  const fetchUser = async () => {
    fetchC(
      "https://coursehub-server-de93512846f7.herokuapp.com/api/users/status",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data && data.msg.id) {
          setUser(data.msg);
        } else {
          setUser(null);
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <ChakraProvider>
      <UserContext.Provider value={{ user, setUser, fetchUser }}>
        {children}
      </UserContext.Provider>
    </ChakraProvider>
  );
}

// Initializes react app
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserProvider>
        <App />
      </UserProvider>
    </BrowserRouter>
  </React.StrictMode>
);
