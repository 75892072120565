import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import MDEditor from "@uiw/react-md-editor";
import EditTest from "./EditTest";
import EditMd from "./EditMd";
import { fetchC } from "../utils/customFetch";
import EditQuestion from "./EditQuestion";
import {
  Button,
  Center,
  Flex,
  IconButton,
  Stack,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import CreateQuestion from "./CreateQuestion";
import EditOption from "./EditOption";
import CreateOption from "./CreateOption";

// FIXME: Style videos

function LessonObjs(props) {
  const toast = useToast();
  const [objIds, setObjIds] = useState([]);
  const [reorder, setReorder] = useState(false);
  const [isDeletingTest, setIsDeletingTest] = useState(false);
  const [isDeletingMd, setIsDeletingMd] = useState(false);
  const [isDeletingVid, setIsDeletingVid] = useState(false);
  const [isReordering, setIsReordering] = useState(false);
  const [isDeletingQuestion, setIsDeletingQuestion] = useState(false);

  useEffect(() => {
    let videos = props.contents?.videos || [];
    let markdowns = props.contents?.markdowns || [];
    let tests = props.contents?.tests || [];

    let vidids = videos.map((v) => {
      return { id: v.id, objNo: v.objNo, type: "video" };
    });

    let mdids = markdowns.map((m) => {
      return { id: m.id, objNo: m.objNo, type: "markdown" };
    });

    let testids = tests.map((t) => {
      return { id: t.id, objNo: t.objNo, type: "test" };
    });

    let allids = vidids.concat(mdids).concat(testids);
    allids.sort((a, b) => a.objNo - b.objNo);
    setObjIds(allids);
  }, [props.contents]);

  const deleteTest = async (id) => {
    let confirm = window.confirm("Are you sure you want to delete this test?");
    if (!confirm) return;
    setIsDeletingTest(true);
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/tests/${id}`,
      {
        method: "DELETE",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => window.location.reload(),
        });
        setIsDeletingTest(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsDeletingTest(false);
      });
  };

  const deleteMd = async (id) => {
    let confirm = window.confirm(
      "Are you sure you want to delete this markdown?"
    );
    if (!confirm) return;
    setIsDeletingMd(true);
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/markdown/${id}`,
      {
        method: "DELETE",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => window.location.reload(),
        });
        setIsDeletingMd(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsDeletingMd(false);
      });
  };

  const deleteVid = async (id) => {
    let confirm = window.confirm("Are you sure you want to delete this video?");
    if (!confirm) return;
    setIsDeletingVid(true);
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/videos/${id}`,
      {
        method: "DELETE",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => window.location.reload(),
        });
        setIsDeletingVid(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsDeletingVid(false);
      });
  };

  const deleteQuestion = async (id) => {
    let confirm = window.confirm(
      "Are you sure you want to delete this question?"
    );
    if (!confirm) return;
    setIsDeletingQuestion(true);
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/questions/${id}`,
      {
        method: "DELETE",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => window.location.reload(),
        });
        setIsDeletingQuestion(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsDeletingQuestion(false);
      });
  };

  const saveOrder = async () => {
    setIsReordering(true);
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/lessons/objs/order/${props.id}`,
      {
        method: "PATCH",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ objNoList: objIds }),
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => window.location.reload(),
        });
        setIsReordering(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsReordering(false);
      });
  };

  const up = (contentObj) => {
    setObjIds(() => {
      const index = objIds.findIndex(
        (obj) => obj.id === contentObj.id && obj.type === contentObj.type
      );

      if (index === 0) {
        // If the object is already at the top, don't move it
        return objIds;
      }

      const newObjs = [...objIds];
      let tmp = newObjs[index].objNo;
      newObjs[index].objNo = newObjs[index - 1].objNo;
      newObjs[index - 1].objNo = tmp;

      return newObjs;
    });
    setReorder(true);
  };

  const down = (contentObj) => {
    setObjIds(() => {
      const index = objIds.findIndex(
        (obj) => obj.id === contentObj.id && obj.type === contentObj.type
      );

      if (index === objIds.length - 1) {
        // If the object is already at the bottom, don't move it
        return objIds;
      }

      const newObjs = [...objIds];
      let tmp = newObjs[index].objNo;
      newObjs[index].objNo = newObjs[index + 1].objNo;
      newObjs[index + 1].objNo = tmp;

      return newObjs;
    });
    setReorder(true);
  };

  const deleteOption = async (id) => {
    let confirm = window.confirm(
      "Are you sure you want to delete this option?"
    );
    if (!confirm) return;
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/options/${id}`,
      {
        method: "DELETE",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => window.location.reload(),
        });
        setIsDeletingQuestion(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsDeletingQuestion(false);
      });
  };

  return (
    <>
      <Button
        colorScheme="green"
        w="fit-content"
        hidden={!reorder}
        onClick={() => saveOrder()}
        isLoading={isReordering}
      >
        Reorder
      </Button>
      <ol style={{ marginLeft: "2em" }}>
        {objIds
          .sort((a, b) => a.objNo - b.objNo)
          .map((contentObj) => {
            let content;
            switch (contentObj.type) {
              case "video":
                content = props.contents.videos.find(
                  (video) => video.id === contentObj.id
                );
                return (
                  <li
                    key={content.id + " " + content.objNo}
                    style={{
                      border: "1px solid orange",
                      borderRadius: "1em",
                      margin: "1em",
                      padding: "1em",
                    }}
                  >
                    <Text fontSize="lg" fontWeight="bold">
                      Video:
                    </Text>
                    <Button
                      colorScheme="red"
                      isLoading={isDeletingVid}
                      onClick={() => deleteVid(content.id)}
                      my="1em"
                    >
                      Delete
                    </Button>
                    <Center>
                      <video
                        style={{ borderRadius: "1em" }}
                        width="500"
                        controls
                        src={content?.video}
                      />
                     </Center>
                    {/*<Flex mt="1em">
                      <Button
                        onClick={() =>
                          up({
                            id: content.id,
                            objNo: content.objNo,
                            type: "video",
                          })
                        }
                      >
                        Up
                      </Button>
                      <Button
                        ml="1em"
                        onClick={() =>
                          down({
                            id: content.id,
                            objNo: content.objNo,
                            type: "video",
                          })
                        }
                      >
                        Down
                      </Button>
                    </Flex> */}
                  </li>
                );
              case "markdown":
                content = props.contents.markdowns.find(
                  (markdown) => markdown.id === contentObj.id
                );
                return (
                  <li
                    key={content.id + " " + content.objNo}
                    data-color-mode="light"
                    style={{
                      margin: "1em",
                      border: "1px solid blue",
                      padding: "1em",
                      borderRadius: "1em",
                    }}
                  >
                    <Text fontSize="lg" fontWeight="bold">
                      Markdown:
                    </Text>
                    <Button
                      colorScheme="red"
                      onClick={() => deleteMd(content.id)}
                      isLoading={isDeletingMd}
                    >
                      Delete
                    </Button>
                    <Popup
                      trigger={<Button ml="1em">Edit</Button>}
                      modal
                      nested
                    >
                      {(close) => (
                        <div className="modal">
                          <button className="close" onClick={close}>
                            &times;
                          </button>
                          <EditMd md={content} close={close} id={content.id} />
                        </div>
                      )}
                    </Popup>
                    <MDEditor.Markdown
                      style={{
                        margin: "1em",
                        border: "1px solid black",
                        padding: "1em",
                        borderRadius: "1em",
                      }}
                      source={content?.markdown}
                    />
                    <Text>Time estimate: {content?.timeEstimate} minutes</Text>
                    {/* <Button
                      mr="1em"
                      onClick={() =>
                        up({
                          id: content.id,
                          objNo: content.objNo,
                          type: "markdown",
                        })
                      }
                    >
                      Up
                    </Button>
                    <Button
                      onClick={() =>
                        down({
                          id: content.id,
                          objNo: content.objNo,
                          type: "markdown",
                        })
                      }
                    >
                      Down
                    </Button> */}
                  </li>
                );
              case "test":
                content = props.contents.tests.find(
                  (test) => test.id === contentObj.id
                );
                return (
                  <li
                    key={content.id + " " + content.objNo}
                    style={{
                      margin: "1em",
                      border: "1px solid green",
                      padding: "1em",
                      borderRadius: "1em",
                    }}
                  >
                    <Text fontSize="xl">
                      <b>Test:</b> {content?.title}
                    </Text>
                    <Text mb="0.5em">{content?.description}</Text>
                    <Button
                      colorScheme="red"
                      onClick={() => deleteTest(content.id)}
                      isLoading={isDeletingTest}
                    >
                      Delete
                    </Button>
                    <Popup
                      trigger={<Button ml="1em">Edit</Button>}
                      modal
                      nested
                    >
                      {(close) => (
                        <div className="modal">
                          <button className="close" onClick={close}>
                            &times;
                          </button>
                          <EditTest
                            test={content}
                            close={close}
                            id={content.id}
                          />
                        </div>
                      )}
                    </Popup>
                    <Stack m="1em" border="1px" p="1em" borderRadius="1em">
                      <p>
                        <b>Time:</b> {content?.time} minutes
                      </p>
                      <p>
                        <b>Max attempts:</b> {content?.max}x
                      </p>
                      <p>
                        <b>Passing score:</b> {content?.passing}pt
                      </p>
                      <p>
                        <b>Delay:</b> {content?.delay}
                      </p>
                      <p>
                        <b>Negative grading:</b>{" "}
                        {content?.negativeGrading == true
                          ? "Yes"
                          : content.negativeGrading == false
                          ? "No"
                          : "Unknown"}
                      </p>
                      <TableContainer
                        p="1em"
                        border="1px solid lightgray"
                        borderRadius="lg"
                      >
                        <Table size="sm" varient="striped" colorScheme="green">
                          <Thead>
                            <Tr>
                              <Th>Question</Th>
                              <Th>Answer</Th>
                              <Th>Points</Th>
                              <Th>Hint</Th>
                              <Th>Required</Th>
                              <Th>Negative points</Th>
                              <Th>Options</Th>
                              <Th>Operations</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {content?.questions
                              .sort((a, b) => a.objNo - b.objNo)
                              ?.map((q) => (
                                <Tr>
                                  <Td>{q.question}</Td>
                                  <Td>{q.answer == "" ? "NULL" : q.answer}</Td>
                                  <Td>{q.points}</Td>
                                  <Td>{q.hint}</Td>
                                  <Td>{q.required ? "Yes" : "No"}</Td>
                                  <Td>{q.negative ? q.negative : "No"}</Td>
                                  <Td>
                                    <ol>
                                      {q.options?.map((o) => (
                                        <li>
                                          <Flex flexDir="column">
                                            {o.option}
                                            <Flex
                                              mt="0.5em"
                                              alignItems="center"
                                            >
                                              <Tag
                                                colorScheme={
                                                  o.correct ? "green" : "red"
                                                }
                                                size="sm"
                                              >
                                                {o.correct ? "True" : "False"}
                                              </Tag>
                                              <Popup
                                                trigger={
                                                  <Button ml="1em" size="xs">
                                                    Edit
                                                  </Button>
                                                }
                                                modal
                                                nested
                                              >
                                                {(close) => (
                                                  <div className="modal">
                                                    <button
                                                      className="close"
                                                      onClick={close}
                                                    >
                                                      &times;
                                                    </button>
                                                    <EditOption
                                                      id={o.id}
                                                      option={o.option}
                                                      correct={o.correct}
                                                      close={close}
                                                    />
                                                  </div>
                                                )}
                                              </Popup>
                                              <Button
                                                ml="1em"
                                                size="xs"
                                                colorScheme="red"
                                                onClick={() =>
                                                  deleteOption(o.id)
                                                }
                                              >
                                                Delete
                                              </Button>
                                            </Flex>
                                          </Flex>
                                        </li>
                                      ))}
                                      <li style={{ listStyle: "none" }}>
                                        {!q.answer || q.answer == "" ? (
                                          <Popup
                                            trigger={
                                              <Button
                                                size="sm"
                                                colorScheme="green"
                                                mt="0.5em"
                                              >
                                                Add
                                              </Button>
                                            }
                                            modal
                                            nested
                                          >
                                            {(close) => (
                                              <div className="modal">
                                                <button
                                                  className="close"
                                                  onClick={close}
                                                >
                                                  &times;
                                                </button>
                                                <CreateOption
                                                  close={close}
                                                  id={q.id}
                                                />
                                              </div>
                                            )}
                                          </Popup>
                                        ) : null}
                                      </li>
                                    </ol>
                                  </Td>
                                  <Td>
                                    <Popup
                                      trigger={<Button>Edit</Button>}
                                      modal
                                      nested
                                    >
                                      {(close) => (
                                        <div className="modal">
                                          <button
                                            className="close"
                                            onClick={close}
                                          >
                                            &times;
                                          </button>
                                          <EditQuestion
                                            id={q.id}
                                            question={q}
                                            close={close}
                                            negativeGrading={
                                              content.negativeGrading
                                            }
                                          />
                                        </div>
                                      )}
                                    </Popup>
                                    <Button
                                      onClick={() => deleteQuestion(q.id)}
                                      colorScheme="red"
                                      ml="1em"
                                      isLoading={isDeletingQuestion}
                                    >
                                      Delete
                                    </Button>
                                  </Td>
                                </Tr>
                              ))}
                          </Tbody>
                        </Table>
                      </TableContainer>
                      <Popup
                        trigger={
                          <Button colorScheme="green" w="fit-content">
                            Add
                          </Button>
                        }
                        modal
                        nested
                      >
                        {(close) => (
                          <div className="modal">
                            <button className="close" onClick={close}>
                              &times;
                            </button>
                            <CreateQuestion
                              negative={content.negativeGrading}
                              test={content.id}
                              close={close}
                            />
                          </div>
                        )}
                      </Popup>
                    </Stack>
                    <Text>Time Estimate: {content?.timeEstimate} minutes</Text>
                    {/* <Button
                      mr="1em"
                      onClick={() =>
                        up({
                          id: content.id,
                          objNo: content.objNo,
                          type: "test",
                        })
                      }
                    >
                      Up
                    </Button>
                    <Button
                      onClick={() =>
                        down({
                          id: content.id,
                          objNo: content.objNo,
                          type: "test",
                        })
                      }
                    >
                      Down
                    </Button> */}
                  </li>
                );
              default:
                return null;
            }
          })}
      </ol>
    </>
  );
}

export default LessonObjs;
