import React, { useState } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { fetchC } from "../utils/customFetch";

function CreateCommentReply(props) {
  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const handleCreate = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    fetchC(
      "https://coursehub-server-de93512846f7.herokuapp.com/api/comments/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          comment,
          lesson: props.lesson,
          parent: props.comment,
        }),
      }
    )
      .then((res) => {
        if (res.ok) {
          toast({
            title: "Comment created.",
            status: "success",
            duration: 3000,
            isClosable: true,
            onCloseComplete: () => {
              props.close();
              window.location.reload();
            },
          });
          setIsLoading(false);
        } else {
          throw res;
        }
      })
      .catch((err) => {
        err.json().then((data) => {
          console.error(data);
        });
        setIsLoading(false);
        toast({
          title: "Failed to create comment.",
          status: "error",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            props.close();
            window.location.reload();
          },
        });
      });
  };

  return (
    <Flex flexDir="column" align="center" padding="2em">
      <Text fontSize="3xl" fontWeight="bold" mb="1em" textOverflow="clip">
        Replying to: {props.commentText}
      </Text>
      <form onSubmit={handleCreate}>
        <FormControl>
          <FormLabel>Comment</FormLabel>
          <Input
            isRequired
            type="text"
            placeholder="Comment"
            value={comment}
            id="c"
            onChange={(e) => setComment(e.target.value)}
          />
        </FormControl>
        <Button
          type="submit"
          mt="2em"
          colorScheme="green"
          isLoading={isLoading}
        >
          Submit
        </Button>
      </form>
    </Flex>
  );
}

export default CreateCommentReply;
