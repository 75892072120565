import { getAuth, onAuthStateChanged } from "firebase/auth";

export async function fetchC(url, options = {}) {
  const auth = getAuth();
  const user = await new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user) => {
        unsubscribe();
        resolve(user);
      },
      reject
    );
  });

  if (user) {
    const idToken = await user.getIdToken();
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${idToken}`,
    };
  }
  return fetch(url, options);
}
