import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./Forms.css";
import UserShort from "./components/UserShort";
import Pagination from "./components/Pagination";
import { fetchC } from "./utils/customFetch";
import { Box, Center, Flex, Text } from "@chakra-ui/react";

function Followers() {
  const { id } = useParams();
  const [followers, setFollowers] = useState([]);
  const [user, setUser] = useState(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const fun = async () => {
      fetchC(
        `https://coursehub-server-de93512846f7.herokuapp.com/api/followers/${id}`,
        {
          method: "GET",
          "Content-Type": "application/json",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setFollowers(data))
        .catch((err) => console.error(err));

      fetchC(
        `https://coursehub-server-de93512846f7.herokuapp.com/api/users/${id}/short`,
        {
          method: "GET",
          "Content-Type": "application/json",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setUser(data.msg))
        .catch((err) => console.error(err));
    };
    fun();
  }, [id]);

  useEffect(() => {
    const fun = async () => {
      fetchC(
        `https://coursehub-server-de93512846f7.herokuapp.com/api/followers/${id}?page=${page}`,
        {
          method: "GET",
          "Content-Type": "application/json",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setFollowers(data))
        .catch((err) => console.error(err));
    };
    fun();
  }, [page]);

  return (
    <Center minH="78vh">
      <Flex
        flexDir="column"
        align="center"
        border="1px"
        px="1em"
        py="2em"
        minW="30vw"
        borderRadius="lg"
        boxShadow="lg"
      >
        <Text fontSize="3xl" fontWeight="bold">
          Followers of {user ? user.username : "Loading..."}
        </Text>
        {followers && followers.msg?.length > 0 ? (
          <>
            {followers.msg?.map((follower) => (
              <Flex
                p="0.5em"
                m="1em"
                minW="60%"
                align="center"
                justify="center"
                border="1px"
                borderRadius="lg"
              >
                <UserShort
                  color="black"
                  key={follower.id}
                  username={follower.username}
                  id={follower.id}
                  img={follower.avatar}
                />
              </Flex>
            ))}
            <Pagination
              setPage={setPage}
              page={page}
              pages={followers.total_pages}
            />
          </>
        ) : (
          <Flex
            p="0.5em"
            m="1em"
            border="1px"
            minW="60%"
            alignItems="center"
            justify="center"
            borderRadius="lg"
          >
            "No followers yet"
          </Flex>
        )}
      </Flex>
    </Center>
  );
}

export default Followers;
