import React, { useEffect, useState } from "react";
import { fetchC } from "./utils/customFetch";
import { Link, useParams } from "react-router-dom";
import {
  Center,
  Divider,
  Heading,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";

function Certificates() {
  const { id } = useParams();

  const [certificates, setCertificates] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/certificates/user/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        setCertificates(data.msg);
        console.log(data.msg);
        setLoading(false);
      })
      .catch((res) => {
        setLoading(false);
        console.log(res);
      });
  }, []);

  function getRandomInt() {
    return Math.floor(Math.random() * 1000);
  }

  return (
    <Center minH="80vh">
      <Stack
        my="2em"
        p="1em"
        border="1px"
        borderRadius="2xl"
        minH="70vh"
        w="70%"
        pt="3em"
      >
        {loading ? (
          <Center h="70vh">
            <Spinner size="xl" />
          </Center>
        ) : (
          <>
            {certificates ? (
              <>
                {certificates.map((certInfo) => {
                  return (
                    <>
                      <Heading ml="2em" fontSize="lg">
                        Verified Certificate
                      </Heading>
                      <Link to={`/course/${certInfo?.courseObj.id}`}>
                        <Text ml="2em" fontSize="lg">
                          Course: {certInfo?.courseObj.title}
                        </Text>
                      </Link>
                      <Center w="100%">
                        <img
                          src={`${
                            certInfo?.certificate
                          }?ignoreCache=${getRandomInt()}`}
                          alt="certificate"
                          style={{
                            width: "80%",
                            height: "auto",
                            borderRadius: "1em",
                            border: "1px solid black",
                            marginTop: "1em",
                            marginBottom: "1em",
                          }}
                        />
                      </Center>
                      <Divider />
                    </>
                  );
                })}
              </>
            ) : (
              <Heading>No certificates found</Heading>
            )}
          </>
        )}
      </Stack>
    </Center>
  );
}

export default Certificates;
