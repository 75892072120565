import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./LessonContents.css";
import Popup from "reactjs-popup";
import LessonObjs from "./components/LessonObjs";
import { fetchC } from "./utils/customFetch";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import ViewLessonObjs from "./components/ViewLessonObjs";
import LessonComments from "./components/LessonComments";
import CreateComment from "./components/CreateComment";

function DoLesson() {
  const { id } = useParams();

  const [contents, setContents] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [comments, setComments] = useState(false);

  const toast = useToast();

  useEffect(() => {
    async function name() {
      fetchC(
        `https://coursehub-server-de93512846f7.herokuapp.com/api/lessons/${id}`,
        {
          method: "GET",
          "Content-Type": "application/json",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setContents(data.msg);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setIsLoading(false);
        });
    }

    name();
  }, []);

  const navigate = useNavigate();

  const complete = () => {
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/lessons/complete`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        "Content-Type": "application/json",
        body: JSON.stringify({ id: id }),
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Lesson completed",
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            if (data.next == "completed") {
              navigate(`/course/${contents.course}`);
            } else if (data.next == "Error") {
              navigate(`/course/${contents.course}`);
            } else {
              navigate(`/lesson-do/${data.next}`);
            }
          },
        });
      })
      .catch((err) => {
        console.error(err);
        toast({
          title: "Error",
          description: "Could not complete lesson",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <Center minH="78vh">
      {id && contents ? (
        <Stack
          padding="2em"
          width={["100vw", "100vw", "75vw"]}
          border={["0px", "0px", "1px"]}
          borderRadius="xl"
          boxShadow={["none", "none", "lg"]}
          my="2em"
        >
          {isLoading ? (
            <Flex w="100%" align="center" justify="center" my="2em">
              <Spinner size="xl" />
            </Flex>
          ) : contents ? (
            <>
              <Breadcrumb>
                <BreadcrumbItem>
                  <BreadcrumbLink href={`/course/${contents.course}`}>
                    Course
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <BreadcrumbLink href={`/course-do/${contents.course}`}>
                    Lessons
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <BreadcrumbLink href="#">
                    {contents.title ? contents.title : "Loading..."}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>
              <Text fontWeight="bold" fontSize="3xl">
                Title: {contents?.title}
              </Text>
              <Text>{contents?.description}</Text>
              <ViewLessonObjs contents={contents} id={id} />
              <Center mb="2em">
                <Button
                  onClick={() => complete()}
                  colorScheme="green"
                  w="fit-content"
                >
                  Mark completed
                </Button>
              </Center>
              <Divider />
              <Flex flexDirection="column" alignItems="center" w="100%">
                <Button
                  w="fit-content"
                  colorScheme="teal"
                  onClick={() => setComments(!comments)}
                  display={comments ? "none" : "block"}
                >
                  Show comments
                </Button>
                {comments ? (
                  <Stack w="100%" display="flex" alignItems="center">
                    <Heading size="md" mt="1em">
                      Comments
                    </Heading>
                    <LessonComments id={id} />
                    <Popup
                      trigger={
                        <Button mt="1em" w="fit-content" colorScheme="green">
                          Add comment
                        </Button>
                      }
                      modal
                      nested
                    >
                      {(close) => (
                        <div className="modal">
                          <button className="close" onClick={close}>
                            &times;
                          </button>
                          <CreateComment close={close} id={id} />
                        </div>
                      )}
                    </Popup>
                  </Stack>
                ) : null}
              </Flex>
            </>
          ) : (
            <Text fontSize="lg" fontWeight="bold">
              Content not found
            </Text>
          )}
        </Stack>
      ) : (
        <h1>No Lesson found</h1>
      )}
    </Center>
  );
}

export default DoLesson;
