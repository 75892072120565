import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./User.css";
import avatar from "./avatar.png";
import { HiCurrencyDollar } from "react-icons/hi2";
import { FaHeart } from "react-icons/fa6";
import { IoPersonAdd } from "react-icons/io5";
import { TbCertificate } from "react-icons/tb";
import EditUser from "./components/EditUser";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import CourseCard from "./components/CourseCard";
import { fetchC } from "./utils/customFetch";
import {
  Button,
  Center,
  Flex,
  Image,
  SimpleGrid,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { MdFlag } from "react-icons/md";
import { IconButton } from "@chakra-ui/react";
import Report from "./components/Report";

function User() {
  const toast = useToast();
  const { id } = useParams();
  const [user, setUser] = useState(null);

  const switchType = async (type) => {
    if (!user || !user.own || !id) {
      toast({
        title: "Warning",
        description: "You can't do that!",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (type != "instructor" && type != "student") {
      toast({
        title: "Warning",
        description: "Invalid type!",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });

      return;
    }
    if (type == user.type) {
      toast({
        title: "Warning",
        description: "You are already that type!",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/users/convert`,
      {
        method: "PATCH",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ type }),
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) =>
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => window.location.reload(false),
        })
      )
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
      });
  };

  const follow = async () => {
    if (!user || user.own || !id) {
      toast({
        title: "Warning",
        description: "You can't do that!",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/followers/`,
      {
        method: "POST",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id }),
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) =>
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => window.location.reload(false),
        })
      )
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
      });
  };

  const unfollow = async () => {
    if (!user || user.own || !id) {
      toast({
        title: "Warning",
        description: "You can't do that!",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });

      return;
    }
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/followers/${id}`,
      {
        method: "DELETE",
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) =>
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => window.location.reload(false),
        })
      )
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
      });
  };

  useEffect(() => {
    const fun = async () => {
      if (id) {
        fetchC(
          `https://coursehub-server-de93512846f7.herokuapp.com/api/users/${id}`,
          {
            method: "GET",
            "Content-Type": "application/json",
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => res.json())
          .then((data) => {
            setUser(data.msg);
          })
          .catch((err) => console.error(err));
      }
    };
    fun();
  }, [id]);

  const deactivateAll = async () => {
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/courses/deactivate/all/${id}`,
      {
        method: "PATCH",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => window.location.reload(false),
        });
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
      });
  };

  return (
    <Center w="100%">
      <Flex
        my="2vh"
        mx="5vw"
        w="100%"
        flexDir={["column", "column", "column", "row"]}
      >
        <Flex
          flexDir="column"
          py="2vh"
          px="1vw"
          mr="5vw"
          my="5vh"
          border="1px"
          minW={"25vw"}
          align="center"
          borderRadius="2xl"
          boxShadow={"xl"}
        >
          <Image
            mt="2em"
            mb="1em"
            w="15vw"
            borderRadius="full"
            src={
              user ? (user.avatar == "default" ? avatar : user.avatar) : avatar
            }
          />
          <Text fontSize="xl" fontWeight="bold">
            {user ? user.username : "Loading..."}
          </Text>
          <p>
            <u>{user ? user.email : null}</u>
          </p>
          <Link style={{ color: "black" }} to={`/followers/${id}`}>
            {user ? user.followers_count : "Loading..."} Followers
          </Link>
          <p className="User__bio">
            {user
              ? user.bio
                ? user.bio
                : "This user has no bio yet."
              : "Loading..."}
          </p>
          {user && user.own ? (
            <>
              <Popup trigger={<Button my="0.5em">Edit</Button>} modal nested>
                {(close) => (
                  <div className="modal">
                    <button className="close" onClick={close}>
                      &times;
                    </button>
                    <EditUser close={close} user={user} id={id} />
                  </div>
                )}
              </Popup>
              <Button
                my="0.5em"
                colorScheme="teal"
                onClick={() => {
                  switchType(
                    user.type == "instructor" ? "student" : "instructor"
                  );
                }}
              >
                Switch to {user.type == "instructor" ? "student" : "instructor"}
              </Button>
              {user.type == "instructor" ? (
                <>
                  <Link
                    to="/create"
                    style={{ color: "black", textDecoration: "underline" }}
                  >
                    Create a course
                  </Link>
                  {user?.instructs.length > 0 &&
                  user?.instructs.some((v) => {
                    return v.status == "active";
                  }) ? (
                    <Button
                      mt="1em"
                      colorScheme="red"
                      onClick={() => deactivateAll()}
                    >
                      Deactivate all
                    </Button>
                  ) : null}
                </>
              ) : null}
            </>
          ) : (
            <Popup
              trigger={
                <IconButton
                  bg="none"
                  border="1px solid red"
                  _hover={{ bg: "red", color: "white" }}
                  icon={<MdFlag />}
                  w="fit-content"
                />
              }
              modal
              nested
            >
              {(close) => (
                <div className="modal">
                  <button className="close" onClick={close}>
                    &times;
                  </button>
                  <Report close={close} type={"user"} id={id} />
                </div>
              )}
            </Popup>
          )}
          {user &&
          user.own != undefined &&
          !user.own &&
          user.follows != undefined &&
          !user.follows ? (
            <Button mt="1em" onClick={() => follow()}>
              Follow
            </Button>
          ) : null}
          {user &&
          user.own != undefined &&
          !user.own &&
          user.follows != undefined &&
          user.follows ? (
            <Button mt="1em" onClick={() => unfollow()}>
              Unfollow
            </Button>
          ) : null}
          <div className="User__rhscont">
            <div className="User__rightside">
              {user && user.own ? (
                <>
                  <div className="User__iconcont">
                    <HiCurrencyDollar className="User__icon" />
                    <p className="User__text">Payment info</p>
                  </div>
                  <div className="User__iconcont">
                    <FaHeart className="User__icon" />
                    <p className="User__text">Favourites</p>
                  </div>
                </>
              ) : null}
              <div className="User__iconcont">
                <IoPersonAdd className="User__icon" />
                <Link to={`/following/${id}`} className="User__text">
                  Following
                </Link>
              </div>
              <div className="User__iconcont">
                <TbCertificate className="User__icon" />
                <Link to={`/certificates/${id}`} className="User__text">
                  Certificates
                </Link>
              </div>
            </div>
          </div>
        </Flex>
        <Flex flexDir="column" my="5vh" w="100%">
          <Text fontSize="4xl" fontWeight="bold" mb="1em">
            Courses
          </Text>
          <Tabs colorScheme="green" w="100%" variant="soft-rounded">
            <TabList
              mb="2em"
              display="flex"
              alignItems="center"
              justifyContent="space-evenly"
            >
              <Tab>Instructs</Tab>
              <Tab>Pursuing</Tab>
              <Tab>Completed</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                {user ? (
                  <SimpleGrid columns={[1, 1, 2]} spacing={10}>
                    {user?.instructs?.map((course) => (
                      <CourseCard
                        thumbnail={course.thumbnail}
                        price={course.price}
                        title={course.title}
                        id={course.id}
                        bestseller={course.bestseller}
                        rating={course.rating}
                        ratingNumber={course.ratingNum}
                        instructor={user}
                        edit={user?.own ? true : false}
                        status={course.status}
                      />
                    ))}
                  </SimpleGrid>
                ) : (
                  <Flex align="center" justify="center" my="2em">
                    <Spinner size="xl" />
                  </Flex>
                )}
              </TabPanel>
              <TabPanel>
                <SimpleGrid columns={[1, 1, 2]} spacing={10}>
                  {user?.pursues?.map((course) => (
                    <CourseCard
                      thumbnail={course.thumbnail}
                      price={course.price}
                      title={course.title}
                      id={course.id}
                      bestseller={course.bestseller}
                      rating={course.rating}
                      ratingNumber={course.ratingNum}
                      instructor={course.instructorObj}
                      edit={false}
                    />
                  ))}
                </SimpleGrid>
              </TabPanel>
              <TabPanel>
                <SimpleGrid columns={[1, 1, 2]} spacing={10}>
                  {user?.coursesCompleted?.map((course) => (
                    <CourseCard
                      thumbnail={course.thumbnail}
                      price={course.price}
                      title={course.title}
                      id={course.id}
                      bestseller={course.bestseller}
                      rating={course.rating}
                      ratingNumber={course.ratingNum}
                      instructor={course.instructorObj}
                      edit={false}
                    />
                  ))}
                </SimpleGrid>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Flex>
      </Flex>
    </Center>
  );
}

export default User;
