import React, { useState } from "react";
import "./EditUser.css";
import avatar from "../avatar.png";
import { fetchC } from "../utils/customFetch";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";

function EditUser(props) {
  const [email, setEmail] = useState(props.user.email);
  const [username, setUsername] = useState(props.user.username);
  const [bio, setBio] = useState(props.user.bio);
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [fullname, setFullname] = useState(props.user.fullName);

  const [isUpdating, setIsUpdating] = useState(false);
  const [isAvatarUpdating, setIsAvatarUpdating] = useState(false);
  const [isAvatarRemoving, setIsAvatarRemoving] = useState(false);

  const toast = useToast();

  const storage = getStorage();

  const update = async (e) => {
    e.preventDefault();
    setIsUpdating(true);
    if (!props.user || !props.user.own) {
      toast({
        title: "Warning",
        description: "You can't do that!",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    let obj = {};
    if (email) obj.email = email;
    if (username) obj.username = username;
    if (bio) obj.bio = bio;
    if (fullname) obj.fullname = fullname;

    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/users/${props.id}`,
      {
        method: "PATCH",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            props.close();
            window.location.reload();
          },
        });
        setIsUpdating(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsUpdating(false);
      });
  };

  const updateAvatar = async () => {
    if (!props.user || !props.user.own) {
      toast({
        title: "Warning",
        description: "You can't do that!",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (!file) {
      toast({
        title: "Warning",
        description: "You must select a file!",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // check file extension
    const extension = file.name.split(".").pop();
    if (!["png", "jpg", "jpeg"].includes(extension)) {
      toast({
        title: "Alert",
        description: "File must be an image!",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsAvatarUpdating(true);

    // upload image to firebase storage

    const storageRef = ref(
      storage,
      `avatars/${props.id}+${file.name.split(".")[1]}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Track upload progress here if needed
      },
      (error) => {
        toast({
          title: "Error",
          description: "Failed to upload file",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setIsAvatarUpdating(false);
      },
      () => {
        // Upload completed successfully
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          fetchC(
            `https://coursehub-server-de93512846f7.herokuapp.com/api/avatars`,
            {
              "Content-Type": "application/json",
              headers: {
                "Content-Type": "application/json",
              },
              method: "POST",
              body: JSON.stringify({
                url: downloadURL,
                userId: props.id,
              }),
            }
          )
            .then((res) => {
              if (!res.ok) {
                throw res;
              }
              return res.json();
            })
            .then((data) => {
              toast({
                title: "Success",
                description: data.msg,
                status: "success",
                duration: 3000,
                isClosable: true,
              });
              setIsAvatarUpdating(false);
            })
            .catch((res) => {
              res.json().then((data) => {
                toast({
                  title: "Error",
                  description: data.msg,
                  status: "error",
                  duration: 3000,
                  isClosable: true,
                });
              });
              setIsAvatarUpdating(false);
            });
        });
      }
    );
  };

  const removeAvatar = async () => {
    let answer = window.confirm("Are you sure you want to remove your avatar?");

    if (!answer) return;

    setIsAvatarRemoving(true);

    if (!props.user || !props.user.own || !props.user.avatar) {
      toast({
        title: "Warning",
        description: "You can't do that!",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    fetch(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/avatars/${props.id}`,
      {
        method: "DELETE",
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            props.close();
            window.location.reload();
          },
        });
        setIsAvatarRemoving(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsAvatarRemoving(false);
      });
  };
  return (
    <Flex
      maxH={"80vh"}
      overflowY="scroll"
      flexDir="column"
      align="center"
      padding="2em"
    >
      <Text fontSize="3xl" fontWeight="bold">
        Edit profile
      </Text>
      <Flex flexDir="column" align="center" my="1em">
        <img
          className="EditUser__avatar"
          src={
            previewUrl
              ? previewUrl
              : props.user.avatar == "default"
              ? avatar
              : props.user.avatar
          }
          alt=""
        />
        <input
          id="file"
          type="file"
          size="60"
          onChange={(e) => {
            setFile(e.target.files[0]);
            setPreviewUrl(URL.createObjectURL(e.target.files[0]));
          }}
          multiple={false}
        />
      </Flex>
      <Flex justifyContent="space-between" width="40%" my="2em">
        <Button onClick={() => updateAvatar()} isLoading={isAvatarUpdating}>
          Update avatar
        </Button>
        <Button
          colorScheme="red"
          onClick={() => removeAvatar()}
          isLoading={isAvatarRemoving}
        >
          Remove avatar
        </Button>
      </Flex>
      <form onSubmit={update}>
        <FormControl maxW="80%">
          <FormLabel>Username</FormLabel>
          <Input
            isRequired
            maxLength={150}
            id="username"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </FormControl>
        <FormControl maxW="80%" mt={"1em"}>
          <FormLabel>Fullname</FormLabel>
          <Input
            isRequired
            maxLength={150}
            id="fullname"
            type="text"
            value={fullname}
            onChange={(e) => setFullname(e.target.value)}
          />
        </FormControl>
        <FormControl maxW="80%" mt={"1em"}>
          <FormLabel>Email</FormLabel>
          <Input
            isRequired
            maxLength={150}
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
        <FormControl maxW="80%" mt={"1em"}>
          <FormLabel>Bio</FormLabel>
          <Textarea
            maxLength={500}
            value={bio}
            onChange={(e) => setBio(e.target.value)}
          ></Textarea>
        </FormControl>
        {props.user && props.user.own ? (
          <>
            <Button
              isLoading={isUpdating}
              colorScheme="green"
              mt="2em"
              type="submit"
            >
              Submit
            </Button>
          </>
        ) : null}
      </form>
    </Flex>
  );
}

export default EditUser;
