import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { fetchC } from "../utils/customFetch";

function CreateFAQ(props) {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const createFAQ = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    fetchC(`https://coursehub-server-de93512846f7.herokuapp.com/api/faqs/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        question,
        answer,
        course: props.id,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            props.close();
            window.location.reload();
          },
        });
        setIsLoading(false);
      })
      .catch((res) => {
        toast({
          title: "Error",
          description: "Failed to create FAQ",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      });
  };
  return (
    <>
      {props.id ? (
        <Flex flexDir="column" align="center" padding="2em">
          <Text fontSize="3xl" fontWeight="bold">
            Create FAQ
          </Text>
          <form onSubmit={createFAQ}>
            <FormControl>
              <FormLabel>Question</FormLabel>
              <Input
                isRequired
                maxLength={500}
                type="text"
                placeholder="Question"
                id="q"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
              />
            </FormControl>
            <FormControl mt="2em">
              <FormLabel>Answer</FormLabel>
              <Input
                isRequired
                maxLength={100}
                type="text"
                placeholder="Answer"
                id="a"
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
              />
            </FormControl>
            <Button
              isLoading={isLoading}
              mt="2em"
              colorScheme="green"
              type="submit"
            >
              Submit
            </Button>
          </form>
        </Flex>
      ) : (
        <Text>No course</Text>
      )}
    </>
  );
}

export default CreateFAQ;
