import React, { useEffect, useState } from "react";
import "../Forms.css";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import { fetchC } from "../utils/customFetch";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Text,
  useToast,
} from "@chakra-ui/react";

function EditMd(props) {
  const toast = useToast();
  const [timeEstimate, setTimeEstimate] = useState(props.md.timeEstimate);
  const [markdown, setMarkdown] = useState(props.md.markdown);
  const [isLoading, setIsLoading] = useState(false);

  const edit = async () => {
    setIsLoading(true);
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/markdown/${props.id}`,
      {
        method: "PATCH",
        body: JSON.stringify({ markdown, timeEstimate }),
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            props.close();
            window.location.reload();
          },
        });
        setIsLoading(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      {props.id ? (
        <Flex flexDir="column" align="center" padding="2em">
          <Text mb="1em" fontSize="3xl" fontWeight="bold">
            Modify Markdown
          </Text>
          <Flex w="100%" data-color-mode="light">
            <MDEditor
              style={{ width: "100%" }}
              className="md"
              value={markdown}
              onChange={setMarkdown}
              previewOptions={{
                rehypePlugins: [[rehypeSanitize]],
              }}
            />
          </Flex>
          <FormControl mt="2em">
            <FormLabel>Time Estimate</FormLabel>
            <InputGroup>
              <Input
                type="number"
                id="time"
                name="time"
                value={timeEstimate}
                onChange={(e) => setTimeEstimate(e.target.value)}
              />
              <InputRightAddon>min</InputRightAddon>
            </InputGroup>
          </FormControl>
          <Button
            isLoading={isLoading}
            mt="2em"
            colorScheme="green"
            onClick={() => edit()}
          >
            Submit
          </Button>
        </Flex>
      ) : (
        <h1>Section not found</h1>
      )}
    </>
  );
}

export default EditMd;
