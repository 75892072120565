import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import CreateCriteria from "./components/CreateCriteria";
import EditCriteria from "./components/EditCriteria";
import { Draggable } from "react-drag-reorder";
import { MdEdit } from "react-icons/md";
import { useParams } from "react-router-dom";
import { fetchC } from "./utils/customFetch";
import {
  Button,
  Center,
  Divider,
  Flex,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";

function arraysEqual(arr1, arr2) {
  // js function to check 2 list of object are equal
  if (arr1.length !== arr2.length) return false;

  for (let i = 0; i < arr1.length; i++) {
    const obj1 = arr1[i];
    const obj2 = arr2[i];

    if (Object.keys(obj1).length !== Object.keys(obj2).length) return false;

    for (let key in obj1) {
      if (obj1[key] !== obj2[key]) return false;
    }
  }

  return true;
}

function AssignmentRuberic() {
  const { id } = useParams();
  const toast = useToast();

  const [criterias, setCriterias] = useState([]);
  const [criteriaIds, setCriteriaIds] = useState([]);
  const [reordered, setReordered] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchCriterias = async () => {
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/assignments/criteria/${id}`,
      {
        method: "GET",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        const newCriterias = [...data.msg];
        newCriterias.sort((a, b) => a.criteriaNumber - b.criteriaNumber);
        setCriterias(newCriterias);
        const newCriteriaIds = newCriterias.map((criteria) => criteria.id);
        setCriteriaIds(newCriteriaIds);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchCriterias();
  }, []);

  const [isDeleting, setIsDeleting] = useState(false);

  const deleteCriteria = async (id) => {
    let confirm = window.confirm(
      "Are you sure you want to delete this criteria?"
    );
    if (!confirm) return;
    setIsDeleting(true);
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/assignments/criteria/${id}`,
      {
        method: "DELETE",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            window.location.reload();
          },
        });
        setIsDeleting(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsDeleting(false);
      });
  };

  const getChangedPosAss = (currentPos, newPos) => {
    const newCriteriaIds = [...criteriaIds];
    const movedCriteria = newCriteriaIds.splice(currentPos, 1)[0];
    newCriteriaIds.splice(newPos, 0, movedCriteria);
    setCriteriaIds(newCriteriaIds);
    setReordered(true);
  };

  const [isReordering, setIsReordering] = useState(false);

  const order = async () => {
    setIsReordering(true);
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/assignments/criteria/reorder/${id}`,
      {
        method: "PATCH",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ criteria: criteriaIds }),
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            window.location.reload();
          },
        });
        setIsReordering(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsReordering(false);
      });
    setReordered(false);
  };
  return (
    <Center minH="78vh">
      {isLoading ? (
        <Spinner size="xl" />
      ) : (
        <Flex
          flexDir="column"
          align="center"
          padding="2em"
          border={["0px", "0px", "1px"]}
          width={["100vw", "100vw", "75vw"]}
          minH="70vh"
          borderRadius="xl"
          boxShadow={["none", "none", "lg"]}
          justifyContent="flex-end"
          my="2em"
        >
          {criterias && criterias.length > 0 ? (
            <>
              <Text fontSize="3xl" fontWeight="bold">
                Criterias
              </Text>
              <Button
                hidden={!reordered}
                onClick={() => order()}
                size="xs"
                isLoading={isReordering}
              >
                Save order
              </Button>
              <ol>
                <Draggable onPosChange={getChangedPosAss}>
                  {criterias.map((criteria) => (
                    <li>
                      <Flex>
                        <Text fontWeight="bold">{criteria.description}</Text>
                        <Popup
                          trigger={
                            <MdEdit
                              style={{
                                cursor: "pointer",
                              }}
                              size={18}
                            />
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="modal">
                              <button className="close" onClick={close}>
                                &times;
                              </button>
                              <EditCriteria
                                criteria={criteria}
                                close={close}
                                id={criteria.id}
                              />
                            </div>
                          )}
                        </Popup>
                      </Flex>
                      <Text>{criteria.points} pt</Text>
                      <Button
                        colorScheme="red"
                        size="sm"
                        onClick={() => deleteCriteria(criteria.id)}
                        isLoading={isDeleting}
                      >
                        Delete
                      </Button>
                    </li>
                  ))}
                </Draggable>
              </ol>
            </>
          ) : (
            <h1>No Criterias yet</h1>
          )}
          <Divider mt="2em" />
          <Popup
            trigger={
              <Button mt="2em" colorScheme="green" w="fit-content">
                New criteria
              </Button>
            }
            modal
            nested
          >
            {(close) => (
              <div className="modal">
                <button className="close" onClick={close}>
                  &times;
                </button>
                <CreateCriteria close={close} id={id} />
              </div>
            )}
          </Popup>
        </Flex>
      )}
    </Center>
  );
}

export default AssignmentRuberic;
