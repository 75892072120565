import React, { useState } from "react";
import "./EditReview.css";
import { fetchC } from "../utils/customFetch";
import { Button, useToast } from "@chakra-ui/react";

function EditReview(props) {
  const toast = useToast();
  const [review, setReview] = useState(props.review);
  const [rating, setRating] = useState(props.rating);
  const [isLoading, setIsLoading] = useState(false);

  const editReview = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let obj = {};
    if (review) obj.review = review;
    if (rating || rating == 0) obj.rating = rating;
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/reviews/${props.id}`,
      {
        method: "PATCH",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            props.close();
            window.location.reload();
          },
        });
        setIsLoading(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsLoading(false);
      });
  };
  return (
    <div className="EditReview">
      <h1 className="header">Edit review</h1>
      <label htmlFor="rating">Rating</label>
      <form onSubmit={editReview}>
        <input
          id="rating"
          type="number"
          className="EditUser__input"
          value={rating}
          onChange={(e) => setRating(e.target.value)}
          min={0}
          max={5}
        />
        <label htmlFor="review">Review</label>
        <textarea
          id="review"
          className="EditUser__input"
          value={review}
          onChange={(e) => setReview(e.target.value)}
          maxLength={500}
        />
        <div className="actions">
          <button className="EditReview__button" onClick={() => props.close()}>
            Cancel
          </button>
          <Button colorScheme="green" isLoading={isLoading} type="submit">
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}

export default EditReview;
