import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import MDEditor from "@uiw/react-md-editor";
import EditTest from "./EditTest";
import EditMd from "./EditMd";
import { fetchC } from "../utils/customFetch";
import EditQuestion from "./EditQuestion";
import {
  Button,
  Center,
  Flex,
  IconButton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import CreateQuestion from "./CreateQuestion";
import { Link } from "react-router-dom";

// FIXME: Style videos

function ViewLessonObjs(props) {
  const [objIds, setObjIds] = useState([]);

  useEffect(() => {
    let videos = props.contents?.videos || [];
    let markdowns = props.contents?.markdowns || [];
    let tests = props.contents?.tests || [];

    let vidids = videos.map((v) => {
      return { id: v.id, objNo: v.objNo, type: "video" };
    });

    let mdids = markdowns.map((m) => {
      return { id: m.id, objNo: m.objNo, type: "markdown" };
    });

    let testids = tests.map((t) => {
      return { id: t.id, objNo: t.objNo, type: "test" };
    });

    let allids = vidids.concat(mdids).concat(testids);
    allids.sort((a, b) => a.objNo - b.objNo);
    setObjIds(allids);
  }, [props.contents]);

  return (
    <>
      <ol style={{ marginLeft: "2em", listStyle: "none" }}>
        {objIds
          .sort((a, b) => a.objNo - b.objNo)
          .map((contentObj) => {
            let content;
            switch (contentObj.type) {
              case "video":
                content = props.contents.videos.find(
                  (video) => video.id === contentObj.id
                );
                return (
                  <li
                    key={content.id + " " + content.objNo}
                    style={{
                      border: "1px solid orange",
                      borderRadius: "1em",
                      margin: "1em",
                      padding: "1em",
                    }}
                  >
                    <Text fontSize="lg" fontWeight="bold">
                      Video:
                    </Text>
                    <Center>
                      <video
                        style={{ borderRadius: "1em" }}
                        width="500"
                        controls
                        src={content?.video}
                      />
                    </Center>
                  </li>
                );
              case "markdown":
                content = props.contents.markdowns.find(
                  (markdown) => markdown.id === contentObj.id
                );
                return (
                  <li
                    key={content.id + " " + content.objNo}
                    data-color-mode="light"
                    style={{
                      margin: "1em",
                      border: "1px solid blue",
                      padding: "1em",
                      borderRadius: "1em",
                    }}
                  >
                    <MDEditor.Markdown source={content?.markdown} />
                  </li>
                );
              case "test":
                content = props.contents.tests.find(
                  (test) => test.id === contentObj.id
                );
                return (
                  <li
                    key={content.id + " " + content.objNo}
                    style={{
                      margin: "1em",
                      border: "1px solid green",
                      padding: "1em",
                      borderRadius: "1em",
                    }}
                  >
                    <Text fontSize="xl">
                      <b>Test:</b> {content?.title}
                    </Text>
                    <Text mb="0.5em">{content?.description}</Text>
                    <Stack m="1em" border="1px" p="1em" borderRadius="1em">
                      <p>
                        <b>Time:</b> {content?.time} minutes
                      </p>
                      <p>
                        <b>Max attempts:</b> {content?.max}x
                      </p>
                      <p>
                        <b>Passing score:</b> {content?.passing}pt
                      </p>
                      <p>
                        <b>Delay:</b> {content?.delay}
                      </p>
                      <p>
                        <b>Negative grading:</b>{" "}
                        {content?.negativeGrading == true
                          ? "Yes"
                          : content.negativeGrading == false
                          ? "No"
                          : "Unknown"}
                      </p>
                    </Stack>
                    <Link
                      style={{
                        padding: "0.5em 1em 0.5em 1em",
                        margin: "1em",
                        borderRadius: "0.5em",
                        color: "white",
                        backgroundColor: "#3182ce",
                      }}
                      to={`/test-do/${content?.id}`}
                    >
                      Take test
                    </Link>
                  </li>
                );
              default:
                return null;
            }
          })}
      </ol>
    </>
  );
}

export default ViewLessonObjs;
