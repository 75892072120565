import { Box, Divider, Spinner, Stack, Tag, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function FreeLessons(props) {
  const [lessons, setLessons] = useState(null);

  useEffect(() => {
    fetch(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/courses/${props.id}/free_lessons`,
      {
        method: "GET",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error();
        } else {
          return res.json();
        }
      })
      .then((data) => {
        setLessons(data.msg);
        console.log(data.msg);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <>
      {lessons ? (
        <Stack p="1em">
          {lessons.map((l) => {
            return (
              <Box>
                <Link to={`/lesson-do/${l.id}`}>
                  <Text fontWeight="bold">{l.title}</Text>
                  <Box ml="1em">
                    <Text size="sm">Description: {l.description}</Text>
                    <Tag colorScheme="blue">
                      Time estimate: {l.timeEstimate} min
                    </Tag>
                  </Box>
                </Link>
                <Divider my="1em" />
              </Box>
            );
          })}
        </Stack>
      ) : (
        <Spinner />
      )}
    </>
  );
}

export default FreeLessons;
