import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./CourseView.css";
import UserShort from "./components/UserShort";
import { FaStar } from "react-icons/fa";
import { UserContext } from "./index";
import { MdDelete, MdEdit, MdErrorOutline, MdFlag } from "react-icons/md";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import EditReview from "./components/EditReview";
import Pagination from "./components/Pagination";
import { fetchC } from "./utils/customFetch";
import { FaCheck } from "react-icons/fa";
import {
  Box,
  Button,
  Center,
  Flex,
  IconButton,
  ListItem,
  OrderedList,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  UnorderedList,
  Heading,
  Divider,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import Report from "./components/Report";
import Discussions from "./components/Discussions";
import CourseCertificate from "./components/CourseCertificate";
import FreeLessons from "./components/FreeLessons";

function CourseView() {
  const toast = useToast();
  const { id } = useParams();

  const { user, setUser, fetchUser } = useContext(UserContext);

  const [course, setCourse] = useState(null);
  const [days, setDays] = useState(0);
  const [reviews, setReviews] = useState(null);

  const [review, setReview] = useState("");
  const [rating, setRating] = useState(0);

  const [tab, setTab] = useState(0);

  const [page, setPage] = useState(1);

  const [isLoading, setIsLoading] = useState(true);
  const [isReviewing, setIsReviewing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isJoining, setIsJoining] = useState(false);
  const [isGettingCert, setIsGettingCert] = useState(false);

  const navigate = useNavigate();

  const reviewHandler = async (e) => {
    e.preventDefault();
    setIsReviewing(true);
    fetchC(`https://coursehub-server-de93512846f7.herokuapp.com/api/reviews/`, {
      method: "POST",
      "Content-Type": "application/json",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ course: id, review, rating }),
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            window.location.reload();
          },
        });
        setIsReviewing(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsReviewing(false);
      });
  };

  function formatDays() {
    const years = Math.floor(days / 365);
    const months = Math.floor((days % 365) / 30);
    const days_int = days % 30;

    let result = "";
    if (years > 0) {
      result +=
        years > 0 ? (years === 1 ? `${years} year` : `${years} years`) : "";
    }
    if (months > 0) {
      if (result) result += ", ";
      result +=
        months > 0
          ? months === 1
            ? `${months} month`
            : `${months} months`
          : "Less than a month";
    }
    if (days_int > 0 && years === 0 && months === 0) {
      result += days_int === 1 ? `${days_int} day` : `${days_int} days`;
    }
    if (years === 0 && months === 0 && days_int === 0) {
      result = "Less than a day";
    }
    return result;
  }

  useEffect(() => {
    const fun = async () => {
      fetchC(
        `https://coursehub-server-de93512846f7.herokuapp.com/api/courses/${id}`,
        {
          method: "GET",
          "Content-Type": "application/json",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw res;
          }
        })
        .then((data) => {
          setCourse(data.msg);
          setDays(Math.ceil(data.msg?.timeEstimate / 60) / data.msg?.hrs);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setIsLoading(false);
        });
    };
    fun();
  }, []);

  const deleteReview = async (id) => {
    let answer = window.confirm("Are you sure you want to delete this review?");
    if (!answer) return;
    setIsDeleting(true);
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/reviews/${id}`,
      {
        method: "DELETE",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            window.location.reload();
          },
        });
        setIsDeleting(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsDeleting(false);
      });
  };

  useEffect(() => {
    const fun = async () => {
      fetchC(
        `https://coursehub-server-de93512846f7.herokuapp.com/api/reviews/${id}?page=${page}`,
        {
          method: "GET",
          "Content-Type": "application/json",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.ok) {
            res.json().then((data) => setReviews(data));
          } else {
            console.error("Error");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };
    fun();
  }, [page]);

  const joinCourse = async () => {
    if (!user) {
      toast({
        title: "Alert",
        description: "You need to be logged in to join a course",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    let answer = window.confirm("Are you sure you want to join this course?");

    if (!answer) return;
    setIsJoining(true);
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/courses/join`,
      {
        method: "POST",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: id }),
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            fetchUser();
            window.location.reload();
          },
        });
        setIsJoining(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsJoining(false);
      });
  };

  const getCert = () => {
    if (!user) {
      toast({
        title: "Alert",
        description: "You need to be logged in to get a certificate",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setIsGettingCert(true);
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/courses/generate_certificate`,
      {
        method: "POST",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ course: id }),
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            fetchUser();
          },
        });
        console.log(data.url);
        setIsGettingCert(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsGettingCert(false);
      });
  };

  return (
    <Flex py="3vh" px="5vw" justify="space-between">
      {isLoading ? (
        <Flex width="100vw" h="78vh" align="center" justify="center">
          <Spinner size="xl" />
        </Flex>
      ) : id ? (
        <>
          <Stack w="100%">
            <Stack
              p="3em"
              bgGradient="linear(to-r, rgba(148, 34, 255, 0.856), rgba(255, 123, 62, 0.644))"
              borderRadius="2xl"
            >
              <Text color="white" fontSize="3xl" fontWeight="bold">
                Course Title: {course?.title}
              </Text>
              <Box my="2vh">
                <Text color="white" fontWeight="bold" fontSize="xl">
                  Description:
                </Text>
                <Text color="white">{course?.description}</Text>
              </Box>

              {course?.pursuing ? (
                <>
                  <Button
                    isLoading={isGettingCert}
                    w="fit-content"
                    onClick={() => getCert()}
                  >
                    View Certificate
                  </Button>
                  <Button
                    colorScheme="orange"
                    w="fit-content"
                    onClick={() => navigate(`/course-do/${id}`)}
                  >
                    Go to course
                  </Button>
                </>
              ) : (
                <Button
                  isLoading={isJoining}
                  width="fit-content"
                  colorScheme="green"
                  disabled={course?.status != "active"}
                  onClick={() => joinCourse()}
                >
                  Enroll Today For ₹ {course?.price}
                </Button>
              )}
              <Popup
                trigger={
                  <IconButton
                    bg="none"
                    border="1px solid red"
                    _hover={{ bg: "red", color: "white" }}
                    icon={<MdFlag />}
                    w="fit-content"
                  />
                }
                modal
                nested
              >
                {(close) => (
                  <div className="modal">
                    <button className="close" onClick={close}>
                      &times;
                    </button>
                    <Report close={close} type={"course"} id={id} />
                  </div>
                )}
              </Popup>
              <Flex align="center" mt="5vh">
                <Text color="white" mb="0.5em">
                  Taught by:
                </Text>{" "}
                <UserShort
                  id={course?.instructorObj?.id}
                  username={course?.instructorObj.username}
                  img={course?.instructorObj.avatar}
                  color="white"
                />
              </Flex>
            </Stack>
            <Tabs colorScheme="green" w="100%">
              <TabList
                display="flex"
                alignItems="center"
                justifyContent="space-evenly"
                mt="2em"
                flexWrap="wrap"
              >
                <Tab>Info</Tab>
                <Tab>Reviews</Tab>
                <Tab>FAQs</Tab>
                <Tab>Discussions</Tab>
                <Tab>Certificate template</Tab>
                <Tab>Sample</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <div className="CourseView__details">
                    <Heading size="lg">Info</Heading>
                    <Flex align="center">
                      {course?.rating}
                      <FaStar color="green" />({course?.ratingNum})
                    </Flex>
                    <p>
                      <b>Duration: </b>
                      {formatDays()} at {course?.hrs} hr/day
                    </p>
                    <Flex align="center">
                      <Text mr="0.5em" fontWeight="bold">
                        Flexible schedule
                      </Text>{" "}
                      <FaCheck />
                    </Flex>
                    {course?.ai ? (
                      <Flex align="center">
                        <Text mr="0.5em" fontWeight="bold">
                          AI Assisted learning
                        </Text>{" "}
                        <FaCheck />
                      </Flex>
                    ) : null}
                    <p className="CourseView__skills">
                      <span>
                        <b>Skills:</b>
                      </span>{" "}
                      {course?.skills ? course?.skills : "N/A"}
                    </p>
                    <p>
                      <span>
                        <b>Taught in:</b>
                      </span>{" "}
                      {course?.language}
                    </p>
                    <Text fontSize="xl" fontWeight="bold">
                      Sections:
                    </Text>
                    <OrderedList py="1em" px="2em" ml="2em">
                      {course?.sections?.map((section) => (
                        <ListItem>
                          <span style={{ fontWeight: 900 }}>
                            {section?.title}
                          </span>
                          <hr />
                        </ListItem>
                      ))}
                    </OrderedList>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="CourseView__details">
                    <Heading size="lg">Reviews</Heading>
                    {reviews?.msg && reviews?.msg.length > 0 ? (
                      <>
                        {reviews?.msg.map((review) => (
                          <Box p="1em" key={review?.id}>
                            <Flex alignItems="center">
                              <Text mr="0.5em">{review?.rating}</Text>
                              <FaStar />
                            </Flex>
                            <Text>{review?.review}</Text>
                            <UserShort
                              id={review?.writerObj?.id}
                              username={review?.writerObj?.username}
                              img={review?.writerObj?.avatar}
                              color="black"
                              rev={true}
                            />
                            <Text fontSize="xs">
                              Created: {review?.created?.split(" ")[0]}
                            </Text>
                            {user && user.id === review?.writerObj?.id ? (
                              <>
                                <Popup
                                  trigger={<Button size="sm">Edit</Button>}
                                  modal
                                  nested
                                >
                                  {(close) => (
                                    <div className="modal">
                                      <button className="close" onClick={close}>
                                        &times;
                                      </button>
                                      <EditReview
                                        close={close}
                                        rating={review?.rating}
                                        review={review?.review}
                                        id={review?.id}
                                      />
                                    </div>
                                  )}
                                </Popup>
                                <Button
                                  colorScheme="red"
                                  ml="0.5em"
                                  size="sm"
                                  isLoading={isDeleting}
                                  onClick={() => deleteReview(review?.id)}
                                >
                                  Delete
                                </Button>
                              </>
                            ) : null}
                            <Divider mt="1em" border="1px solid gray" />
                          </Box>
                        ))}
                        <Pagination
                          setPage={setPage}
                          page={page}
                          pages={reviews.total_pages}
                        />
                      </>
                    ) : (
                      <h1>No reviews yet</h1>
                    )}
                    {course?.pursuing && user && reviews?.reviewed == false ? (
                      <form
                        className="CourseView__reviewForm"
                        onSubmit={(e) => reviewHandler(e)}
                      >
                        <div className="CourseView__stars">
                          <span>Rating</span>
                          {[1, 2, 3, 4, 5].map((num) => {
                            return (
                              <div>
                                <span
                                  onClick={(e) => setRating(num)}
                                  className={
                                    rating < num
                                      ? "Search__star"
                                      : "Search__starSelected"
                                  }
                                >
                                  &#9733;
                                </span>
                              </div>
                            );
                          })}
                        </div>
                        <span>Review</span>
                        <Textarea
                          maxLength={500}
                          placeholder="Review"
                          value={review}
                          onChange={(e) => setReview(e.target.value)}
                        />
                        <Button
                          colorScheme="green"
                          isLoading={isReviewing}
                          type="submit"
                        >
                          Submit
                        </Button>
                      </form>
                    ) : null}
                  </div>
                </TabPanel>
                <TabPanel>
                  <Heading size="lg" mb="0.5em">
                    FAQs
                  </Heading>
                  {course &&
                  "faqs" in course &&
                  course?.faqs &&
                  Array.isArray(course?.faqs) &&
                  course?.faqs.length > 0 ? (
                    <UnorderedList w="100%" py="1em" px="2em">
                      {course?.faqs.map((faq) => (
                        <ListItem>
                          <Text fontWeight="bold">Q: {faq?.question}</Text>
                          <Text fontSize="sm" mb="1em">
                            A: {faq?.answer}
                          </Text>
                          <hr />
                        </ListItem>
                      ))}
                    </UnorderedList>
                  ) : null}
                </TabPanel>
                <TabPanel>
                  <Heading size="lg">Discussions</Heading>
                  <Discussions num={3} id={id} />
                  <Button onClick={() => navigate(`/discussions/${id}`)}>
                    View all
                  </Button>
                </TabPanel>
                <TabPanel>
                  <Heading size="lg">Certificate template</Heading>
                  <CourseCertificate id={id} />
                </TabPanel>
                <TabPanel>
                  <Heading size="lg" mb="0.5em">
                    Free lessons
                  </Heading>
                  <FreeLessons id={id} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Stack>
          <Flex
            flexDir="column"
            padding="2em"
            borderRadius="2xl"
            height="fit-content"
            ml="5vw"
            minW="30vw"
            h="53vh"
            justify="space-evenly"
            display={["none", "none", "flex"]}
            boxShadow="lg"
          >
            <Text fontSize="3xl" fontWeight="bold">
              ₹ {course?.price}
            </Text>
            <Flex align="center" width={"12%"} justify="space-between">
              {course?.rating}
              <FaStar color="green" />({course?.ratingNum})
            </Flex>
            <Text>
              <b>Duration: </b>
              {formatDays()} at {course?.hrs} hr/day
            </Text>
            <Flex align="center">
              <Text mr="0.5em" fontWeight="bold">
                Flexible schedule
              </Text>{" "}
              <FaCheck />
            </Flex>
            {course?.ai ? (
              <Flex align="center">
                <Text mr="0.5em" fontWeight="bold">
                  AI Assisted learning
                </Text>{" "}
                <FaCheck />
              </Flex>
            ) : null}
            <Text>
              <b>Skills:</b> {course?.skills ? course?.skills : "N/A"}
            </Text>
            {course?.status != "active" ? (
              <Text
                fontWeight="extrabold"
                display="flex"
                alignItems="center"
                color="red"
              >
                <MdErrorOutline color="red" size={22} /> Sorry the course is not
                active{" "}
              </Text>
            ) : null}
          </Flex>
        </>
      ) : (
        <h1>404: No course selected</h1>
      )}
    </Flex>
  );
}

export default CourseView;
