import {
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate } from "react-router-dom";

function ForgotPassword() {
  const toast = useToast();
  const [email, setEmail] = useState("");
  const recaptcha = useRef();

  const navigate = useNavigate();

  const auth = getAuth();

  const [isLoading, setIsLoading] = useState(false);

  // Sign in user
  const reset = (e) => {
    e.preventDefault();
    if (!recaptcha.current.getValue()) {
      toast({
        title: "Error",
        description: "Please complete the reCAPTCHA",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setIsLoading(true);
    sendPasswordResetEmail(auth, email)
      .then(() => {
        toast({
          title: "Success",
          description: "Password reset email sent",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      });
  };
  return (
    <Center minH="78vh">
      <Flex
        flexDir="column"
        border="1px"
        paddingX="1em"
        paddingY="2em"
        borderRadius="1em"
        boxShadow="lg"
        minW={"30vw"}
      >
        <Text fontSize="4xl" textAlign="center" fontWeight="bold">
          CourseHub
        </Text>
        <Text textAlign="center">Welcome back to CourseHub</Text>
        <form onSubmit={reset}>
          <FormControl maxW="80%" mb="1em" mt="2em">
            <FormLabel>Email</FormLabel>
            <Input
              isRequired
              maxLength={150}
              id="email"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_SITE_KEY}
            ref={recaptcha}
            className="Form__recaptcha"
          />
          <Button isLoading={isLoading} colorScheme="green" type="submit">
            Reset
          </Button>
          <br />
          <Link to="/signup">
            <u>Dont have an account?</u>
          </Link>
        </form>
      </Flex>
    </Center>
  );
}

export default ForgotPassword;
