import React, { useState } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Text,
  useToast,
} from "@chakra-ui/react";
import { fetchC } from "../utils/customFetch";

function EditComment(props) {
  const [comment, setComment] = useState(props.comment.comment);
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const handleEdit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/comments/${props.id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        "Content-Type": "application/json",
        body: JSON.stringify({
          comment,
        }),
      }
    )
      .then((res) => {
        if (res.ok) {
          toast({
            title: "Comment edited.",
            status: "success",
            duration: 3000,
            isClosable: true,
            onCloseComplete: () => {
              window.location.reload();
              props.close();
            },
          });
          setIsLoading(false);
        } else {
          throw res;
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast({
          title: "Failed to edit comment.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <Flex flexDir="column" align="center" padding="2em">
      <Text fontSize="3xl" fontWeight="bold" mb="1em">
        Edit comment
      </Text>
      <form onSubmit={handleEdit}>
        <FormControl>
          <FormLabel>Comment</FormLabel>
          <Input
            isRequired
            type="text"
            placeholder="Comment"
            value={comment}
            id="c"
            onChange={(e) => setComment(e.target.value)}
          />
        </FormControl>
        <Button
          type="submit"
          mt="2em"
          colorScheme="green"
          isLoading={isLoading}
        >
          Submit
        </Button>
      </form>
    </Flex>
  );
}

export default EditComment;
