import React, { useEffect, useState } from "react";
import "../Forms.css";
import { fetchC } from "../utils/customFetch";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";

function EditLesson(props) {
  const toast = useToast();
  const [title, setTitle] = useState(props.obj.title);
  const [description, setDescription] = useState(props.obj.description);
  const [locked, setLocked] = useState(props.obj.locked);
  const [free, setFree] = useState(props.obj.free);
  const [isLoading, setIsLoading] = useState(false);

  const createLesson = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let obj = {};
    if (title) obj.title = title;
    if (description) obj.description = description;
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/lessons/${props.id}`,
      {
        method: "PATCH",
        body: JSON.stringify({ ...obj, locked, free }),
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            props.close();
            window.location.reload();
          },
        });
        setIsLoading(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      {props.id ? (
        <Flex flexDir="column" align="center" padding="2em">
          <Text fontSize="3xl" fontWeight="bold">
            Modify Lesson
          </Text>
          <form onSubmit={createLesson}>
            <FormControl>
              <FormLabel>Title</FormLabel>
              <Input
                isRequired
                maxLength={100}
                type="text"
                placeholder="Title"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormControl>
            <FormControl mt="2em">
              <FormLabel>Description</FormLabel>
              <Textarea
                isRequired
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Description"
                maxLength={500}
              ></Textarea>
            </FormControl>
            <FormControl mt="2em">
              <FormLabel>Locked</FormLabel>
              <Switch
                isChecked={locked}
                id="locked"
                onChange={(e) => setLocked(!locked)}
              />
            </FormControl>
            <FormControl mt="2em">
              <FormLabel>Free</FormLabel>
              <Switch
                isChecked={free}
                id="free"
                onChange={(e) => setFree(!free)}
              />
            </FormControl>
            <Button
              isLoading={isLoading}
              mt="2em"
              colorScheme="green"
              type="submit"
            >
              Submit
            </Button>
          </form>
        </Flex>
      ) : (
        <h1>Section not found</h1>
      )}
    </>
  );
}

export default EditLesson;
