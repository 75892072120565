import React from "react";
import "./Card.css";
import { Box, Image, Text } from "@chakra-ui/react";

function Card(props) {
  return (
    <Box
      px="1em"
      py="1.5em"
      w={["80vw", "80vw", "70vw", "50vw", "25vw"]}
      boxShadow="lg"
      borderRadius="lg"
      h="100%"
      mt="2em"
    >
      <Image src={props.image} w="100%" h="30vh" objectFit="cover" />
      <Text fontSize="lg" fontWeight="bold" w="50%">
        {props.title}
      </Text>
      <Text mt="1em">{props.description}</Text>
    </Box>
  );
}

export default Card;
