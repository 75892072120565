import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Forms.css";
import { fetchC } from "./utils/customFetch";
import {
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Select,
  Switch,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";

function CreateCourse() {
  const toast = useToast();
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState(0);
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState("");
  const [passing, setPassing] = useState(0);
  const [ai, setAi] = useState(false);
  const [skill, setSkill] = useState("");
  const [hrs, setHrs] = useState(0);
  const [language, setLanguage] = useState("English");

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const createCourse = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    fetchC("https://coursehub-server-de93512846f7.herokuapp.com/api/courses/", {
      method: "POST",
      body: JSON.stringify({
        title: title,
        price: price,
        description: description,
        tags: tags,
        passing: passing,
        ai: ai,
        skills: skill,
        hrs: hrs,
        language: language,
      }),
      "Content-Type": "application/json",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            if (data.msg == "Course created successfully") {
              navigate(`/course/${data.id}`);
            }
          },
        });
        setIsLoading(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsLoading(false);
      });
  };
  return (
    <Center>
      <Flex
        flexDir="column"
        align="center"
        minW={["80vw", "70vw", "60vw", "50vw", "40vw"]}
        my="5vh"
        py="2em"
        px="1em"
        border="1px"
        borderRadius="xl"
        boxShadow={"xl"}
      >
        <Text fontSize="3xl" fontWeight="bold">
          Create course
        </Text>
        <form onSubmit={createCourse}>
          <FormControl maxW="80%" mb="1em" mt="2em">
            <FormLabel>Title</FormLabel>
            <Input
              isRequired
              maxLength={200}
              placeholder="Title"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormControl>
          <FormControl maxW="80%" mb="1em">
            <FormLabel>Price</FormLabel>
            <InputGroup>
              <Input
                isRequired
                type="number"
                min={5}
                max={100000}
                id="price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
              <InputRightAddon>₹</InputRightAddon>
            </InputGroup>
          </FormControl>
          <FormControl maxW="80%" mb="1em">
            <FormLabel>Tags</FormLabel>
            <Input
              id="tags"
              placeholder="Tags (Saparated by ', ')"
              value={tags}
              onChange={(e) => setTags(e.target.value)}
            />
          </FormControl>
          <FormControl maxW="80%" mb="1em">
            <FormLabel>Skills</FormLabel>
            <Input
              id="skills"
              placeholder="Skills (Saparate by ',')"
              value={skill}
              onChange={(e) => setSkill(e.target.value)}
            />
          </FormControl>
          <FormControl maxW="80%" mb="1em">
            <FormLabel>Passing percentage</FormLabel>
            <InputGroup>
              <Input
                isRequired
                min={0}
                max={100}
                id="passing"
                type="number"
                value={passing}
                onChange={(e) => setPassing(e.target.value)}
              />
              <InputRightAddon>%</InputRightAddon>
            </InputGroup>
          </FormControl>
          <FormControl maxW="80%" mb="1em">
            <FormLabel>Description</FormLabel>
            <Textarea
              isRequired
              maxLength={500}
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Description"
            />
          </FormControl>
          <FormControl maxW="80%" mb="1em">
            <FormLabel>Estimated Hrs/day</FormLabel>
            <InputGroup>
              <Input
                isRequired
                min={0}
                max={24}
                id="hrs"
                type="number"
                value={hrs}
                onChange={(e) => setHrs(e.target.value)}
              />
              <InputRightAddon>hr</InputRightAddon>
            </InputGroup>
          </FormControl>
          <FormControl maxW="80%" mb="1em">
            <FormLabel>Opt in to AI features ?</FormLabel>
            <Switch isChecked={ai ? ai : false} onChange={(e) => setAi(!ai)} />
          </FormControl>
          <FormControl maxW="80%" mb="1em">
            <FormLabel>Language</FormLabel>
            <Select
              id="lang"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
            >
              <option value="English">English</option>
              <option value="Hindi">Hindi</option>
              <option value="Gujarati">Gujarati</option>
              <option value="Telugu">Telugu</option>
              <option value="Tamil">Tamil</option>
              <option value="Kannada">Kannada</option>
              <option value="Malayalam">Malayalam</option>
              <option value="Bengali">Bengali</option>
              <option value="Punjabi">Punjabi</option>
              <option value="Urdu">Urdu</option>
              <option value="Spanish">Spanish</option>
              <option value="French">French</option>
              <option value="German">German</option>
              <option value="Italian">Italian</option>
              <option value="Russian">Russian</option>
              <option value="Chinese">Chinese</option>
              <option value="Mandrin">Mandrin</option>
            </Select>
          </FormControl>
          <Button isLoading={isLoading} colorScheme="green" type="submit">
            Submit
          </Button>
        </form>
      </Flex>
    </Center>
  );
}

export default CreateCourse;
