import React, { useEffect, useState } from "react";
import "./CourseContent.css";
import { SiContentful } from "react-icons/si";
import Popup from "reactjs-popup";
import EditLesson from "./EditLesson";
import { MdDeleteOutline } from "react-icons/md";
import { Draggable } from "react-drag-reorder";
import EditAssignment from "./EditAssignment";
import { Link } from "react-router-dom";
import AssignmentRuberic from "../AssignmentRuberic";
import { fetchC } from "../utils/customFetch";
import {
  Badge,
  Button,
  Flex,
  IconButton,
  Text,
  useToast,
} from "@chakra-ui/react";

function arraysEqual(arr1, arr2) {
  // js function to check 2 list of object are equal
  if (arr1.length !== arr2.length) return false;

  for (let i = 0; i < arr1.length; i++) {
    const obj1 = arr1[i];
    const obj2 = arr2[i];

    if (Object.keys(obj1).length !== Object.keys(obj2).length) return false;

    for (let key in obj1) {
      if (obj1[key] !== obj2[key]) return false;
    }
  }

  return true;
}

function LessonsView(props) {
  const toast = useToast();
  const [isDeletingLesson, setIsDeletingLesson] = useState(false);
  const [isDeletingAssignment, setIsDeletingAssignment] = useState(false);
  const [isReordering, setIsReordering] = useState(false);
  const deleteLesson = async (id) => {
    let answer = window.confirm("Are you sure you want to delete this lesson?");
    if (!answer) return;
    setIsDeletingLesson(true);
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/lessons/${id}`,
      {
        method: "DELETE",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => window.location.reload(),
        });
        setIsDeletingLesson(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsDeletingLesson(false);
      });
  };
  const deleteAssignment = async (id) => {
    let answer = window.confirm(
      "Are you sure you want to delete this assignment?"
    );
    if (!answer) return;
    setIsDeletingAssignment(true);
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/assignments/${id}`,
      {
        method: "DELETE",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => window.location.reload(),
        });
        setIsDeletingAssignment(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsDeletingAssignment(false);
      });
  };

  const [lessonIds, setLessonIds] = useState([]);
  const [reordered, setReordered] = useState(false);

  useEffect(() => {
    let lessonIdsTmp = props.lessons.map((lesson) => {
      return {
        type: "Lesson",
        id: lesson.id,
        lessonNumber: lesson.lessonNumber,
      };
    });
    let assignmentIdsTmp = props.assignments.map((assignment) => {
      return {
        type: "Assignment",
        id: assignment.id,
        lessonNumber: assignment.lessonNumber,
      };
    });
    let combined = lessonIdsTmp.concat(assignmentIdsTmp);
    combined.sort((a, b) => a.lessonNumber - b.lessonNumber);
    setLessonIds(combined);
  }, [props.lessons, props.assignments]);

  const getChangedPos = (currentPos, newPos) => {
    const newLessonIds = [...lessonIds];
    const movedLesson = newLessonIds.splice(currentPos, 1)[0];
    newLessonIds.splice(newPos, 0, movedLesson);
    setLessonIds(newLessonIds);
  };

  useEffect(() => {
    if (lessonIds.length === 0) return;
    let lessonIdsTmp = props.lessons.map((lesson) => {
      return {
        type: "Lesson",
        id: lesson.id,
        lessonNumber: lesson.lessonNumber,
      };
    });
    let assignmentIdsTmp = props.assignments.map((assignment) => {
      return {
        type: "Assignment",
        id: assignment.id,
        lessonNumber: assignment.lessonNumber,
      };
    });
    let combined = lessonIdsTmp.concat(assignmentIdsTmp);
    combined.sort((a, b) => a.lessonNumber - b.lessonNumber);
    console.log(combined, lessonIds);
    if (!arraysEqual(combined, lessonIds)) {
      setReordered(true);
    }
  }, [lessonIds]);

  const reorder = async () => {
    if (!reordered) {
      return;
    }
    let lessonIdsTmp = props.lessons.map((lesson) => {
      return {
        type: "Lesson",
        id: lesson.id,
        lessonNumber: lesson.lessonNumber,
      };
    });
    let assignmentIdsTmp = props.assignments.map((assignment) => {
      return {
        type: "Assignment",
        id: assignment.id,
        lessonNumber: assignment.lessonNumber,
      };
    });
    let combined = lessonIdsTmp.concat(assignmentIdsTmp);
    combined.sort((a, b) => a.lessonNumber - b.lessonNumber);
    if (!arraysEqual(combined, lessonIds)) {
      setIsReordering(true);
      fetchC(
        `https://coursehub-server-de93512846f7.herokuapp.com/api/lessons/order/${props.sectionId}`,
        {
          method: "PATCH",
          "Content-Type": "application/json",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ objects: lessonIds }),
        }
      )
        .then((res) => {
          if (!res.ok) {
            throw res;
          }
          return res.json();
        })
        .then((data) => {
          toast({
            title: "Success",
            description: data.msg,
            status: "success",
            duration: 3000,
            isClosable: true,
            onCloseComplete: () => window.location.reload(),
          });
          setIsReordering(false);
        })
        .catch((res) => {
          res.json().then((data) => {
            toast({
              title: "Error",
              description: data.msg,
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          });
          setIsReordering(false);
        });
    }
  };
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Text fontSize="lg" fontWeight="bold">
          Lessons and Assignments:
        </Text>
        {reordered ? (
          <Button
            size="sm"
            ml="1em"
            onClick={() => reorder()}
            isLoading={isReordering}
          >
            Save order
          </Button>
        ) : null}
      </div>
      <ol style={{ marginLeft: "2em" }}>
        <Draggable onPosChange={getChangedPos}>
          {[]
            .concat(props.lessons.map((lesson) => ({ ...lesson, ttype: "l" })))
            .concat(
              props.assignments.map((assignment) => ({
                ...assignment,
                ttype: "a",
              }))
            )
            ?.sort((a, b) => a.lessonNumber - b.lessonNumber)
            .map((object) => (
              <>
                {object.ttype == "l" ? (
                  <li key={object.id + " " + object.lessonNumber}>
                    <Flex alignItems="center">
                      <Popup
                        trigger={<Text fontWeight="bold">{object.title}</Text>}
                        modal
                        nested
                      >
                        {(close) => (
                          <div className="modal">
                            <button className="close" onClick={close}>
                              &times;
                            </button>
                            <EditLesson
                              close={close}
                              id={object.id}
                              obj={object}
                            />
                          </div>
                        )}
                      </Popup>
                      <Link to={`/lesson-cont/${object.id}`}>
                        <IconButton size="xl" ml="0.5em">
                          <SiContentful size={14} />
                        </IconButton>
                      </Link>
                      <IconButton
                        size="xl"
                        onClick={() => deleteLesson(object.id)}
                        ml="0.5em"
                        isDisabled={isDeletingLesson}
                      >
                        <MdDeleteOutline size={18} />
                      </IconButton>
                    </Flex>
                    <Badge size="sm" colorScheme="blue">
                      Lesson
                    </Badge>
                    <details>
                      <Text>{object.description}</Text>
                      <Text>
                        <u>Time Estimate:</u>
                        {object.timeEstimate} min
                      </Text>
                      <Flex mt="1em" ml="-0.5em">
                        {object.locked ? (
                          <Badge ml="0.5em" size="sm" colorScheme="blue">
                            Locked
                          </Badge>
                        ) : null}
                        {object.free ? (
                          <Badge ml="0.5em" size="sm" colorScheme="green">
                            Free
                          </Badge>
                        ) : null}
                      </Flex>
                    </details>
                  </li>
                ) : (
                  <li key={object.id + " " + object.lessonNumber}>
                    <div className="CourseContent__sections">
                      <Popup
                        trigger={<Text fontWeight="bold">{object.title}</Text>}
                        modal
                        nested
                      >
                        {(close) => (
                          <div className="modal">
                            <button className="close" onClick={close}>
                              &times;
                            </button>
                            <EditAssignment
                              close={close}
                              id={object.id}
                              obj={object}
                            />
                          </div>
                        )}
                      </Popup>
                      <Link
                        to={`/assignment-cont/${object.id}`}
                        className="CourseContent__buttons"
                      >
                        <SiContentful size={14} />
                      </Link>
                      <Button
                        size="xl"
                        ml="0.5em"
                        isDisabled={isDeletingAssignment}
                        onClick={() => deleteAssignment(object.id)}
                      >
                        <MdDeleteOutline size={18} />
                      </Button>
                    </div>
                    <Badge size="sm" colorScheme="yellow">
                      Assignment
                    </Badge>
                    <details>
                      <p>{object.description}</p>
                      <div className="CourseContent__span_box">
                        <span>Time Estimate:</span>
                        <p>{object.timeEstimate} min</p>
                      </div>
                      <div className="CourseContent__span_box">
                        <span>Assignment type:</span>
                        <p>{object.type} reviewed</p>
                      </div>
                      <div className="CourseContent__span_box">
                        <span>Max points:</span>
                        <p>{object.maxPoints} pt</p>
                      </div>
                      <div className="CourseContent__span_box">
                        <span>Passing:</span>
                        <p>{object.passing} pt</p>
                      </div>
                      {object.locked ? (
                        <Badge size="sm" colorScheme="blue">
                          Locked{" "}
                        </Badge>
                      ) : null}
                    </details>
                  </li>
                )}
              </>
            ))}
        </Draggable>
      </ol>
    </>
  );
}

export default LessonsView;
