import React, { useEffect, useState } from "react";
import "../Forms.css";
import { fetchC } from "../utils/customFetch";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Select,
  Switch,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";

function EditAssignment(props) {
  const [title, setTitle] = useState(props.obj.title);
  const [description, setDescription] = useState(props.obj.description);
  const [locked, setLocked] = useState(
    props.obj.locked || props.obj.locked == false ? props.obj.locked : false
  );
  const [passing, setPassing] = useState(props.obj.passing);
  const [time, setTime] = useState(props.obj.timeEstimate);
  const [type, setType] = useState(props.obj.type);
  const [points, setPoints] = useState(props.obj.maxPoints);

  const [isUpdating, setIsUpdating] = useState(false);

  const toast = useToast();

  const createAssignment = async (e) => {
    e.preventDefault();
    setIsUpdating(true);
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/assignments/${props.id}`,
      {
        method: "PATCH",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: title,
          description: description,
          locked: locked,
          passing: passing,
          timeEstimate: time,
          type: type,
          max: points,
        }),
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => window.location.reload(),
        });
        setIsUpdating(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsUpdating(false);
      });
  };

  return (
    <>
      {props.id ? (
        <Flex
          flexDir="column"
          align="center"
          padding="2em"
          overflowY="scroll"
          maxH="75vh"
        >
          <Text fontSize="3xl" fontWeight="bold">
            Edit Assignment
          </Text>
          <form onSubmit={createAssignment}>
            <FormControl>
              <FormLabel>Title</FormLabel>
              <Input
                isRequired
                maxLength={100}
                type="text"
                placeholder="Title"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormControl>
            <FormControl mt="2em">
              <FormLabel>Description</FormLabel>
              <Textarea
                isRequired
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Description"
                maxLength={500}
              ></Textarea>
            </FormControl>
            <FormControl mt="2em">
              <FormLabel>Locked</FormLabel>
              <Switch
                isChecked={locked}
                id="locked"
                onChange={(e) => setLocked(!locked)}
              />
            </FormControl>
            <FormControl mt="2em">
              <FormLabel>Passing</FormLabel>
              <InputGroup>
                <Input
                  isRequired
                  type="number"
                  id="passing"
                  min={0}
                  max={100}
                  placeholder="Passing grade"
                  value={passing}
                  onChange={(e) => setPassing(e.target.value)}
                />
                <InputRightAddon>%</InputRightAddon>
              </InputGroup>
            </FormControl>
            <FormControl mt="2em">
              <FormLabel>Points</FormLabel>
              <InputGroup>
                <Input
                  isRequired
                  type="number"
                  id="points"
                  min={1}
                  placeholder="Max points"
                  value={points}
                  onChange={(e) => setPoints(e.target.value)}
                />
                <InputRightAddon>pt</InputRightAddon>
              </InputGroup>
            </FormControl>
            <FormControl mt="2em">
              <FormLabel>Time estimate</FormLabel>
              <InputGroup>
                <Input
                  isRequired
                  type="number"
                  id="time"
                  min={0}
                  placeholder="Time estimate"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                />
                <InputRightAddon>min</InputRightAddon>
              </InputGroup>
            </FormControl>
            <FormControl mt="2em">
              <FormLabel>Type</FormLabel>
              <Select
                value={type}
                onChange={(e) => {
                  setType(e.target.value);
                }}
              >
                <option value="peer">Peer graded</option>
                <option value="instructor">Instructor graded</option>
              </Select>
            </FormControl>
            <Button
              isLoading={isUpdating}
              colorScheme="green"
              mt="2em"
              type="submit"
            >
              Submit
            </Button>
          </form>
        </Flex>
      ) : (
        <h1>Section not found</h1>
      )}
    </>
  );
}

export default EditAssignment;
