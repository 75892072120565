import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { fetchC } from "../utils/customFetch";

function CreateDiscussion(props) {
  const [title, setTitle] = useState("");
  const [topic, setTopic] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const handleCreate = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/discussions/`,
      {
        method: "POST",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          course: props.id,
          title: title,
          topic: topic,
        }),
      }
    )
      .then(async (res) => {
        if (!res.ok) {
          return res.json().then((data) => {
            throw new Error(data.msg);
          });
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            props.close();
            window.location.reload();
          },
        });
        setIsLoading(false);
      })
      .catch((error) => {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      });
  };

  return (
    <Flex flexDir="column" align="center" padding="2em">
      <Text fontSize="3xl" fontWeight="bold" mb="1em">
        Create discussion
      </Text>
      <form onSubmit={handleCreate}>
        <FormControl>
          <FormLabel>Title</FormLabel>
          <Input
            isRequired
            type="text"
            placeholder="Title"
            value={title}
            id="t"
            onChange={(e) => setTitle(e.target.value)}
          />
        </FormControl>
        <FormControl mt="2em">
          <FormLabel>Topic</FormLabel>
          <Textarea
            isRequired
            id="to"
            value={topic}
            placeholder="Topic"
            onChange={(e) => setTopic(e.target.value)}
          />
        </FormControl>
        <Button
          type="submit"
          mt="2em"
          colorScheme="green"
          isLoading={isLoading}
        >
          Submit
        </Button>
      </form>
    </Flex>
  );
}

export default CreateDiscussion;
