import React, { useEffect, useState } from "react";
import { fetchC } from "../utils/customFetch";
import cert from "./cert.png";
import { Image, Spinner, Text } from "@chakra-ui/react";

function CourseCertificate(props) {
  const [certificate, setCertificate] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch the certificate from the server
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/courses/certificate/${props.id}`
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setCertificate(data.msg);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  return (
    <>
      {!loading ? (
        <>
          {certificate && certificate == "default" ? (
            <Text mb="1em">(Default)</Text>
          ) : null}
          <Image
            w="80%"
            border="1px"
            borderRadius="lg"
            src={certificate && certificate != "default" ? certificate : cert}
          />
        </>
      ) : (
        <Spinner size="lg" />
      )}
    </>
  );
}

export default CourseCertificate;
