import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  Progress,
  Spinner,
  Stack,
  Tag,
  Text,
  useToast,
} from "@chakra-ui/react";
import { fetchC } from "./utils/customFetch";
import { Link, useParams } from "react-router-dom";
import { CiCircleCheck } from "react-icons/ci";

function CourseDo() {
  const [sections, setSections] = useState(null);
  const [title, setTitle] = useState("");
  const [progress, setProgress] = useState(0);

  const { id } = useParams();

  useEffect(() => {
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/courses/${id}/sections`,
      {
        method: "GET",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error();
        } else {
          return res.json();
        }
      })
      .then((data) => {
        setSections(data.msg);
        setTitle(data.title);
      })
      .catch((err) => {
        console.error(err);
      });
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/courses/${id}/progress`,
      {
        method: "GET",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error();
        } else {
          return res.json();
        }
      })
      .then((data) => {
        setProgress(data.msg);
        console.log(data.msg);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const toast = useToast();

  const completeSection = (sectionId) => {
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/sections/complete`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        "Content-Type": "application/json",
        body: JSON.stringify({ id: sectionId }),
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Section completed",
          description: data.msg,
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        });
      });
  };

  const completeCourse = () => {
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/courses/complete`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        "Content-Type": "application/json",
        body: JSON.stringify({ id: id }),
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Course completed",
          description: data.msg,
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        });
      });
  };

  return (
    <Center minH="78vh">
      <Stack minH="70vh" w="80vw" p="2em" border="1px" borderRadius="lg">
        <Box mb="1em">
          <Breadcrumb>
            <BreadcrumbItem>
              <BreadcrumbLink href={`/course/${id}`}>Course</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href={`#`}>Lessons</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Heading>Course content: {title}</Heading>
          <Stack>
            <Text fontSize="lg" fontWeight="bold">
              Progress
            </Text>
            {progress ? (
              <Flex alignItems="center">
                <Progress
                  borderRadius="md"
                  minW="10vw"
                  value={progress?.percent}
                />
                <Text ml="1em">
                  | {progress?.completed} lessons out of {progress?.total}
                </Text>
              </Flex>
            ) : null}
          </Stack>
        </Box>
        <Accordion>
          {sections ? (
            sections
              ?.sort((a, b) => a.sectionNumber - b.sectionNumber)
              ?.map((s) => {
                return (
                  <AccordionItem key={s.id}>
                    <h2>
                      <AccordionButton>
                        <Box as="span" flex="1" textAlign="left">
                          <Heading fontSize="2xl">{s.title}</Heading>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Text fontSize="xs">{s.description}</Text>
                      <Button
                        w="fit-content"
                        colorScheme="green"
                        size="sm"
                        onClick={() => completeSection(s.id)}
                      >
                        Mark section completed
                      </Button>
                      <Text fontWeight="bold">Lessons & Assignments</Text>
                      {s ? (
                        <Stack px="1em" mt="1em">
                          {[]
                            .concat(
                              s.lessons.map((lesson) => ({
                                ...lesson,
                                ttype: "l",
                              }))
                            )
                            ?.concat(
                              s.assignments.map((assignment) => ({
                                ...assignment,
                                ttype: "a",
                              }))
                            )
                            ?.sort((a, b) => a.lessonNumber - b.lessonNumber)
                            .map((object) => {
                              return (
                                <Link
                                  to={`/${
                                    object.ttype == "a"
                                      ? "assignment-do"
                                      : "lesson-do"
                                  }/${object.id}`}
                                >
                                  <Flex>
                                    <Text>{object.title}</Text>
                                    <Tag
                                      ml="1em"
                                      colorScheme={
                                        object.ttype == "a" ? "blue" : "purple"
                                      }
                                      size="sm"
                                    >
                                      {object.ttype == "a"
                                        ? "Assignment"
                                        : "Lesson"}
                                    </Tag>
                                  </Flex>
                                  <Text fontSize="xs">
                                    {object.description}
                                  </Text>
                                  {object.ttype == "a" &&
                                  progress.assignments?.some(
                                    (a) => a == object.id
                                  ) ? (
                                    <Tag colorScheme="green">
                                      <Text mr="0.5em">Completed</Text>{" "}
                                      <CiCircleCheck color="green" />
                                    </Tag>
                                  ) : null}
                                  {object.ttype == "l" &&
                                  progress.lessons?.some(
                                    (a) => a == object.id
                                  ) ? (
                                    <Tag colorScheme="green">
                                      <Text mr="0.5em">Completed</Text>{" "}
                                      <CiCircleCheck color="green" />
                                    </Tag>
                                  ) : null}
                                  <Text fontSize="xs">
                                    <u>Time estimate:</u> {object.timeEstimate}{" "}
                                    min
                                  </Text>
                                  <Divider mt="1em" />
                                </Link>
                              );
                            })}
                        </Stack>
                      ) : null}
                    </AccordionPanel>
                  </AccordionItem>
                );
              })
          ) : (
            <Spinner />
          )}
        </Accordion>
        <Button
          onClick={() => completeCourse()}
          w="fit-content"
          colorScheme="green"
        >
          Mark course completed
        </Button>
      </Stack>
    </Center>
  );
}

export default CourseDo;
