import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { fetchC } from "../utils/customFetch";
import Pagination from "./Pagination";
import UserShort from "./UserShort";
import { getAuth } from "firebase/auth";
import Popup from "reactjs-popup";
import EditComment from "./EditComment";
import CreateCommentReply from "./CreateCommentReply";

function LessonComments(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [isDeleting, setIsDeleting] = useState(false);

  const toast = useToast();

  const auth = getAuth();

  const deleteComment = (id) => {
    let confirm = window.confirm(
      "Are you sure you want to delete this comment?"
    );
    if (!confirm) {
      return;
    }

    setIsDeleting(true);

    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/comments/${id}`,
      {
        method: "DELETE",
      }
    )
      .then((res) => {
        if (res.ok) {
          toast({
            title: "Comment deleted.",
            status: "success",
            duration: 3000,
            isClosable: true,
            onCloseComplete: () => {
              window.location.reload();
            },
          });
          setIsDeleting(false);
        } else {
          throw res;
        }
      })
      .catch((err) => {
        toast({
          title: "Error deleting comment.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setIsDeleting(false);
      });
  };

  useEffect(() => {
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/comments/${props.id}?page=${page}`
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        } else {
          return res.json();
        }
      })
      .then((data) => {
        setComments(data.msg);
        setTotalPages(data.total);
        console.log(data.msg);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, [page]);

  return (
    <Stack mt="1em" w="60%" p={"1em"} border="1px" borderRadius="lg">
      {isLoading ? (
        <Spinner />
      ) : (
        <Stack>
          {comments.map((comment) => {
            return (
              <Box>
                <UserShort
                  id={comment?.userObj.id}
                  username={comment?.userObj.username}
                  img={comment?.userObj.avatar}
                  color="black"
                  rev={true}
                />
                <Text>{comment.comment}</Text>
                <Text fontSize="xs">Created: {comment.created}</Text>
                {comment.created != comment.lastModified ? (
                  <Text fontSize="xs">Updated: {comment.lastModified}</Text>
                ) : null}
                <Flex mt="1em">
                  <Popup
                    trigger={
                      <Button colorScheme="teal" size="xs">
                        Reply
                      </Button>
                    }
                    modal
                    nested
                  >
                    {(close) => (
                      <div className="modal">
                        <button className="close" onClick={close}>
                          &times;
                        </button>
                        <CreateCommentReply
                          close={close}
                          comment={comment?.id}
                          lesson={props.id}
                          commentText={comment?.comment}
                        />
                      </div>
                    )}
                  </Popup>
                  {auth.currentUser &&
                  auth.currentUser.uid == comment?.userObj.id ? (
                    <>
                      <Button
                        onClick={() => deleteComment(comment?.id)}
                        colorScheme="red"
                        size="xs"
                        ml="1em"
                      >
                        Delete
                      </Button>
                      <Popup
                        trigger={
                          <Button size="xs" ml="1em">
                            Edit
                          </Button>
                        }
                        modal
                        nested
                      >
                        {(close) => (
                          <div className="modal">
                            <button className="close" onClick={close}>
                              &times;
                            </button>
                            <EditComment close={close} comment={comment} />
                          </div>
                        )}
                      </Popup>
                    </>
                  ) : null}
                </Flex>
                <Stack p="0.5em" m="0.5em">
                  <Heading
                    fontSize="xs"
                    display={comment.replies.length == 0 ? "none" : "block"}
                  >
                    Replies
                  </Heading>
                  {comment?.replies.map((comment) => {
                    return (
                      <Box p="1em">
                        <UserShort
                          id={comment?.userObj.id}
                          username={comment?.userObj.username}
                          img={comment?.userObj.avatar}
                          color="black"
                          rev={true}
                        />
                        <Text>{comment.comment}</Text>
                        <Text fontSize="xs">Created: {comment.created}</Text>
                        {comment.created != comment.lastModified ? (
                          <Text fontSize="xs">
                            Updated: {comment.lastModified}
                          </Text>
                        ) : null}
                        <Flex mt="1em">
                          {auth.currentUser &&
                          auth.currentUser.uid == comment?.userObj.id ? (
                            <>
                              <Button
                                onClick={() => deleteComment(comment?.id)}
                                colorScheme="red"
                                size="xs"
                              >
                                Delete
                              </Button>
                              <Popup
                                trigger={
                                  <Button size="xs" ml="1em">
                                    Edit
                                  </Button>
                                }
                                modal
                                nested
                              >
                                {(close) => (
                                  <div className="modal">
                                    <button className="close" onClick={close}>
                                      &times;
                                    </button>
                                    <EditComment
                                      close={close}
                                      comment={comment}
                                    />
                                  </div>
                                )}
                              </Popup>
                            </>
                          ) : null}
                        </Flex>
                        <Divider mt="1em" />
                      </Box>
                    );
                  })}
                </Stack>
                <Divider mt="1em" />
              </Box>
            );
          })}
          <Pagination setPage={setPage} page={page} pages={totalPages} />
        </Stack>
      )}
    </Stack>
  );
}

export default LessonComments;
