import React, { useState } from "react";
import "../Forms.css";
import thumbnail from "../thumbnail.png";
import { fetchC } from "../utils/customFetch";
import { Button, Flex, Image, Text, useToast } from "@chakra-ui/react";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";

function UploadThumbnail(props) {
  const toast = useToast();
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(props.thumbnail);
  const [isLoading, setIsLoading] = useState(false);
  const storage = getStorage();

  const submit = async () => {
    if (!file) {
      toast({
        title: "Alert",
        description: "Please select a file!",
        status: "info",
        duration: 3000,
        isClosable: true,
        onCloseComplete: () => {
          window.location.reload();
        },
      });
      return;
    }
    // check file extension
    const extension = file.name.split(".").pop();
    if (!["png", "jpg", "jpeg"].includes(extension)) {
      toast({
        title: "Alert",
        description: "File must be an image!",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);

    const storageRef = ref(
      storage,
      "thumbnails/" + props.id + "." + file.name.split(".").pop()
    );

    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      },
      () => {
        getDownloadURL(storageRef)
          .then((url) => {
            fetchC(
              `https://coursehub-server-de93512846f7.herokuapp.com/api/thumbnails/${props.id}`,
              {
                "Content-Type": "application/json",
                headers: {
                  "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify({
                  url: url,
                }),
              }
            )
              .then((res) => {
                if (!res.ok) {
                  throw res;
                }
                return res.json();
              })
              .then((data) => {
                toast({
                  title: "Success",
                  description: data.msg,
                  status: "success",
                  duration: 3000,
                  isClosable: true,
                  onCloseComplete: () => {
                    props.close();
                    window.location.reload();
                  },
                });
                setIsLoading(false);
              })
              .catch((res) => {
                res.json().then((data) => {
                  toast({
                    title: "Error",
                    description: data.msg,
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                  });
                });
                setIsLoading(false);
              });
          })
          .catch((error) => {
            console.error(error);
          });
      }
    );
  };

  return (
    <>
      {props.id ? (
        <Flex flexDir="column" align="center" padding="2em">
          <Text fontSize="3xl" fontWeight="bold">
            Upload thumbnail
          </Text>
          <Text>Please upload a file 530 &times; 360px for best results</Text>
          <Flex flexDir="column" align="center" my="1em">
            <Image
              borderRadius="md"
              width="20vw"
              src={previewUrl != "default" ? previewUrl : thumbnail}
              alt=""
            />
            <input
              type="file"
              size="60"
              onChange={(e) => {
                setFile(e.target.files[0]);
                setPreviewUrl(URL.createObjectURL(e.target.files[0]));
              }}
              multiple={false}
            />
            <Button
              isLoading={isLoading}
              colorScheme="green"
              mt="2em"
              onClick={() => submit()}
            >
              Submit
            </Button>
          </Flex>
        </Flex>
      ) : null}
    </>
  );
}

export default UploadThumbnail;
