import React from "react";
import { Link } from "react-router-dom";
import avatar from "../avatar.png";
import "./UserShort.css";

function UserShort(props) {
  return (
    <div className="UserShort">
      {props.rev ? (
        <>
          {props.img != "default" ? (
            <img className="UserShort__avatar" src={props.img} alt="" />
          ) : (
            <img className="UserShort__avatar" src={avatar} />
          )}
        </>
      ) : null}
      <Link
        style={{ color: props.color, margin: "0.5em" }}
        to={`/user/${props.id}`}
      >
        {props.username}
      </Link>
      {!props.rev ? (
        <>
          {props.img != "default" ? (
            <img className="UserShort__avatar" src={props.img} alt="" />
          ) : (
            <img className="UserShort__avatar" src={avatar} />
          )}
        </>
      ) : null}
    </div>
  );
}

export default UserShort;
