import { Center, Flex, Stack, Text } from "@chakra-ui/react";
import React from "react";

function AboutUs() {
  return (
    <Stack padding="2em" lineHeight="2.5" minHeight="78vh">
      <Text fontSize="2xl" fontWeight="bold">
        About Us
      </Text>
      <p>
        Welcome to our website! We are a team of students who are passionate
        about providing high-quality tools for our users and providing a
        platform for educators to create communities for students interested in
        learning interactively. Our mission is to deliver innovative solutions
        that meet the needs of our customers and exceed their expectations.
        <br />
        <br />
        This Website aims to provide a platform for students to learn and
        practice coding and other skills in a fun and interactive way. We
        provide a variety of tools such as interactive flashcards, quizzes, and
        assignments to help students learn the basics and improve their
        problem-solving skills. Our goal is to make learning accessible to
        everyone, regardless of their background or experience level. We believe
        that traditional educational institutions continuously fail to provide
        the necessary tools and resources for students to succeed in the modern
        world. We aim to bridge this gap by providing a platform that is both
        engaging and educational.
      </p>
    </Stack>
  );
}
export default AboutUs;
