import React, { useState } from "react";
import { fetchC } from "../utils/customFetch";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";

function EditOption(props) {
  const [option, setOption] = useState(props.option);
  const [correct, setCorrect] = useState(props.correct);
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const handleEdit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/options/${props.id}`,
      {
        method: "PATCH",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          option: option,
          correct: correct,
        }),
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            props.close();
            window.location.reload();
          },
        });
        setIsLoading(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsLoading(false);
      });
  };

  return (
    <Flex flexDir="column" align="center" padding="2em">
      <Text fontSize="3xl" fontWeight="bold" mb="1em">
        Edit option
      </Text>
      <form onSubmit={handleEdit}>
        <FormControl>
          <FormLabel>Option</FormLabel>
          <Input
            isRequired
            type="text"
            placeholder="Question"
            value={option}
            id="q"
            onChange={(e) => setOption(e.target.value)}
          />
        </FormControl>
        <FormControl mt="2em">
          <FormLabel>Correct</FormLabel>
          <Switch
            isChecked={correct}
            onChange={(e) => setCorrect(!correct)}
            id="correct"
          />
        </FormControl>
        <Button
          type="submit"
          mt="2em"
          colorScheme="green"
          isLoading={isLoading}
        >
          Submit
        </Button>
      </form>
    </Flex>
  );
}

export default EditOption;
