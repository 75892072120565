import React, { useState } from "react";
import "./CourseCard.css";
import thb from "../thumbnail.png";
import { Link } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import UserShort from "./UserShort";
import { MdEdit, MdErrorOutline } from "react-icons/md";
import { AiOutlineDashboard } from "react-icons/ai";
import { SiContentful } from "react-icons/si";
import { fetchC } from "../utils/customFetch";
import {
  Badge,
  Box,
  Button,
  Flex,
  Image,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";

function CourseCard({
  thumbnail,
  price,
  title,
  id,
  bestseller,
  rating,
  ratingNumber,
  instructor,
  featured,
  promoted,
  edit,
  status,
}) {
  const toast = useToast();

  const [loading, setLoading] = useState(false);

  const activate = async () => {
    setLoading(true);
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/courses/activate/${id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => window.location.reload(),
        });
        setLoading(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setLoading(false);
      });
  };
  const deactivate = async () => {
    setLoading(true);
    let answer = window.confirm(
      "Are you sure you want to deactivate this course?"
    );

    if (!answer) {
      setLoading(false);
      return;
    }
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/courses/deactivate/${id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => window.location.reload(),
        });
        setLoading(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setLoading(false);
      });
  };

  return (
    <Flex
      flexDir="column"
      padding="1em"
      border="1px"
      borderRadius="xl"
      boxShadow="md"
      _hover={{ boxShadow: "lg" }}
      pb="2em"
    >
      <Link to={`/course/${id}`}>
        <Image
          borderRadius="xl"
          width="100%"
          src={thumbnail == "default" ? thb : thumbnail}
          alt={title}
        />
      </Link>
      <Stack px="1em">
        <Text mt="1em" fontSize="lg" display="flex" align="center">
          <b>Title: </b> {title}
        </Text>

        <Text fontSize="xl">₹ {price}</Text>
        <Flex>
          {featured ? (
            <Badge mr="1em" w="fit-content" p="0.5em" colorScheme="green">
              Featured
            </Badge>
          ) : null}
          {bestseller ? (
            <Badge mr="1em" w="fit-content" p="0.5em" colorScheme="yellow">
              Bestseller
            </Badge>
          ) : null}
          {status && status != "active" ? (
            <Badge mr="1em" w="fit-content" p="0.5em" colorScheme="red">
              Not active
            </Badge>
          ) : null}
          {promoted ? (
            <Badge w="fit-content" p="0.5em">
              Promoted
            </Badge>
          ) : null}
        </Flex>
        <Text display="flex" align="center" my="0.5em">
          {rating} <FaStar /> ({ratingNumber})
        </Text>
        <Box mb="-1em">
          <UserShort
            id={instructor?.id}
            username={instructor?.username}
            img={instructor?.avatar}
            color="black"
            rev={true}
          />
        </Box>
        {edit ? (
          <Flex my="0.5em">
            <Link to={`/edit/${id}`} className="CourseCard__edit">
              <MdEdit size={18} />
            </Link>
            <Link to={`/course-dash/${id}`} className="CourseCard__edit">
              <AiOutlineDashboard size={18} />
            </Link>
          </Flex>
        ) : null}
        {status && status != "active" && edit ? (
          <Button
            w="fit-content"
            onClick={() => activate()}
            mt="0.5em"
            mb="1em"
            isLoading={loading}
          >
            Activate
          </Button>
        ) : null}
        {edit && status && status == "active" ? (
          <Button
            w="fit-content"
            onClick={() => deactivate()}
            mt="0.5em"
            mb="1em"
            isLoading={loading}
          >
            Deactivate
          </Button>
        ) : null}
      </Stack>
    </Flex>
  );
}

export default CourseCard;
