import React from "react";
import "./Notification.css";
import { fetchC } from "../utils/customFetch";
import { Button, useToast } from "@chakra-ui/react";

function Notification(props) {
  const toast = useToast();
  const [isLoading, setIsLoading] = React.useState(false);
  const deleteNotification = async (id) => {
    setIsLoading(true);
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/notifications/notification/${id}`,
      {
        method: "PATCH",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            window.location.reload();
          },
        });
        setIsLoading(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsLoading(false);
      });
  };
  return (
    <div key={props.obj.id} className="notification">
      <div className="notification__left">
        <h3>{props.obj.title}</h3>
        <p>{props.obj.message}</p>
      </div>
      <div className="notification__right">
        <Button
          isLoading={isLoading}
          onClick={() => deleteNotification(props.obj.id)}
        >
          &times;
        </Button>
      </div>
    </div>
  );
}

export default Notification;
