import { Button, Center, Divider, Flex, Spinner } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { fetchC } from "../utils/customFetch";
import UserShort from "./UserShort";

function CourseStudents(props) {
  const [loading, setLoading] = useState(true);
  const [students, setStudents] = useState([]);

  useEffect(() => {
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/courses/students/${props.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        "Content-Type": "application/json",
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((data) => {
        setStudents(data.msg);
        console.log(data.msg);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, [props.id]);

  return (
    <Center minH="30vh">
      <Flex
        flexDir="column"
        align="center"
        border="1px"
        px="1em"
        py="2em"
        minW="30vw"
        borderRadius="lg"
        boxShadow="lg"
      >
        {loading ? (
          <Spinner size="xl" />
        ) : Array.isArray(students) && students.length > 0 ? (
          <>
            {students.map((student) => (
              <>
                <Flex
                  p="0.5em"
                  m="1em"
                  minW="60%"
                  align="center"
                  justify="center"
                >
                  <Flex flexDir="column" al>
                    <UserShort
                      color="black"
                      key={student.id}
                      username={student.username}
                      id={student.id}
                      img={student.avatar}
                    />
                    <Button
                      ml="0.5em"
                      colorScheme="teal"
                      size="sm"
                      w="fit-content"
                    >
                      Details
                    </Button>
                  </Flex>
                </Flex>
                <Divider />
              </>
            ))}
          </>
        ) : (
          <Flex
            p="0.5em"
            m="1em"
            border="1px"
            minW="60%"
            alignItems="center"
            justify="center"
            borderRadius="lg"
          >
            No students...
          </Flex>
        )}
      </Flex>
    </Center>
  );
}

export default CourseStudents;
