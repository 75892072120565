import React, { useState } from "react";
import "../Forms.css";
import { fetchC } from "../utils/customFetch";
import { Button, Flex, Text, useToast } from "@chakra-ui/react";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { uuid } from "unique-string-generator";
import { duration } from "@mui/material";

function UploadVideo(props) {
  const toast = useToast();
  const storage = getStorage();
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const submit = async () => {
    if (!file) {
      toast({
        title: "Alert",
        description: "Please select a file!",
        status: "info",
        duration: 3000,
        isClosable: true,
        onCloseComplete: () => {
          window.location.reload();
        },
      });
      return;
    }
    setIsLoading(true);

    // get video length and filename

    const reader = new FileReader();
    reader.onload = function (event) {
      const video = document.createElement("video");
      video.src = event.target.result;
      video.onloadedmetadata = function () {
        const duration = video.duration;
        const extension = file.name.split(".").pop();
        if (duration > 600) {
          toast({
            title: "Alert",
            description: "Video must be less than 10 minutes!",
            status: "info",
            duration: 3000,
            isClosable: true,
          });
          setIsLoading(false);
          return;
        }
        if (extension !== "mp4") {
          toast({
            title: "Alert",
            description: "Video must be in mp4 format!",
            status: "info",
            duration: 3000,
            isClosable: true,
          });
          setIsLoading(false);
          return;
        }
      };
    };

    const storageRef = ref(
      storage,
      "videos/" + props.id + "-" + uuid.v4() + "." + file.name.split(".").pop()
    );
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        toast({
          title: "Error",
          description: error,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      },
      () => {
        getDownloadURL(storageRef).then((url) => {
          console.log(duration);
          fetchC(
            `https://coursehub-server-de93512846f7.herokuapp.com/api/videos/${props.id}`,
            {
              "Content-Type": "application/json",
              headers: {
                "Content-Type": "application/json",
              },
              method: "POST",
              body: JSON.stringify({
                url: url,
                duration: duration.standard,
              }),
            }
          )
            .then((res) => {
              if (!res.ok) {
                throw res;
              }
              return res.json();
            })
            .then((data) => {
              toast({
                title: "Success",
                description: data.msg,
                status: "success",
                duration: 3000,
                isClosable: true,
                onCloseComplete: () => {
                  props.close();
                  window.location.reload();
                },
              });
              setIsLoading(false);
            })
            .catch((res) => {
              res.json().then((data) => {
                toast({
                  title: "Error",
                  description: data.msg,
                  status: "error",
                  duration: 3000,
                  isClosable: true,
                });
              });
              setIsLoading(false);
            });
        });
      }
    );
  };

  return (
    <>
      {props.id ? (
        <Flex flexDir="column" align="center" padding="2em">
          <Text fontSize="3xl" fontWeight="bold">
            Upload Video
          </Text>
          <Flex flexDir="column" width="80%" align="center">
            <div
              className="Form__group"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <input
                type="file"
                size="60"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
                multiple={false}
              />
            </div>
            <Button
              isLoading={isLoading}
              mt="1em"
              colorScheme="green"
              onClick={() => submit()}
            >
              Submit
            </Button>
          </Flex>
        </Flex>
      ) : null}
    </>
  );
}

export default UploadVideo;
