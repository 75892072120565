import React, { useEffect, useState } from "react";
import "./CourseContent.css";
import Popup from "reactjs-popup";
import CreateSection from "./CreateSection";
import "reactjs-popup/dist/index.css";
import CreateLesson from "./CreateLesson";
import EditSection from "./EditSection";
import { MdDeleteOutline } from "react-icons/md";
import { Draggable } from "react-drag-reorder";
import LessonsView from "./LessonsView";
import CreateAssignment from "./CreateAssignment";
import { fetchC } from "../utils/customFetch";
import {
  Button,
  Flex,
  IconButton,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";

function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

function CourseContent(props) {
  const [sections, setSections] = useState(null);
  const [title, setTitle] = useState("");
  const [sectionIds, setSectionIds] = useState([]);
  const [reordered, setReordered] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteing, setIsDeleting] = useState(false);
  const [isReordering, setIsReordering] = useState(false);

  const toast = useToast();

  useEffect(() => {
    const getData = async () => {
      await fetchC(
        `https://coursehub-server-de93512846f7.herokuapp.com/api/courses/${props.id}/sections`,
        {
          method: "GET",
          "Content-Type": "application/json",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setSections(data.msg);
          setTitle(data.title);
          let sectionIdsTmp = data.msg
            .sort((a, b) => a.sectionNumber - b.sectionNumber)
            .map((section) => section.id);
          setSectionIds(sectionIdsTmp);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setIsLoading(false);
        });
    };
    getData();
  }, []);

  const deleteSection = async (id) => {
    setIsDeleting(true);
    let answer = window.confirm(
      "Are you sure you want to delete this section?"
    );
    if (!answer) {
      setIsDeleting(false);
      return;
    }

    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/sections/${id}`,
      {
        method: "DELETE",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => window.location.reload(),
        });
        setIsDeleting(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsDeleting(false);
      });
  };

  const getChangedPos = (currentPos, newPos) => {
    const newSectionIds = [...sectionIds];
    const movedSection = newSectionIds.splice(currentPos, 1)[0];
    newSectionIds.splice(newPos, 0, movedSection);
    setSectionIds(newSectionIds);
  };

  useEffect(() => {
    if (!sections) {
      return;
    }
    let newSectionIds = sections
      ?.sort((a, b) => a.sectionNumber - b.sectionNumber)
      .map((section) => section.id);
    if (arraysEqual(newSectionIds, sectionIds)) {
      return;
    }
    if (newSectionIds.length <= 0 || sectionIds.length <= 0) {
      return;
    }
    setReordered(true);
  }, [sectionIds]);

  const reorder = async () => {
    setIsReordering(true);
    if (!reordered) {
      setIsReordering(false);
      return;
    }
    let newSectionIds = sections
      ?.sort((a, b) => a.sectionNumber - b.sectionNumber)
      .map((section) => section.id);
    if (!arraysEqual(newSectionIds, sectionIds)) {
      fetchC(
        `https://coursehub-server-de93512846f7.herokuapp.com/api/sections/order/${props.id}`,
        {
          method: "PATCH",
          "Content-Type": "application/json",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ sections: sectionIds }),
        }
      )
        .then((res) => {
          if (!res.ok) {
            throw res;
          }
          return res.json();
        })
        .then((data) => {
          toast({
            title: "Success",
            description: data.msg,
            status: "success",
            duration: 3000,
            isClosable: true,
            onCloseComplete: () => window.location.reload(),
          });
          setIsReordering(false);
        })
        .catch((res) => {
          res.json().then((data) => {
            toast({
              title: "Error",
              description: data.msg,
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          });
          setIsReordering(false);
        });
    } else {
      setIsReordering(false);
    }
  };

  return (
    <div className="CourseContent">
      {isLoading ? (
        <Spinner />
      ) : !props.id || !Array.isArray(sections) ? (
        <Text fontSize="xl">No course found</Text>
      ) : (
        <>
          <div className="CourseContent__sections">
            <Text fontSize="xl" fontWeight="bold">
              Sections
            </Text>
            {reordered ? (
              <Button
                ml="1em"
                size="sm"
                onClick={() => reorder()}
                isLoading={isReordering}
              >
                Save order
              </Button>
            ) : null}
          </div>
          <ol style={{ marginLeft: "2em", marginBottom: "1em" }}>
            <Draggable onPosChange={getChangedPos}>
              {sections &&
                sections.length > 0 &&
                sections
                  ?.sort((a, b) => a.sectionNumber - b.sectionNumber)
                  ?.map((section) => (
                    <li key={section.id}>
                      <div className="CourseContent__sections">
                        <Popup
                          trigger={
                            <Text fontSize="xl" fontWeight="bold">
                              {section.title}
                            </Text>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="modal">
                              <button className="close" onClick={close}>
                                &times;
                              </button>
                              <EditSection
                                close={close}
                                id={section.id}
                                obj={section}
                              />
                            </div>
                          )}
                        </Popup>
                        <IconButton
                          ml="1em"
                          onClick={() => deleteSection(section.id)}
                          disabled={isDeleteing}
                        >
                          <MdDeleteOutline color="red" size={20} />
                        </IconButton>
                      </div>
                      <details style={{ marginLeft: "1em" }}>
                        <Text>{section.description}</Text>
                        <LessonsView
                          sectionId={section.id}
                          lessons={section?.lessons}
                          assignments={section?.assignments}
                          arraysEqual={arraysEqual}
                        />
                        <Flex my="1em">
                          <Popup
                            trigger={<Button>New Lesson</Button>}
                            modal
                            nested
                          >
                            {(close) => (
                              <div className="modal">
                                <button className="close" onClick={close}>
                                  &times;
                                </button>
                                <CreateLesson close={close} id={section.id} />
                              </div>
                            )}
                          </Popup>
                          <Popup
                            trigger={<Button ml="2em">New Assignment</Button>}
                            modal
                            nested
                          >
                            {(close) => (
                              <div className="modal">
                                <button className="close" onClick={close}>
                                  &times;
                                </button>
                                <CreateAssignment
                                  close={close}
                                  id={section.id}
                                />
                              </div>
                            )}
                          </Popup>
                        </Flex>
                      </details>
                    </li>
                  ))}
            </Draggable>
          </ol>
          <Popup trigger={<Button>New Section</Button>} modal nested>
            {(close) => (
              <div className="modal">
                <button className="close" onClick={close}>
                  &times;
                </button>
                <CreateSection close={close} id={props.id} />
              </div>
            )}
          </Popup>
        </>
      )}
    </div>
  );
}

export default CourseContent;
