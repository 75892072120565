import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Heading,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Discussions from "./components/Discussions";
import Pagination from "./components/Pagination";
import Replies from "./components/Replies";
import UserShort from "./components/UserShort";
import Popup from "reactjs-popup";
import CreateReply from "./components/CreateReply";

function DiscussionPage() {
  const { id } = useParams();

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [course, setCourse] = useState("#");
  const [discussion, setDiscussion] = useState({});
  const [loading, setLoading] = useState(false);

  return (
    <Center minH="78vh" p="2em">
      {loading ? (
        <Spinner size="xl" />
      ) : (
        <Stack
          display="flex"
          flexDir="column"
          justifyContent="space-between"
          w="80%"
          minH="70vh"
          p="2em"
          border="1px"
          borderRadius="xl"
        >
          <Stack>
            <Breadcrumb>
              <BreadcrumbItem>
                <BreadcrumbLink href={`/course/${course}`}>
                  Course
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink href={`/discussions/${course}`}>
                  Discussions
                </BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <BreadcrumbLink href={`/discussion/${id}`}>
                  {discussion.title ? discussion.title : "Loading..."}
                </BreadcrumbLink>
              </BreadcrumbItem>
            </Breadcrumb>
            <Heading>
              Title: {discussion.title ? discussion.title : "Loading..."}
            </Heading>
            <Text>{discussion.topic ? discussion.topic : "Loading..."}</Text>
            <Text>
              Created At:{" "}
              {discussion.created ? discussion.created : "Loading..."}
            </Text>
            {discussion.creator ? (
              <UserShort
                rev={true}
                color="black"
                id={discussion.creator.id ? discussion.creator.id : "#"}
                img={
                  discussion.creator.avatar
                    ? discussion.creator.avatar
                    : "default"
                }
                username={
                  discussion.creator.username
                    ? discussion.creator.username
                    : "Loading..."
                }
              />
            ) : null}
          </Stack>
          <Replies
            id={id}
            per_page={10}
            page={page}
            setTotal={setTotalPages}
            setCourse={setCourse}
            setDiscussion={setDiscussion}
          />

          <Popup
            trigger={
              <Button my="1em" w="fit-content" colorScheme="green">
                Reply
              </Button>
            }
            modal
            nested
          >
            {(close) => (
              <div className="modal">
                <button className="close" onClick={close}>
                  &times;
                </button>
                <CreateReply id={id} close={close} />
              </div>
            )}
          </Popup>
          <Pagination setPage={setPage} page={page} pages={totalPages} />
        </Stack>
      )}
    </Center>
  );
}

export default DiscussionPage;
