import { Stack, Text } from "@chakra-ui/react";
import React from "react";

function Privacy() {
  return (
    <Stack padding="2em" lineHeight="2.5" minHeight="78vh">
      <Text fontSize="2xl" fontWeight="bold">
        Privacy Policy
      </Text>
      <p>
        This Privacy Policy describes how CourseHub collects, uses, and protects
        the personal information of its users. By using CourseHub, you agree to
        the collection and use of your personal information as described in this
        policy.
      </p>
      <Text fontSize="xl" fontWeight="bold">
        1. Information We Collect
      </Text>
      <p>
        CourseHub collects personal information that you provide when creating
        an account, such as your name, email address, and payment information.
        We may also collect information about your usage of the platform, such
        as the courses you enroll in and your progress.
      </p>
      <Text fontSize="xl" fontWeight="bold">
        2. How We Use Your Information
      </Text>
      <p>
        CourseHub uses the collected information to provide and improve our
        services, personalize your experience, process payments, communicate
        with you, and ensure the security of our platform. We may also use your
        information for research and analytics purposes.
      </p>
      <Text fontSize="xl" fontWeight="bold">
        3. Sharing Your Information
      </Text>
      <p>
        CourseHub may share your personal information with trusted third-party
        service providers who assist us in operating our platform and delivering
        our services. We may also share your information when required by law or
        to protect our rights and safety.
      </p>
      <Text fontSize="xl" fontWeight="bold">
        4. Security
      </Text>
      <p>
        CourseHub takes reasonable measures to protect your personal information
        from unauthorized access, use, or disclosure. However, no method of
        transmission over the internet or electronic storage is 100% secure, and
        we cannot guarantee absolute security.
      </p>
      <Text fontSize="xl" fontWeight="bold">
        5. Changes to this Privacy Policy
      </Text>
      <p>
        CourseHub may update this Privacy Policy from time to time. We will
        notify you of any changes by posting the updated policy on our website
        or through other communication channels.
      </p>
      <Text fontSize="xl" fontWeight="bold">
        6. Contact Us
      </Text>
      <p>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at chevlimegh@gmail.com.
      </p>
    </Stack>
  );
}

export default Privacy;
