import React, { useEffect, useState } from "react";
import { fetchC } from "../utils/customFetch";
import {
  Box,
  Button,
  Divider,
  Flex,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { getAuth } from "firebase/auth";
import Popup from "reactjs-popup";
import EditDiscussion from "./EditDiscussion";

function Discussions(props) {
  const [data, setData] = useState([]);
  const [deleting, setDeleting] = useState(false);

  const auth = getAuth();

  const toast = useToast();

  useEffect(() => {
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/discussions/${
        props.id
      }?per_page=${props.num ? props.num : 3}&page=${
        props.page ? props.page : 1
      }`,
      {
        method: "GET",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        setData(data.msg);
        if (props.hasOwnProperty("setTotal")) {
          props.setTotal(data.total_pages);
        }
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
      });
  }, [props.id]);

  const deleteDiscussion = (id) => {
    const conf = window.confirm(
      "Are you sure you want to delete this discussion?"
    );
    if (!conf) {
      return;
    }

    setDeleting(true);

    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/discussions/${id}`,
      {
        method: "DELETE",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setDeleting(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setDeleting(false);
      });
  };

  return (
    <Box p="1em" my="2em" w="100%">
      {!data || data.length <= 0 ? (
        <Spinner size="xl" />
      ) : (
        data.map((d) => (
          <Box mb="1em" display="flex" w="100%" alignItems="flex-start">
            <Link to={`/discussion/${d.id}`} style={{ flex: 2 }}>
              <Box key={d.id}>
                <Text fontSize="lg" fontWeight="bold">
                  {d.title}
                </Text>
                <Text fontSize="sm">{d.topic}</Text>
                <Text fontSize="xs">Created by: {d.creatorObj.username}</Text>
                <Divider mt="1em" />
              </Box>
            </Link>
            {auth?.currentUser && auth?.currentUser.uid == d.creatorObj.id ? (
              <Flex>
                <Popup
                  trigger={
                    <Button size="sm" w="fit-content">
                      Edit
                    </Button>
                  }
                  modal
                  nested
                >
                  {(close) => (
                    <div className="modal">
                      <button className="close" onClick={close}>
                        &times;
                      </button>
                      <EditDiscussion
                        id={d.id}
                        title={d.title}
                        topic={d.topic}
                        close={close}
                      />
                    </div>
                  )}
                </Popup>
                <Button
                  colorScheme="red"
                  size="sm"
                  ml="2em"
                  onClick={() => deleteDiscussion(d.id)}
                  isLoading={deleting}
                >
                  Delete
                </Button>
              </Flex>
            ) : null}
          </Box>
        ))
      )}
    </Box>
  );
}

export default Discussions;
