import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./Forms.css";
import UserShort from "./components/UserShort";
import Pagination from "./components/Pagination";
import { fetchC } from "./utils/customFetch";
import { Box, Center, Flex, Text } from "@chakra-ui/react";

function Following() {
  const { id } = useParams();
  const [following, setFollowing] = useState(null);
  const [user, setUser] = useState(null);

  const [page, setPage] = useState(1);

  useEffect(() => {
    const fun = async () => {
      fetchC(
        `https://coursehub-server-de93512846f7.herokuapp.com/api/followers/following/${id}`,
        {
          method: "GET",
          "Content-Type": "application/json",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setFollowing(data))
        .catch((err) => console.error(err));

      fetchC(
        `https://coursehub-server-de93512846f7.herokuapp.com/api/users/${id}/short`,
        {
          method: "GET",
          "Content-Type": "application/json",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setUser(data.msg))
        .catch((err) => console.error(err));
    };
    fun();
  }, [id]);

  useEffect(() => {
    const fun = async () => {
      fetchC(
        `https://coursehub-server-de93512846f7.herokuapp.com/api/followers/following/${id}?page=${page}`
      )
        .then((res) => res.json())
        .then((data) => setFollowing(data))
        .catch((err) => console.error(err));
    };
    fun();
  }, [page]);

  return (
    <Center minH="78vh">
      <Flex
        flexDir="column"
        align="center"
        border="1px"
        boxShadow="lg"
        px="1em"
        py="2em"
        minW="30vw"
        borderRadius="lg"
      >
        <Text fontSize="3xl" fontWeight="bold">
          {user ? user.username : "Loading..."} Follows
        </Text>
        {following == null ||
        following == undefined ||
        following?.msg.length <= 0 ? (
          <Flex
            p="0.5em"
            m="1em"
            border="1px"
            minW="60%"
            alignItems="center"
            justify="center"
            borderRadius="lg"
          >
            Not following anyone yet...
          </Flex>
        ) : (
          <>
            {following?.msg.map((follow) => (
              <Flex
                p="0.5em"
                m="1em"
                border="1px"
                minW="60%"
                alignItems="center"
                justify="center"
                borderRadius="lg"
              >
                <UserShort
                  color="black"
                  key={follow.id}
                  username={follow.username}
                  id={follow.id}
                  img={follow.avatar}
                />
              </Flex>
            ))}
            <Pagination
              setPage={setPage}
              page={page}
              pages={following.total_pages}
            />
          </>
        )}
      </Flex>
    </Center>
  );
}

export default Following;
