import React, { useContext, useEffect, useRef, useState } from "react";
import "./Forms.css";
import ReCAPTCHA from "react-google-recaptcha";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { UserContext } from "./index";
import { Link, useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithPopup,
} from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";
import { fetchC } from "./utils/customFetch";
import {
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { FcGoogle } from "react-icons/fc";

// The Sign up page

function Signup() {
  const toast = useToast();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [fullname, setFullname] = useState("");
  const recaptcha = useRef();

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);

  const { user, setUser, fetchUser } = useContext(UserContext);

  const auth = getAuth();

  const [isLoading, setIsLoading] = useState(false);

  const provider = new GoogleAuthProvider();

  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);

  const google = () => {
    setIsLoadingGoogle(true);

    signInWithPopup(auth, provider)
      .then((result) => {
        fetchC(
          "https://coursehub-server-de93512846f7.herokuapp.com/api/users/status",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            "Content-Type": "application/json",
          }
        )
          .then((res) => {
            if (!res.ok) {
              throw res;
            }
            toast({
              title: "Success",
              description: "Successfully signed in",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            setIsLoadingGoogle(false);
            navigate("/");
          })
          .catch((error) => {
            setIsLoadingGoogle(false);
            navigate("/additional-details");
            return;
          });
      })
      .catch((error) => {
        const errorMessage = error.message;
        toast({
          title: "Error",
          description: errorMessage,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setIsLoadingGoogle(false);
      });
  };

  // Creates a user
  const createUser = (e) => {
    e.preventDefault();
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      toast({
        title: "Warning",
        description: "Please complete the captcha!",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (password !== confPassword) {
      toast({
        title: "Alert",
        description: "Passwords do not match!",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setIsLoading(true);
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        fetchC(
          "https://coursehub-server-de93512846f7.herokuapp.com/api/users/",
          {
            method: "POST",
            body: JSON.stringify({
              email: email,
              username: username,
              type: "student",
              token: captchaValue,
              fullname: fullname,
              uid: userCredential.user.uid,
              verification: await userCredential.user.getIdToken(),
            }),
            "Content-Type": "application/json",
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => {
            if (!res.ok) {
              throw res;
            }
            return res.json();
          })
          .then((data) => {
            toast({
              title: "Success",
              description: data.msg,
              status: "success",
              duration: 3000,
              isClosable: true,
              onCloseComplete: () => {
                fetchUser();
                window.location.reload();
                navigate("/");
              },
            });
            setIsLoading(false);
          })
          .catch((res) => {
            res.json().then((data) => {
              toast({
                title: "Error",
                description: data.msg,
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            });
            setIsLoading(false);
          });
      })
      .catch((err) => {
        toast({
          title: "Error",
          description: err.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      {!user ? (
        <Center minH="95vh">
          <Flex
            my={"2em"}
            flexDir="column"
            border={["0px", "1px"]}
            paddingX="1em"
            paddingY="2em"
            borderRadius="1em"
            boxShadow={["none", "lg"]}
            minW={"30vw"}
          >
            <Text fontSize="4xl" textAlign="center" fontWeight="bold">
              CourseHub
            </Text>
            <Text textAlign="center">Welcome to CourseHub</Text>
            <form onSubmit={createUser}>
              <FormControl maxW="80%" mt="2em">
                <FormLabel>Username</FormLabel>
                <Input
                  isRequired
                  maxLength={150}
                  id="username"
                  type="text"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </FormControl>
              <FormControl maxW="80%" mt="1em">
                <FormLabel>Full name</FormLabel>
                <Input
                  isRequired
                  maxLength={150}
                  id="fullname"
                  type="text"
                  placeholder="Full name"
                  value={fullname}
                  onChange={(e) => setFullname(e.target.value)}
                />
              </FormControl>
              <FormControl maxW="80%" mt="1em">
                <FormLabel>Email</FormLabel>
                <Input
                  isRequired
                  maxLength={150}
                  id="email"
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
              <FormControl maxW="80%" mt="1em">
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <Input
                    isRequired
                    minLength={6}
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <InputRightElement>
                    <Button onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <FormControl maxW="80%" mt="1em">
                <FormLabel>Confirm Password</FormLabel>
                <InputGroup>
                  <Input
                    isRequired
                    minLength={6}
                    type={showConfPassword ? "text" : "password"}
                    placeholder="Confirm Password"
                    id="confpassword"
                    value={confPassword}
                    onChange={(e) => setConfPassword(e.target.value)}
                  />
                  <InputRightElement>
                    <Button
                      onClick={() => setShowConfPassword(!showConfPassword)}
                    >
                      {showConfPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <br />
              <ReCAPTCHA
                className="Form__recaptcha"
                sitekey={process.env.REACT_APP_SITE_KEY}
                ref={recaptcha}
              />
              <Button isLoading={isLoading} type="submit" colorScheme="green">
                Submit
              </Button>
              or
              <Button
                display="flex"
                alignItems="center"
                isLoading={isLoadingGoogle}
                onClick={google}
              >
                Sign in with Google
                <FcGoogle size={24} style={{ marginLeft: "0.5em" }} />
              </Button>
            </form>
            <br />
            <Text textAlign="center">
              By Signing up, you are agreeing to our <br />{" "}
              <Link to="tandc">
                <u>Terms and conditions</u>
              </Link>
              , <br />
              <Link to="privacy">
                <u>Privacy policy</u>
              </Link>
              {", "}
              and our <br />
              <Link to="cancellation">
                <u>Cancellation and refund policy</u>
              </Link>
            </Text>
          </Flex>
        </Center>
      ) : (
        <h1>Already signed in</h1>
      )}
    </>
  );
}

export default Signup;
