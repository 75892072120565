import React, { useContext, useRef, useState } from "react";
import {
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate } from "react-router-dom";
import { fetchC } from "./utils/customFetch";
import { UserContext } from "./index";

function AdditionalDetail() {
  const toast = useToast();
  const [username, setUsername] = useState("");
  const [fullname, setFullname] = useState("");
  const recaptcha = useRef();

  const { user, setUser, fetchUser } = useContext(UserContext);

  const navigate = useNavigate();

  const auth = getAuth();

  const [isLoading, setIsLoading] = useState(false);

  // Sign in user
  const fillUp = async (e) => {
    e.preventDefault();
    if (!recaptcha.current.getValue()) {
      toast({
        title: "Error",
        description: "Please complete the reCAPTCHA",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (username.length < 3) {
      toast({
        title: "Warning",
        description: "Username must be at least 3 characters",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (fullname.length < 3) {
      toast({
        title: "Warning",
        description: "Full name must be at least 3 characters",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setIsLoading(true);
    fetchC("https://coursehub-server-de93512846f7.herokuapp.com/api/users/", {
      method: "POST",
      body: JSON.stringify({
        email: auth.currentUser?.email,
        username: username,
        type: "student",
        token: await recaptcha.current.getValue(),
        fullname: fullname,
        uid: auth.currentUser.uid,
        verification: await auth.currentUser.getIdToken(),
      }),
      "Content-Type": "application/json",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            fetchUser();
            navigate("/");
          },
        });
        setIsLoading(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsLoading(false);
      });
  };
  return (
    <Center minH="78vh">
      <Flex
        flexDir="column"
        border="1px"
        paddingX="1em"
        paddingY="2em"
        borderRadius="1em"
        boxShadow="lg"
        minW={"30vw"}
      >
        <Text fontSize="4xl" textAlign="center" fontWeight="bold">
          CourseHub
        </Text>
        <Text textAlign="center">Please enter remaining details</Text>
        <form onSubmit={fillUp}>
          <FormControl maxW="80%" mt="2em">
            <FormLabel>Username</FormLabel>
            <Input
              isRequired
              maxLength={150}
              id="username"
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </FormControl>
          <FormControl maxW="80%" my="1em">
            <FormLabel>Full name</FormLabel>
            <Input
              isRequired
              maxLength={150}
              id="fullname"
              type="text"
              placeholder="Full name"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
            />
          </FormControl>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_SITE_KEY}
            ref={recaptcha}
            className="Form__recaptcha"
          />
          <Button isLoading={isLoading} colorScheme="green" type="submit">
            Submit
          </Button>
        </form>
      </Flex>
    </Center>
  );
}
export default AdditionalDetail;
