import React from "react";
import "./NoPage.css";

function NoPage() {
  return (
    <div className="NoPage">
      <h1>Error: 404 Not found</h1>
      <p>Requested page not found...</p>
    </div>
  );
}

export default NoPage;
