import {
  Button,
  Center,
  Divider,
  Heading,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchC } from "./utils/customFetch";

function DoAssignment() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [criteria, setCriteria] = useState([]);
  const [loadingCriteria, setLoadingCriteria] = useState(true);

  const { id } = useParams();

  useEffect(() => {
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/assignments/${id}`,
      {
        method: "GET",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error();
        } else {
          return res.json();
        }
      })
      .then((data) => {
        setData(data.msg);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/assignments/criteria/${id}`,
      {
        method: "GET",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw new Error();
        } else {
          return res.json();
        }
      })
      .then((data) => {
        setCriteria(data.msg);
        setLoadingCriteria(false);
      })
      .catch((err) => {
        console.error(err);
        setLoadingCriteria(false);
      });
  }, []);

  return (
    <Center>
      <Stack minH="70vh" w="80vw" p="2em" border="1px" borderRadius="lg">
        {loading ? (
          <Center h="70vh">
            <Spinner size="xl" />
          </Center>
        ) : (
          <>
            <Heading>Assignment: {data.title}</Heading>
            <Text>{data.description}</Text>
            <Text>Passing percentage: {data.passing}%</Text>
            <Text mt="1em" fontSize="lg" fontWeight="bold">Criteria Ruberic</Text>
            <Divider />
            {loadingCriteria ? (
              <Spinner size="xl" />
            ) : (
              <Stack>
                {criteria
                  .sort((a, b) => a.criteriaNumber - b.criteriaNumber)
                  .map((criterion) => (
                    <Stack
                      key={criterion.id}
                      p="1em"
                      border="1px"
                      borderRadius="lg"
                    >
                      <Text>{criterion.description}</Text>
                      <Text>Points: {criterion.points} pt</Text>
                      <Divider />
                    </Stack>
                  ))}
              </Stack>
            )}
            <Divider />
            <Text fontSize="xl" fontWeight="bold">
              Submit
            </Text>
            <Text>Upload your assignment here</Text>
            <input type="file" size="200" />
            <Button colorScheme="green" w="fit-content">
              Submit
            </Button>
          </>
        )}
      </Stack>
    </Center>
  );
}

export default DoAssignment;
