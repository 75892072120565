import React, { useState } from "react";
import "../Forms.css";
import { fetchC } from "../utils/customFetch";

import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Switch,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";

function CreateTest(props) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [graded, setGraded] = useState(false);
  const [time, setTime] = useState("");
  const [timeEst, setTimeEst] = useState("");
  const [questionsNum, setQuestionsNum] = useState(0);
  const [maxAttempts, setMaxAttempts] = useState(5);
  const [delay, setDelay] = useState(0);
  const [negativeGrading, setNegativeGrading] = useState(false);
  const [passing, setPassing] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const createTest = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    fetchC("https://coursehub-server-de93512846f7.herokuapp.com/api/tests/", {
      "Content-Type": "application/json",
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        title: title,
        description: description,
        lesson: props.id,
        graded: graded,
        time: time,
        timeEstimate: timeEst,
        questionsNumber: questionsNum,
        max: maxAttempts,
        delay: delay,
        negative: negativeGrading,
        passingGrade: passing,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            props.close();
            window.location.reload();
          },
        });
        setIsLoading(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      {props.id ? (
        <Flex
          flexDir="column"
          align="center"
          padding="2em"
          overflowY="scroll"
          maxH="75vh"
        >
          <Text fontSize="3xl" fontWeight="bold">
            Create Test
          </Text>
          <form onSubmit={createTest}>
            <FormControl mt="1em">
              <FormLabel>Title</FormLabel>
              <Input
                isRequired
                maxLength={100}
                type="text"
                placeholder="Title"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormControl>
            <FormControl mt="2em">
              <FormLabel>Description</FormLabel>
              <Textarea
                isRequired
                maxLength={500}
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Description"
              ></Textarea>
            </FormControl>
            <FormControl mt="2em">
              <FormLabel>Graded</FormLabel>
              <Switch
                isChecked={graded}
                id="graded"
                onChange={(e) => setGraded(!graded)}
              />
            </FormControl>
            <FormControl mt="2em">
              <FormLabel>Time limit</FormLabel>
              <InputGroup>
                <Input
                  isRequired
                  type="number"
                  placeholder="Time"
                  id="time"
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                  min={0}
                />
                <InputRightAddon>min</InputRightAddon>
              </InputGroup>
            </FormControl>
            <FormControl mt="2em">
              <FormLabel>Time estimate</FormLabel>
              <InputGroup>
                <Input
                  isRequired
                  type="number"
                  placeholder="Time estimate"
                  id="timeest"
                  value={timeEst}
                  onChange={(e) => setTimeEst(e.target.value)}
                  min={0}
                />
                <InputRightAddon>min</InputRightAddon>
              </InputGroup>
            </FormControl>
            <FormControl mt="2em">
              <FormLabel>Number of questions to be shown out of all</FormLabel>
              <Input
                isRequired
                type="number"
                placeholder="Number of questions"
                id="questionsnum"
                value={questionsNum}
                onChange={(e) => setQuestionsNum(e.target.value)}
              />
            </FormControl>
            <FormControl mt="2em">
              <FormLabel>Maximum attempts</FormLabel>
              <Input
                isRequired
                type="number"
                placeholder="Maximum attempts"
                id="maxattempts"
                value={maxAttempts}
                onChange={(e) => setMaxAttempts(e.target.value)}
                min={0}
              />
            </FormControl>
            <FormControl mt="2em">
              <FormLabel>Delay</FormLabel>
              <InputGroup>
                <Input
                  type="number"
                  placeholder="Delay"
                  id="delay"
                  value={delay}
                  onChange={(e) => setDelay(e.target.value)}
                  min={0}
                />
                <InputRightAddon>min</InputRightAddon>
              </InputGroup>
            </FormControl>
            <FormControl mt="2em">
              <FormLabel>Negative grading</FormLabel>
              <Switch
                isChecked={negativeGrading}
                id="negativegrading"
                onChange={() => setNegativeGrading(!negativeGrading)}
              />
            </FormControl>
            <FormControl mt="2em">
              <FormLabel>Passing</FormLabel>
              <InputGroup>
                <Input
                  isRequired
                  type="number"
                  placeholder="Passing"
                  id="passing"
                  value={passing}
                  onChange={(e) => setPassing(e.target.value)}
                  min={0}
                  max={100}
                />
                <InputRightAddon>%</InputRightAddon>
              </InputGroup>
            </FormControl>
            <Button
              isLoading={isLoading}
              mt="2em"
              colorScheme="green"
              type="submit"
            >
              Submit
            </Button>
          </form>
        </Flex>
      ) : (
        <h1>Lesson not found</h1>
      )}
    </>
  );
}

export default CreateTest;
