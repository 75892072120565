import React, { useState } from "react";
import "../Forms.css";
import { fetchC } from "../utils/customFetch";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";

function CreateSection(props) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  const createSection = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    fetchC(
      "https://coursehub-server-de93512846f7.herokuapp.com/api/sections/",
      {
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          title: title,
          description: description,
          course: props.id,
        }),
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            props.close();
            window.location.reload();
          },
        });
        setIsLoading(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      {props.id ? (
        <Flex flexDir="column" align="center" padding="2em">
          <Text fontSize="3xl" fontWeight="bold">
            Create Section
          </Text>
          <form onSubmit={createSection}>
            <FormControl>
              <FormLabel>Title</FormLabel>
              <Input
                isRequired
                maxLength={100}
                type="text"
                placeholder="Title"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </FormControl>
            <FormControl mt="2em">
              <FormLabel>Description</FormLabel>
              <Textarea
                isRequired
                maxLength={500}
                name=""
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Description"
              ></Textarea>
            </FormControl>
            <Button
              mt="2em"
              colorScheme="green"
              type="submit"
              isLoading={isLoading}
            >
              Submit
            </Button>
          </form>
        </Flex>
      ) : (
        <h1>Course not found</h1>
      )}
    </>
  );
}

export default CreateSection;
