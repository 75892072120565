import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./LessonContents.css";
import Popup from "reactjs-popup";
import UploadVideo from "./components/UploadVideo";
import CreateMarkdown from "./components/CreateMarkdown";
import CreateTest from "./components/CreateTest";
import LessonObjs from "./components/LessonObjs";
import { fetchC } from "./utils/customFetch";
import { Button, Center, Flex, Spinner, Stack, Text } from "@chakra-ui/react";

function LessonContents() {
  const { id } = useParams();

  const [contents, setContents] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function name() {
      fetchC(
        `https://coursehub-server-de93512846f7.herokuapp.com/api/lessons/contents/${id}`,
        {
          method: "GET",
          "Content-Type": "application/json",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setContents(data.msg);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setIsLoading(false);
        });
    }

    name();
  }, []);

  const [mdEdit, setMdEdit] = useState(false);

  const closeMd = () => {
    setMdEdit(false);
  };

  return (
    <Center minH="78vh">
      {id && contents ? (
        <Stack
          padding="2em"
          width={["100vw", "100vw", "75vw"]}
          border={["0px", "0px", "1px"]}
          borderRadius="xl"
          boxShadow={["none", "none", "lg"]}
          my="2em"
        >
          {isLoading ? (
            <Flex w="100%" align="center" justify="center" my="2em">
              <Spinner size="xl" />
            </Flex>
          ) : contents.lesson ? (
            <>
              <Text fontWeight="bold" fontSize="3xl">
                Title: {contents?.lesson?.title}
              </Text>
              <Text>{contents?.lesson?.description}</Text>
              <LessonObjs edit={true} contents={contents} id={id} />
            </>
          ) : (
            <Text fontSize="lg" fontWeight="bold">
              Content not found
            </Text>
          )}
          <div>
            <hr />
            {!mdEdit ? (
              <div className="LessonContents__newDiv">
                <Button onClick={() => setMdEdit(true)}>New Markdown</Button>
                <Popup trigger={<Button>New Video</Button>} modal nested>
                  {(close) => (
                    <div className="modal">
                      <button className="close" onClick={close}>
                        &times;
                      </button>
                      <UploadVideo close={close} id={id} />
                    </div>
                  )}
                </Popup>
                <Popup trigger={<Button>New Test</Button>} modal nested>
                  {(close) => (
                    <div className="modal">
                      <button className="close" onClick={close}>
                        &times;
                      </button>
                      <CreateTest close={close} id={id} />
                    </div>
                  )}
                </Popup>
              </div>
            ) : (
              <CreateMarkdown close={closeMd} id={id} />
            )}
          </div>
        </Stack>
      ) : (
        <h1>No Lesson found</h1>
      )}
    </Center>
  );
}

export default LessonContents;
