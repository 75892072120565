import {
  Box,
  Button,
  Divider,
  Flex,
  Spinner,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { fetchC } from "../utils/customFetch";
import { Link } from "react-router-dom";
import UserShort from "./UserShort";
import { getAuth } from "firebase/auth";
import EditReply from "./EditReply";
import Popup from "reactjs-popup";

function Replies(props) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const auth = getAuth();

  const toast = useToast();

  useEffect(() => {
    // Get paginated discussions for props.id
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/discussions/${
        props.id
      }/replies?per_page=${props.per_page ? props.per_page : 10}&page=${
        props.page ? props.page : 1
      }`,
      {
        method: "GET",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        setData({ replies: data.msg.replies, title: data.msg.title });
        console.log(data.msg);
        if (props.hasOwnProperty("setTotal")) {
          props.setTotal(data.total_pages);
        }
        if (props.hasOwnProperty("setCourse")) {
          props.setCourse(data.msg.course);
        }
        if (props.hasOwnProperty("setDiscussion")) {
          props.setDiscussion({
            title: data.msg.title,
            topic: data.msg.topic,
            created: data.msg.created,
            creator: {
              username: data.msg.creator,
              id: data.msg.creator_id,
              avatar: data.msg.creator_avatar,
            },
          });
        }
        setIsLoading(false);
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
        setIsLoading(false);
      });
  }, [props.id]);

  const deleteReply = (id) => {
    const conf = window.confirm("Are you sure you want to delete this reply?");
    if (!conf) {
      return;
    }

    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/discussions/reply/${id}`,
      {
        method: "DELETE",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        window.location.reload();
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
      });
  };

  return (
    <Box border="1px" p="1em" borderRadius="lg" my="2em" w="100%">
      {isLoading ? (
        <Spinner size="xl" />
      ) : data && data.replies && data.replies.length ? (
        data.replies
          .sort((a, b) => a.created - b.created)
          .map((r) => (
            <Box mb="1em">
              <Flex key={r.id}>
                <Stack flex="2">
                  <Text fontSize="lg" fontWeight="bold">
                    Re: {data.title}
                  </Text>
                  <Text fontSize="sm" mb="1em">
                    {r.reply}
                  </Text>
                  <UserShort
                    rev={true}
                    color="black"
                    id={r.creator_id ? r.creator_id : "#"}
                    img={r.creator_avatar ? r.creator_avatar : "default"}
                    username={r.creator ? r.creator : "Loading..."}
                  />
                  <Divider mt="1em" />
                </Stack>
                {auth?.currentUser && auth?.currentUser.uid == r.creator_id ? (
                  <Flex>
                    <Popup
                      trigger={
                        <Button size="sm" w="fit-content">
                          Edit
                        </Button>
                      }
                      modal
                      nested
                    >
                      {(close) => (
                        <div className="modal">
                          <button className="close" onClick={close}>
                            &times;
                          </button>
                          <EditReply id={r.id} reply={r.reply} close={close} />
                        </div>
                      )}
                    </Popup>
                    <Button
                      w="fit-content"
                      colorScheme="red"
                      size="sm"
                      ml="2em"
                      onClick={() => deleteReply(r.id)}
                    >
                      Delete
                    </Button>
                  </Flex>
                ) : null}
              </Flex>
            </Box>
          ))
      ) : (
        "No replies found"
      )}
    </Box>
  );
}

export default Replies;
