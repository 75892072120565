import React, { useContext, useEffect, useState } from "react";
import "./Landing.css";
import girl from "./clg project.png";
import { IoMdSearch } from "react-icons/io";
import { Link } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Card from "./components/Card";
import UserShort from "./components/UserShort";
import { UserContext } from "./index";
import CourseCard from "./components/CourseCard";
import { fetchC } from "./utils/customFetch";
import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
  ListIcon,
  ListItem,
  Spacer,
  Spinner,
  Text,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";

import { getAuth } from "firebase/auth";
import { MdMenu } from "react-icons/md";

// Represent the landing page of the website

function Landing() {
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const { user, setUser, fetchUser } = useContext(UserContext);
  const [courses, setCourses] = useState([]);

  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const auth = getAuth();

  useEffect(() => {
    setIsLoading(true);
    fetchC(
      "https://coursehub-server-de93512846f7.herokuapp.com/api/courses/home",
      {
        method: "GET",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setCourses(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });

    if (auth.currentUser) {
      console.log(auth.currentUser.uid, auth.currentUser.getIdToken());
    } else {
      console.error("No user");
    }
  }, []);

  const searchF = () => {
    navigate(`/search?q=${search}`);
  };

  return (
    <>
      <Box
        bg="#0f8b32"
        pl="2em"
        pr="2em"
        pt="1em"
        borderBottomLeftRadius="1em"
        borderBottomRightRadius="1em"
      >
        <Flex align="center" justify="space-between">
          <Text fontSize="5xl" fontWeight="extrabold" color="white">
            CourseHub
          </Text>
          <Box>
            {user && user.id ? (
              <UserShort
                id={user.id}
                username={user.username}
                img={user.avatar}
                color="white"
              />
            ) : (
              <>
                <Flex display={["none", "flex"]}>
                  <Link className="Landing__navlink" to={"/signin"}>
                    Sign in
                  </Link>
                  <Link className="Landing__navlink" to={"/signup"}>
                    Sign up
                  </Link>
                  <Link className="Landing__navlink" to={"/about"}>
                    About
                  </Link>
                  <Link className="Landing__navlink" to={"/contact"}>
                    Contact us
                  </Link>
                  <Link className="Landing__navlink" to={"/"}>
                    Featured
                  </Link>
                  <Link className="Landing__navlink" to={"/"}>
                    Blog
                  </Link>
                </Flex>
                <Button
                  onClick={onOpen}
                  display={["block", "none"]}
                  ref={btnRef}
                >
                  <MdMenu />
                </Button>
                <Drawer
                  isOpen={isOpen}
                  placement="right"
                  onClose={onClose}
                  finalFocusRef={btnRef}
                >
                  <DrawerOverlay />
                  <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Links</DrawerHeader>

                    <DrawerBody
                      display="flex"
                      flexDir="column"
                      lineHeight="2.5"
                    >
                      <Link to={"/signin"}>
                        <u>Sign in</u>
                      </Link>
                      <Link to={"/signup"}>
                        <u>Sign up</u>
                      </Link>
                      <Link to={"/about"}>
                        <u>About</u>
                      </Link>
                      <Link to={"/contact"}>
                        <u>Contact us</u>
                      </Link>
                      <Link to={"/"}>
                        <u>Featured</u>
                      </Link>
                      <Link to={"/"}>
                        <u>Blog</u>
                      </Link>
                    </DrawerBody>
                  </DrawerContent>
                </Drawer>
              </>
            )}
          </Box>
        </Flex>
        <Flex alignItems="flex-end" height="100%">
          <Image src={girl} alt="girl" mx="5vw" />
          <Center
            flexDir="column"
            height="100%"
            display={["none", "none", "none", "flex"]}
          >
            <Text fontSize="5xl" fontWeight="bold">
              Welcome to CourseHub <br />
              Where Learning Meets Possibility
            </Text>
            <InputGroup
              borderRadius={5}
              size="lg"
              width="40vw"
              marginBottom="30%"
              mt="1.5em"
            >
              <Input
                type="search"
                border="1px solid #949494"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                size="lg"
                placeholder="Find the perfect course for you"
                bg="white"
              />
              <InputRightAddon p={0} border="none">
                <Button bg="none" _hover="none" onClick={() => searchF()}>
                  <IoMdSearch size={20} />
                </Button>
              </InputRightAddon>
            </InputGroup>
          </Center>
        </Flex>
      </Box>

      <Flex flexDir="column" p="2em">
        <Box display={["block", "block", "block", "none"]}>
          <Text fontSize="3xl" fontWeight={"bold"}>
            Welcome
          </Text>
          <Text>Find your perfect course</Text>
          <InputGroup borderRadius={5} size="lg" marginBottom="2em" mt="1.5em">
            <Input
              type="search"
              border="1px solid #949494"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              size="lg"
              placeholder="Find the perfect course for you"
              bg="white"
            />
            <InputRightAddon p={0} border="none">
              <Button bg="none" _hover="none" onClick={() => searchF()}>
                <IoMdSearch size={20} />
              </Button>
            </InputRightAddon>
          </InputGroup>
        </Box>
        <Text fontSize="3xl" fontWeight={"bold"}>
          Courses for Every Occasion
        </Text>
        <Flex
          align="center"
          justify="space-evenly"
          flexDir={["column", "column", "column", "column", "row"]}
        >
          <Card
            image="https://img.freepik.com/free-vector/student-with-laptop-studying-online-course_74855-5293.jpg"
            title="Education"
            description="Courses that help students excel in their academics and beyond. Helping them with course material, assignments, and more."
          />
          <Card
            image="https://www.totaljobs.com/advice/wp-content/uploads/graphic-designer-job-description.jpg"
            title="Career"
            description="Courses that help professionals excel in their careers. Helping them with job skills, certifications, and more. Increasing their employability."
          />
          <Card
            image="https://t3.ftcdn.net/jpg/04/16/10/78/360_F_416107856_8YTxXxOxaYJZzchysZv2bYSLINGKEamD.jpg"
            title="Self Development"
            description="Courses that help individuals excel in their personal lives. Helping them with personal growth, hobbies, and more. Improving their quality of life."
          />
        </Flex>
      </Flex>

      <hr />

      <Flex
        p="2em"
        align="center"
        justify="center"
        flexDir={["column", "column", "row"]}
      >
        <Image
          width={["90vw", "70vw", "50vw"]}
          src="https://i.pinimg.com/originals/ae/5a/e1/ae5ae16a1f8bdad663c96a699d91e646.jpg"
          alt=""
        />
        <Flex flexDir="column" align="center">
          <Text fontSize="3xl" textAlign="center" fontWeight="bold">
            Quality Education <br />
            By Quality Educators
          </Text>
          <Button
            colorScheme="green"
            mt="1em"
            onClick={(e) => navigate("/signup")}
          >
            Sign Up
          </Button>
        </Flex>
      </Flex>
      <hr />
      <Flex flexDir="column" p="2em">
        <Text fontSize="3xl" fontWeight={"bold"} mb="1em">
          Top Courses
        </Text>
        <Flex
          align="center"
          justify="space-evenly"
          flexDir={["column", "column", "row"]}
        >
          {!isLoading ? (
            <>
              {courses && courses.msg && courses.msg.length > 0 ? (
                <>
                  {courses.msg?.map((course) => (
                    <CourseCard
                      thumbnail={course.thumbnail}
                      price={course.price}
                      title={course.title}
                      description={course.description}
                      id={course.id}
                      bestseller={course.bestseller}
                      rating={course.rating}
                      ratingNumber={course.ratingNum}
                      instructor={course.instructorObj}
                      featured={course.featured}
                      promoted={course.promoted}
                    />
                  ))}
                </>
              ) : (
                <Text mb="5vh">No courses yet</Text>
              )}
            </>
          ) : (
            <Spinner mb="5vh" size="xl" />
          )}
        </Flex>
      </Flex>
      <hr />
      <Flex flexDir="column" p="2em">
        <Text fontSize="3xl" fontWeight={"bold"} mb="1em">
          Why Choose Us?
        </Text>
        <Flex align="center" justify="center" w="100%">
          <UnorderedList
            spacing={10}
            fontSize="2xl"
            mr={["0", "0", "0", "5em"]}
          >
            <ListItem>Interactive courses</ListItem>
            <ListItem>High availability</ListItem>
            <ListItem>Data security, privacy</ListItem>
            <ListItem>Certificate provided</ListItem>
            <ListItem>User friendly</ListItem>
            <ListItem>Various choices</ListItem>
          </UnorderedList>
          <Image
            display={["none", "none", "none", "block"]}
            src="https://media.istockphoto.com/id/1303877287/vector/paper-checklist-and-pencil-flat-pictogram.jpg?s=612x612&w=0&k=20&c=NoqPzn94VH2Pm7epxF8P5rCcScMEAiGQ8Hv_b2ZwRjY="
          />
        </Flex>
      </Flex>
    </>
  );
}

export default Landing;
