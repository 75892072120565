import { Routes, Route, Link, useLocation } from "react-router-dom";
import Signin from "./Signin";
import Signup from "./Signup";
import NoPage from "./NoPage";
import "./App.css";
import Landing from "./Landing";
import User from "./User";
import CourseView from "./CourseView";
import CreateCourse from "./CreateCourse";
import EditCourse from "./EditCourse";
import { useContext, useEffect, useRef, useState } from "react";
import UserShort from "./components/UserShort";
import Followers from "./Followers";
import Following from "./Following";
import { UserContext } from "./index";
import { IoIosNotifications, IoMdExit } from "react-icons/io";
import Search from "./Search";
import "./variables.css";
import Notification from "./components/Notification";
import CourseDash from "./CourseDash";
import LessonContents from "./LessonContents";
import AssignmentRuberic from "./AssignmentRuberic";
import { fetchC } from "./utils/customFetch";
import { signOut, getAuth } from "firebase/auth";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import TandC from "./TandC";
import Privacy from "./Privacy";
import Cancellation from "./Cancellation";
import { Box, Button, Flex, IconButton, Text } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import AdditionalDetail from "./AdditionalDetail";
import ForgotPassword from "./ForgotPassword";
import DiscussionsPage from "./DiscussionsPage";
import DiscussionPage from "./DiscussionPage";
import CourseDo from "./CourseDo";
import DoLesson from "./DoLesson";
import DoAssignment from "./DoAssignment";
import DoTest from "./DoTest";
import VerifyCertificate from "./VerifyCertificate";
import Certificates from "./Certificates";

function App() {
  const toast = useToast();
  // React router routes
  const { user, setUser, fetchUser } = useContext(UserContext);

  const [showNotifications, setShowNotifications] = useState(false);

  const dropdownRef = useRef(null);

  const auth = getAuth();

  useEffect(() => {
    function handleOutsideClick(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowNotifications(false);
      }
    }

    function handleEscapeKey(event) {
      if (event.key === "Escape") {
        setShowNotifications(false);
      }
    }

    document.addEventListener("mousedown", handleOutsideClick);
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  const location = useLocation();

  const deleteNotifications = async () => {
    fetchC(
      `https://coursehub-server-de93512846f7.herokuapp.com/api/notifications/user/${user.id}`,
      {
        method: "PATCH",
        "Content-Type": "application/json",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        toast({
          title: "Success",
          description: data.msg,
          status: "success",
          duration: 3000,
          isClosable: true,
          onCloseComplete: () => {
            window.location.reload();
          },
        });
      })
      .catch((res) => {
        res.json().then((data) => {
          toast({
            title: "Error",
            description: data.msg,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
      });
  };

  return (
    <>
      {location.pathname !== "/" && (
        <nav className="App__nav">
          <Link to="/" className="App__siteName">
            <Text fontSize="3xl" fontWeight="bold" color="#0f8b32">
              CourseHub
            </Text>
          </Link>
          {!user ? (
            <Flex>
              <Link className="App__rightLink" to="/signin">
                Signin
              </Link>
              <Link className="App__rightLink" to="/signup">
                Signup
              </Link>
            </Flex>
          ) : (
            <Flex align="center" justify="space-between" minW="12vw">
              <IconButton
                onClick={() => setShowNotifications(!showNotifications)}
                icon={<IoIosNotifications size={26} />}
              />
              {showNotifications && user ? (
                <div className="notifications-dropdown" ref={dropdownRef}>
                  {user.notificationsReceived.length > 1 ? (
                    <Button
                      colorScheme="red"
                      onClick={() => deleteNotifications()}
                    >
                      &times; Clear all
                    </Button>
                  ) : null}
                  {user?.notificationsReceived &&
                  user.notificationsReceived.length > 0 ? (
                    user.notificationsReceived?.map((notification) => (
                      <Notification obj={notification} fetchUser={fetchUser} />
                    ))
                  ) : (
                    <h3>No more notification</h3>
                  )}
                </div>
              ) : null}
              <UserShort
                color="black"
                id={user.id}
                username={user.username}
                img={user.avatar}
              />
              <IconButton
                icon={<IoMdExit size={28} color="black" />}
                onClick={() => {
                  signOut(auth);
                  window.location.reload();
                }}
              />
            </Flex>
          )}
        </nav>
      )}
      <Routes>
        <Route index element={<Landing />} />

        {/* <Route path="signin" element={<Signin />} />
        <Route path="signup" element={<Signup />} /> */}
        <Route path="create" element={<CreateCourse />} />
        <Route path="user" element={<User />}>
          <Route path="/user/:id" element={<User />} />
        </Route>
        <Route path="course" element={<CourseView />}>
          <Route path="/course/:id" element={<CourseView />} />
        </Route>
        <Route path="edit" element={<EditCourse />}>
          <Route path="/edit/:id" element={<EditCourse />} />
        </Route>
        <Route path="followers" element={<Followers />}>
          <Route path="/followers/:id" element={<Followers />} />
        </Route>
        <Route path="following" element={<Following />}>
          <Route path="/following/:id" element={<Following />} />
        </Route>
        <Route path="course-dash" element={<CourseDash />}>
          <Route path="/course-dash/:id" element={<CourseDash />} />
        </Route>
        <Route path="lesson-cont" element={<LessonContents />}>
          <Route path="/lesson-cont/:id" element={<LessonContents />} />
        </Route>
        <Route path="assignment-cont" element={<AssignmentRuberic />}>
          <Route path="/assignment-cont/:id" element={<AssignmentRuberic />} />
        </Route>
        <Route path="discussions" element={<DiscussionsPage />}>
          <Route path="/discussions/:id" element={<DiscussionsPage />} />
        </Route>
        <Route path="discussion" element={<DiscussionPage />}>
          <Route path="/discussion/:id" element={<DiscussionPage />} />
        </Route>
        <Route path="lesson-do" element={<DoLesson />}>
          <Route path="/lesson-do/:id" element={<DoLesson />} />
        </Route>
        <Route path="assignment-do" element={<DoAssignment />}>
          <Route path="/assignment-do/:id" element={<DoAssignment />} />
        </Route>
        <Route path="test-do" element={<DoTest />}>
          <Route path="/test-do/:id" element={<DoTest />} />
        </Route>
        <Route path="*" element={<NoPage />} />
        <Route path="signin" element={<Signin />} />
        <Route path="signup" element={<Signup />} />
        <Route path="search" element={<Search />} />
        <Route path="about" element={<AboutUs />} />
        <Route path="contact" element={<ContactUs />} />
        <Route path="tandc" element={<TandC />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="cancellation" element={<Cancellation />} />
        <Route path="additional-details" element={<AdditionalDetail />} />
        <Route path="forgot-pass" element={<ForgotPassword />} />
        <Route path="course-do" element={<CourseDo />}>
          <Route path="/course-do/:id" element={<CourseDo />} />
        </Route>
        <Route path="cert-verify" element={<VerifyCertificate />}>
          <Route path="/cert-verify/:id" element={<VerifyCertificate />} />
        </Route>
        <Route path="certificates" element={<Certificates />}>
          <Route path="/certificates/:id" element={<Certificates />} />
        </Route>
      </Routes>
      <Box textAlign="center">
        <hr />
        <Text mt="1em">Copyright © 2023 Megh Chevli</Text>
        <Text my="0.5em">
          <Link style={{ textDecoration: "underline" }} to="/about">
            About us
          </Link>
          ,{" "}
          <Link style={{ textDecoration: "underline" }} to="/contact">
            Contact us
          </Link>
          ,{" "}
          <Link style={{ textDecoration: "underline" }} to="/tandc">
            Terms and Conditions
          </Link>
          ,{" "}
          <Link style={{ textDecoration: "underline" }} to="privacy">
            Privacy policy
          </Link>
          ,{" "}
          <Link style={{ textDecoration: "underline" }} to="cancellation">
            Cancellation & Refund policy
          </Link>
        </Text>
        <Text>Our <a href="https://www.instagram.com/coursehub.in/">IG</a></Text>
        <Text fontSize="lg" fontWeight="bold" my="1em">
          Made in India 🇮🇳
        </Text>
      </Box>
    </>
  );
}

export default App;
